@media only screen and (max-width: 1070px) {
  .clientPorfile {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 25px;
    &__profile {
      width: 100%;
      &__image {
        display: flex;
        &__box {
          width: 95%;
          padding: 0 2rem;
          margin: 0 3rem;
          margin-right: 2rem;
        }
        img {
          width: 100%;
        }
        &__heartIcon {
          width: 6%;
          display: block;
          color: $BLUE_COLOR;
          margin-top: 2rem;
          position: relative;
          right: 0.8rem;
        }
      }
      &__name {
        &__heartIcon {
          display: none;
        }
        &__title {
          width: 50%;
          font: normal normal bold 50px/30px Yu Gothic;
        }
        &__messageIcon {
          color: $BLUE_COLOR;
          display: flex;
        }
      }
      &__rating {
        &__number {
          font: normal normal normal 30px/40px Yu Gothic;
          margin-top: 0.5rem;
        }
        &__starIcon {
          position: relative;
          top: 0.3rem;
        }
      }
      &__information {
        &__text {
          font: normal normal normal 22px/15px Poppins;
          margin-right: 1.6rem;
        }
      }
    }
    &__clientPortfolio {
      width: 100%;
    }
    &__messageIcon {
      display: none;
    }
  }
  .profile__information {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    &__container__one {
      width: auto;
    }
  }
  .tab__mainconatiner {
    width: 100% !important;
  }
  .about {
    &__locationInformation {
      &__text {
        font: normal normal normal 16px/27px Poppins;
      }
    }
    &__container {
      &__paragraph {
        width: 100%;
      }
    }
  }
  .service {
    display: flex;
    width: 100% !important;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 0rem;
    position: relative;
    right: 0 !important;
    &__boxContainer {
      height: 100% !important;
      width: 50%;
      &__image {
        width: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &__boxsecondContainer {
      height: 100% !important;
      width: 50% !important;
      position: relative;
      left: 1rem;
      text-align: center;
    }

    &__buttonsBox__register {
      display: none !important;
    }
  }
  .service > :nth-child(3) {
    margin-top: 1rem;
  }
  .review {
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    height: 90vh;
    &__boxContainer {
      width: 90%;
      position: relative;
      right: 0rem;
      &__paragraph {
        height: 85px;
      }
    }
  }
}
@media only screen and (max-width: 560px) {
  .clientPorfile {
    display: flex;
    flex-wrap: wrap;
    &__profile {
      width: 100%;
      &__image {
        display: flex;
        &__box {
          width: 95%;
          padding: 0 2rem;
          margin: 0 3rem;
          margin-right: 2rem;
        }
        img {
          width: 100%;
        }
        &__heartIcon {
          width: 6%;
          display: block;
          color: $BLUE_COLOR;
          margin-top: 2rem;
          position: relative;
          right: 1.2rem;
        }
      }
      &__name {
        &__title {
          width: 60%;
          font: normal normal bold 40px/20px Yu Gothic;
        }
      }
      &__rating {
        &__number {
          font: normal normal normal 20px/20px Yu Gothic;
          margin-top: 0.5rem;
        }
      }
      &__information {
        &__text {
          font: normal normal normal 18px/15px Poppins;
          margin-right: 0.5rem;
        }
      }
    }
    &__clientPortfolio {
      width: 100%;
    }
    .tab__mainconatiner {
      width: 100% !important;
    }
  }
  .profile__information {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    &__container__one {
      width: auto;
    }
  }
  .review {
    &__boxContainer {
      position: relative;
      right: 0rem;
      width: 100%;
      &__rating {
        &__profile {
          &__image {
            img {
              width: 50px;
              height: 50px;
            }
          }
          &__information {
            margin-left: 0.5rem;
            &__name {
              font: normal normal bold 20px/10px Yu Gothic;
            }
            &__point {
              &__number {
                font: normal normal normal 15px/37px Roboto;
              }
              &__iconbox {
                font: normal normal normal 15px/37px Roboto;
              }
            }
          }
        }
        &__days {
          font: normal normal normal 11px/63px Roboto;
        }
      }
      &__paragraph {
        height: 90px;
        font: normal normal normal 14px Roboto;
      }
    }
  }
  .portfolio__tab {
    width: 1% !important;
  }
}
@media only screen and (max-width: 420px) {
  .clientPorfile {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &__profile {
      width: 100%;
      margin-left: 0.6rem;
      &__image {
        display: flex;
        &__box {
          width: 100%;
          img {
            width: 200;
          }
        }
      }
      &__name {
        &__title {
          width: 50%;
          font: normal normal bold 25px/0px Yu Gothic;
        }
      }
      &__rating {
        &__number {
          font: normal normal normal 20px/10px Yu Gothic;
          margin-top: 0.5rem;
        }
      }
      &__information {
        &__text {
          font: normal normal normal 18px/25px Poppins;
          margin-left: 0.6rem;
        }
      }
    }
    .about__tab {
      width: 50% !important;
    }
    .service__tab {
      width: 50% !important;
    }
    .review__tab {
      width: 50% !important;
    }
    .portfolio__tab {
      width: 50% !important;
    }
  }
  .review {
    &__boxContainer {
      &__rating {
        &__profile {
          &__image {
            img {
              width: 30px;
              height: 30px;
            }
          }
          &__information {
            &__name {
              font: normal normal bold 13px/0px Yu Gothic;
              position: relative;
              bottom: 0.5rem;
            }
            &__point {
              &__number {
                font: normal normal normal 12px/20px Roboto;
              }
              &__iconbox {
                font: normal normal normal 0px/20px Roboto;
                position: relative;
                bottom: 0.34rem;
                width: 85%;
              }
            }
          }
        }
        &__days {
          font: normal normal normal 8px/20px Roboto;
        }
      }
      &__paragraph {
        font: normal normal normal 12px Roboto;
      }
    }
  }
}
@media only screen and (max-width: 370px) {
  .tab__mainconatiner {
    width: 100% !important;
    padding: 1rem !important;
  }
}
.css-19kzrtu {
  padding: 0rem !important;
}
