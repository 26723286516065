.clientProfile {
    display: flex;
    margin: 9rem 2rem 5rem 2rem;
    justify-content: center;

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        left: 2rem;

        @media (max-width: 1000px) {
            width: 100%;
        }

        &__image {
            &__box {
                display: flex;
                justify-content: center;
            }
            img {
                width: 100%;
                height: 250px;
                box-shadow: 3px 4px 7px $BLACK_COLOR;
                object-fit: cover;
                border-radius: 1rem;

                @media (max-width: 500px) {
                    width: 80%;
                }
            }

            &__editIcon {
                display: flex;
                justify-content: center;
                background-color: $DARK_GREEN_COLOR;
                color: $WHITE_COLOR;
                cursor: pointer;
                position: relative;
                right: 1.5rem;
                height: 40px;
                width: 40px;
                align-items: center;
                text-align: center;
                bottom: 0.8rem;
                clip-path: circle(40%);
            }
        }

        &__title {
            font: normal normal bold 27px/10px Yu Gothic;
            color: $LIGHT_BLACK_COLOR;
            margin-right: 1rem;
            margin-top: 2rem;
        }

        
        &__text {
            font: normal normal normal 21px/0px Poppins;
            color: $DARK_GRAY_COLOR;
            margin-top: 1.5rem;
            opacity: 0.8;
            margin-right: 2.2rem;
        }

        &__about {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center !important;
            justify-content: center !important;

            &__email__container {
                display: flex;
                margin-top: 2rem;

                &__icon {
                    color: $DARK_GRAY_COLOR !important;
                }

                h4 {
                    margin-top: 0.2rem;
                    margin-left: 1rem;
                    font: normal normal normal 19px/20px Poppins;
                    color: $DARK_GRAY_COLOR;

                    @media (max-width: 400px) {
                        font: normal normal normal 16px/20px Poppins;
                    }
                }
            }

            &__contact__container {
                display: flex;
                margin-top: 1rem;

                @media (max-width: 600px) {
                    margin-right: 0rem;
                }

                &__callIcon {
                    color: $DARK_GRAY_COLOR;
                    margin-top: 0.2rem;
                }

                &__country__image {
                    margin-left: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 30px;
                    }
                }

                h4 {
                    color: $DARK_GRAY_COLOR;
                    margin-left: 0.4rem;
                    font: normal normal normal 18px/30px Poppins;

                    @media (max-width: 400px) {
                        font: normal normal normal 16px/20px Poppins;
                        margin-top: 0.5rem;
                    }
                }
            }

            &__locationInformation {
                display: flex;
                margin-top: 1rem;

                @media (max-width: 600px) {
                    margin-right: 0rem;
                }

                &__cityIcon {
                    color: $DARK_GRAY_COLOR;
                }

                h4 {
                    font: normal normal normal 18px/27px Poppins;
                    margin-left: 1rem;
                    color: $DARK_GRAY_COLOR;

                    @media (max-width: 400px) {
                        font: normal normal normal 16px/20px Poppins;
                    }
                }
            }

            &__container {
                width: 60%;
                margin-top: 0.5rem;
                margin-left: 3rem;

                @media (max-width: 768px) {
                    margin-left: 0rem;
                    width: 70%;
                }

                @media (max-width: 600px) {
                    width: 100%;
                }

                h4 {
                    font-size: 23px;
                    color: $DARK_GRAY_COLOR;
                    position: relative;
                    right: 1rem;
                }

                &__paragraph {
                    text-align: justify;
                    word-wrap: none;
                    width: 80%;
                    margin-top: 0.5rem;
                    font: normal normal normal 17px/25px Poppins;
                    color: $DARK_GRAY_COLOR;
                }
            }
        }
    }

    &__profilesetting {
        width: 30%;
        background-color: $LIGHT_WHEAT_COLOR;
        padding: 1rem;

        @media (max-width: 1000px) {
            background-color: transparent;
            width: auto;
            margin-top: 1rem;
        }

        &__section {
            &__title {
                text-align: left;
                margin-top: 0.5rem;
                font: normal normal medium 100px/28px Poppins;
                letter-spacing: 0.6px;
                color: $BLUE_COLOR;
                opacity: 1;
                font-size: 25px;
                font-weight: 600;
            }

            &__box {
                background: $WHITE_COLOR 0% 0% no-repeat padding-box;
                border: 1px solid $LIGHT_RED_COLOR;
                border-radius: 12px 12px 0px 0px;
                margin-top: 1rem;

                &__container {
                    display: flex;
                    justify-content: space-between;
                    text-align: left;
                    font: normal normal 600 15px/24px Yu Gothic;
                    color: $LIGHT_BLACK_COLOR;
                    border-bottom: 1px solid $LIGHT_RED_COLOR;
                    padding: 0.5rem;
                    cursor: pointer;

                    &__logouttitle {
                        color: $RED_COLOR;
                    }

                    &__icon {
                        color: $LIGHT_RED_COLOR;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}