.myAds {
  margin-top: 5rem;
  margin-left: 250px;
  padding: 1rem 2rem;
  @include fadeIn;

  @media (max-width: 768px) {
    padding: 1rem 1rem;
    margin-left: 0;
  }

  &__body {
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        color: $BLUE_COLOR;
      }
      @media (max-width: 367px) {
        font-size: 12px;
      }
    }
    &__compulsoryData{
      margin-top: 20px;
      display: flex;
      align-items: center;
      color: red;
      border: 1px solid red;
      padding: 5px;
      margin-bottom: 15px;
      border-radius: 5px;
    }
    &__container2 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
      @media (max-width: 768px) {
        justify-content: center;
      }
      &__adsContainer2 {
        margin: 1rem 0;
        width: 500px;
        @media (max-width: 880px) {
          width: 500px;
        }
        @media (max-width: 600px) {
          margin: 0rem;
          padding: 1rem;
        }
        @media (max-width: 550px) {
          width: 500px;
        }
        @media (max-width: 470px) {
          width: 400px;
        }
        @media (max-width: 375px) {
          width: 340px;
        }
        &__adCard {
          border-radius: 1rem;
          box-shadow: $BOX_SHADOW;
          background-color: $WHITE_COLOR;
          padding: 1.5rem;

          @media (max-width: 1125px) {
            min-width: 100%;
          }

          &__profile {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            border-bottom: 1px solid lightgray;
            padding-bottom: 0.5rem;
            word-break: break-all;
            &__image {
              display: flex;
              align-items: flex-start;
              img {
                width: 110px;
                height: 120px;
                object-fit: cover;
                border-radius: 1rem;
                margin-top: 0.5rem;
              }

              &__icon {
                color: $GOLD_COLOR;
                font-size: 3rem !important;
              }
            }

            &__nameBox {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              h2 {
                margin-top: 0.5rem;
              }

              span {
                color: $GRAY_COLOR;
                text-transform: uppercase;
                margin-top: 0.3rem;
              }

              h5 {
                color: $GRAY_COLOR;
                font-size: 0.8rem;
                margin-top: 0.8rem;

                b {
                  margin-left: 0.5rem;
                  // white-space: nowrap;
                  color: black;
                }
              }
            }
          }

          &__services {
            margin-top: 0.5rem;
            border-bottom: 1px solid lightgray;
            &__options {
              display: flex;
              align-items: center;
              overflow-x: scroll;
              text-wrap: nowrap;
              h5 {
                margin: 0.5rem;
                background-color: $BLUE_COLOR;
                padding: 0.5rem 1.5rem;
                border-radius: 3rem;
                color: $WHITE_COLOR;
                font-size: 0.9rem;
                font-weight: 500;
              }
            }

            &__address {
              display: flex;
              align-items: center;
              margin-top: 0.5rem;
              font-size: 0.9rem;
              padding-bottom: 1rem;
              word-break: break-all;
              &__icon {
                font-size: 1.2rem !important;
              }

              b {
                margin-left: 0.5rem;
                text-transform: capitalize;
              }
            }
          }

          &__details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.5rem;
            border-bottom: 1px solid lightgray;
            padding-bottom: 0.5rem;
            word-break: break-all;
            &__time {
              font-size: 0.9rem;
              color: $GRAY_COLOR;
            }

            &__price {
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: center;
              text-align: center;

              span {
                font-size: 0.8rem;
              }

              b {
                color: $BLUE_COLOR;
                font-size: 2rem;
              }
            }
          }

          &__images {
            margin-top: 0.5rem;

            &__header {
              display: flex;
              justify-content: space-between;
              align-items: center;

              &__icons {
                &__icon {
                  background-color: $BLUE_COLOR !important;
                  padding: 1px;
                  color: $WHITE_COLOR !important;
                  border-radius: 50%;
                  margin: 0 0.3rem;
                }
              }
            }

            &__imageBox::-webkit-scrollbar {
              display: none;
            }

            &__imageBox {
              display: flex !important;
              overflow: auto;
              margin-top: 1rem;
              -ms-overflow-style: none;
              scrollbar-width: none;

              img {
                border-radius: 1rem;
                height: 140px;
                width: 140px;
                object-fit: cover;
                margin: 0 0.3rem;
                box-shadow: 3px 2px 2px lightgray;
              }
            }
          }
        }
      }
    }

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;

      &__adsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5rem;

        &__adIcon {
          color: $GRAY_COLOR !important;
          font-size: 14rem !important;
        }

        p {
          color: $GRAY_COLOR;
          font-size: 2rem;
          margin-top: 1rem;
          text-transform: uppercase;
        }

        &__addNewButton {
          margin-top: 0rem;
        }
      }
    }
  }
}

.newAdModalButton {
  background-color: $BLUE_COLOR !important;
  columns: $WHITE_COLOR !important;
  padding: 0.6rem 2rem !important;
  border-radius: 0.5rem !important;
  color: white !important;
  align-items: center !important;
  @media (max-width: 550px) {
    padding: 0rem !important;
    font-weight: 0 !important;
    letter-spacing: 0 !important;
    text-transform: normal !important;
    min-width: auto;
    padding: 9px 9px !important;
    font-size: 12px !important;
  }
}

.newAdModal {
  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    header {
      text-align: center;

      h2 {
        text-transform: capitalize;
      }
    }

    &__steps {
      display: flex;
      justify-content: space-between;
      width: 250px;
      margin: 2rem 0;
      color: $GREEN_COLOR;
      font-size: 2.5rem;
      font-weight: 100;

      &__border {
        border: 1px solid $GREEN_COLOR;
        width: 250px;
        position: absolute;
        margin-top: 0.8rem;
      }

      &__step {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 222;
        background-color: white;

        &__doneIcon {
          color: $WHITE_COLOR;
          background-color: $GREEN_COLOR;
          border-radius: 50%;
          width: 30px !important;
          height: 30px !important;
          padding: 2px;
          margin-top: -1.9rem;
        }

        span {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          border: 2px solid $GREEN_COLOR;
        }

        p {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: black;
          font-size: 1rem;
          margin-top: 0.5rem;
        }
      }
    }

    &__textFields {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__group {
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }

        &__textField {
          @media (max-width: 768px) {
            width: 100% !important;
            padding: 0 0.3rem;
          }
        }
      }

      &__group2 {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0.5rem 0;
        width: 100%;

        &__textField {
          width: 49%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0.3rem;

          @media (max-width: 500px) {
            width: 100%;
          }

          p {
            margin: 0 3rem 0 0;
            font-weight: 600;
            color: $GRAY_COLOR;

            @media (max-width: 768px) {
              display: none;
            }
          }

          div {
            width: 100%;
          }
        }
      }

      &__nextButton {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__pictures {
      width: 100%;

      header {
        text-align: left;

        h3 {
          margin: 1rem 0;
        }
      }

      &__box {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 768px) {
          display: flex;
          justify-content: space-around;
        }

        img {
          width: 150px;
          height: 150px;
          object-fit: cover;
          margin: 0.7rem;
          border-radius: 0.5rem;
        }

        &__badge {
          margin: 0.7rem;
          border-radius: 1rem;
          box-shadow: $BOX_SHADOW;
          background-color: $WHITE_COLOR;
          padding: 1.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          min-width: 210px;

          &__icon {
            font-size: 5rem !important;
            background-color: $BLUE_COLOR !important;
            padding: 5px;
            border-radius: 50%;
            color: $GOLD_COLOR !important;
          }

          span {
            color: $GRAY_COLOR;
            font-size: 0.8rem;
            margin-top: 0.7rem;
            text-transform: capitalize;
            white-space: nowrap;
          }

          p {
            font-size: 0.9rem;
            margin-top: 0.7rem;
            text-transform: uppercase;
            white-space: nowrap;
          }

          strong {
            margin-top: 0.7rem;
            white-space: nowrap;
          }
        }
      }
    }

    &__badgeModal {
      margin-top: 2rem;
    }

    &__createAdButton {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;

      div {
        width: 340px;
      }
    }
    &__aboutAd{
      width: 100%;
      &__timeLineInfo{
        display: flex;
        align-items: center;
        color: gray;
        border: 1px solid #cdcdcd;
        padding: 5px;
        margin-bottom: 15px;
        border-radius: 5px;
        svg{
          margin-right: 5px;
        }
      }
      &__timeline{
        display: flex;
        justify-content: space-between;
        &__input{
            width: 47%;
        }
      }
    }
  }

}



.modalSmall {
  @media (max-width: 1000px) {
    position: unset !important;
    top: unset !important;
    left: unset !important;
    transform: translate(0%, 0%) !important;
    width: 100% !important;
    border: unset !important;
    box-shadow: unset !important;
    border-radius: unset !important;
    margin-top: unset !important;
    padding: 1rem !important;
  }
}

.swiper-wrapper {
  z-index: 0 !important;
}
.swiper {
  z-index: 0 !important;
}

.myAds__body__container2__adsContainer2__adCard__services__options::-webkit-scrollbar {
  height: 5px;
}
.myAds__body__container2__adsContainer2__adCard__services__options::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px white;
  border-radius: 10px;
}
.myAds__body__container2__adsContainer2__adCard__services__options::-webkit-scrollbar-thumb {
  background: #537198;
  border-radius: 10px;
}
.myAds__body__container2__adsContainer2__adCard__services__options::-webkit-scrollbar-thumb:hover {
  background: #537198;
}
.ad__editIcon {
  display: flex;
  justify-content: center;
  background-color: #7fc550;
  color: #ffffff;
  position: relative;
  height: 40px !important;
  width: 40px !important;
  align-items: center;
  text-align: center;
  clip-path: circle(40%);
  position: relative;
  left: 1rem;
  bottom: 0.5rem;
  cursor: pointer;
}
.flex__item {
  display: flex;
  justify-content: space-between;
}
