.myvideo {
  margin-top: 5rem;
  margin-left: 250px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    margin-left: 0px;
  }

  &__container {
    width: 100%;

    &__section {
      display: flex;
      padding: 0rem 0rem;
      width: 100%;
      margin-bottom: 1rem;
      &__noVideo {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        overflow-y: auto;
        width: 75%;
        gap: 1rem;
        padding: 1rem;
        height: 85vh;
        @media (max-width: 1130px) {
          width: 60%;
        }

        @media (max-width: 900px) {
          width: 100%;
        }

        @media (max-width: 768px) {
          width: 100%;
          overflow-y: hidden;
          height: 100%;
          justify-content: center;
        }
        &__greenbox {
          display: none;
          @media (max-width: 900px) {
            width: 100%;
            display: block;
            background: $WHITE_COLOR 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 2px $BLACK_COLOR_2;
            border: 1px solid $DARK_GREEN_COLOR;
            border-radius: 10px;
            opacity: 1;
            margin-top: 1rem;
          }
        }
        &__box {
          width: 300px;
          margin-top: 0.8rem;
          background: $WHITE_COLOR 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px $BLACK_COLOR_2;
          border: 1px solid $BLACK_COLOR_3;
          border-radius: 17px;
          position: relative;
          cursor: pointer;

          @media (max-width: 1000px) {
            width: 250px;
          }

          @media (max-width: 768px) {
            width: 85%;
          }
          &__video {
            @media (max-width: 768px) {
            }
          }
          &:hover .myvideo__container__section__container__box__hovercontainer {
            opacity: 0.76;
            height: 40px;
          }
          &__hovercontainer {
            opacity: 0;
            position: absolute;
            width: 100%;
            border: 1px solid $BLACK_COLOR_3;
            cursor: pointer;
            border-radius: 15px 15px 0 0;
            box-shadow: 0px 3px 6px $BLACK_COLOR_2;
            background: $BLUE_COLOR_1;
            transition: all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0.5rem;
            height: 40px;
            &__icon {
              color: $WHITE_COLOR;
              text-align: end;
              padding: 0.5rem;
              font-weight: bold;
            }
          }
          video {
            width: 100%;
            height: 170px !important;
            object-fit: cover;
            border-radius: 15px 15px 0px 0px;
          }

          &__paragraph {
            padding: 0rem 0.8rem;
            letter-spacing: 0px;
            color: $BLACK_COLOR_1;
            font-size: 13px;
            word-wrap: break-word;
            text-align: justify;
          }

          &__viewscontainer {
            display: flex;
            justify-content: space-between;
            padding: 0.5rem 0.8rem;
            font: normal normal medium 0px/15px Montserrat;
            letter-spacing: 0px;
            color: #6f6969;
            font-size: 12px;
          }
        }
      }
    }
  }

  &__uploadvideo {
    background-color: $WHITE_COLOR;
    width: 32%;
    position: relative;
    bottom: 1.5rem;

    @media (max-width: 1130px) {
      width: 45%;
    }

    @media (max-width: 900px) {
      width: 55%;
    }

    &__section {
      padding: 2rem 1rem;

      &__greenbox {
        background: $WHITE_COLOR 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 2px $BLACK_COLOR_2;
        border: 1px solid $DARK_GREEN_COLOR;
        border-radius: 10px;
        opacity: 1;

        &__container {
          padding: 0.3rem;
          display: flex;
          justify-content: space-between;

          &__textfileldcontainer {
            display: flex;

            &__icon {
              position: relative;
              top: 0.4rem;
              color: $BLACK_COLOR_4;
              cursor: pointer;
            }

            &__textfiled {
              input {
                margin-top: 0.5rem;
                outline: unset;
                border: unset;
                font-weight: 600;
                padding-right: 40px;
                background-color: unset;
                border-radius: 0.5rem;
                width: 100%;
                padding-left: 0.5rem;
                font: normal normal normal 11px/19px Roboto;
                letter-spacing: 0px;
                color: $BLACK_COLOR_4;
                align-items: center;
              }
            }
          }

          &__buttonbox {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            width: 30%;

            &__button {
              background-color: $BLUE_COLOR !important;
              width: 250px !important;
              columns: $WHITE_COLOR !important;
              border-radius: 0.4rem !important;
              margin-top: 0rem !important;
              color: $WHITE_COLOR !important;
              font-size: 11px !important;
            }
          }
        }
      }

      &__borderbox {
        margin-top: 1.5rem;
        background: $WHITE_COLOR 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $BLACK_COLOR_2;
        border-radius: 12px;
        padding: 1rem 1.5rem;

        &__title {
          position: relative;
          bottom: 0.2rem;
          font: normal normal bold 15px/18px Roboto;
          letter-spacing: 0px;
          color: $BLACK_COLOR_1;
        }

        &__dashedborderbox {
          margin-top: 0.5rem;
          border: 3px dashed #768ead !important;
          border-radius: 10px;
          border-width: 0.15rem;
          padding: 1.5rem 0rem;
          background-color: $WHITE_COLOR;
          border-spacing: 15px 50px;

          input {
            display: none;
          }

          &__container {
            display: flex;
            align-items: center;
            justify-content: center;

            &__icon {
              font-size: 10px;
              color: $BLACK_COLOR_1;
            }

            &__text {
              font: normal normal bold 13px/18px Roboto;
              letter-spacing: 0px;
              color: $BLACK_COLOR_1;
            }
          }

          &__buttonbox {
            margin-top: 0.5rem;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            width: 100%;
            margin-left: 0.3rem;
            &__button {
              background-color: $BLUE_COLOR !important;
              width: 145px !important;
              columns: $WHITE_COLOR !important;
              padding: 0.6rem 1rem !important;
              border-radius: 0.4rem !important;
              margin-top: 0rem !important;
              color: $WHITE_COLOR !important ;
              font-size: 11px !important;
            }
          }
        }
      }
      &__greeninput {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        border: 2px solid $GREEN_COLOR;
        border-radius: 0.7rem;
        padding: 0.3rem 1rem;
        margin-bottom: 0rem;
        width: 100%;
        background-color: $WHITE_COLOR;
        label {
          color: $LIGHT_BLACK_COLOR;
          font-size: 13px;
          font-weight: 600;
        }
        select {
          outline: unset;
          border: unset;
          font-weight: 600;
          color: $BLACK_COLOR_4;
          opacity: 0.5;
          margin-top: 0.6rem;
          width: 100%;
        }
      }
      &__greenTextarea {
        border: 2px solid $GREEN_COLOR;
        border-radius: 0.7rem;
        padding: 0.8rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        background-color: $WHITE_COLOR;

        label {
          color: $LIGHT_BLACK_COLOR;
          font-size: 13px;
          font-weight: 600;
        }

        &__textBox {
          margin-top: 0.3rem;

          textarea {
            border: unset;
            outline: unset;
            width: 100%;
            font-weight: 600;
            font-size: 15px;
            color: $BLACK_COLOR_4;
            opacity: 0.5;
            &::placeholder {
              font-weight: normal;
            }
          }
        }
      }
      &__uploadbuttoncontainer {
        margin-top: 1.5rem;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: 100%;
        &__button {
          background-color: $BLUE_COLOR !important;
          width: 270px !important;
          columns: $WHITE_COLOR !important;
          padding: 0.6rem 2rem !important;
          border-radius: 0.4rem !important;
          margin-top: 0rem !important;
          color: $WHITE_COLOR !important ;
          font-size: 11px !important;
        }
      }
    }
  }
}

.myvideo__container__section__container::-webkit-scrollbar {
  width: 5px;
}
.myvideo__container__section__container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $WHITE_COLOR;
  border-radius: 10px;
}
.myvideo__container__section__container::-webkit-scrollbar-thumb {
  background: #537198;
  border-radius: 10px;
}
.myvideo__container__section__container::-webkit-scrollbar-thumb:hover {
  background: #537198;
}
.tab__container {
  width: 100% !important;
}
@media (max-width: 768px) {
  .tab__container {
    position: relative;
    bottom: 3px;
  }
  .css-heg063-MuiTabs-flexContainer {
    position: static;
    width: 100%;
  }
  .css-old1by {
    padding: 3rem 0rem;
  }
  .MuiTabs-scroller {
    width: 100% !important;
  }
}
.videoStat__container {
  padding: 3rem;
  @media (max-width: 1000px) {
    padding: 3rem 0rem;
  }
  @media (max-width: 900px) {
    padding: 3rem 2rem;
    flex-wrap: nowrap;
  }

  &__tabeleHeader {
    background: #8598ad29;
    position: relative;
    bottom: 1.5rem;
    padding: 1rem;
    &__tablecell {
      font: normal normal bold 15px/18px Roboto !important;
      color: $BLACK_COLOR_1 !important;
      @media (max-width: 555px) {
        min-width: 200px !important;
        flex-wrap: nowrap !important;
        font: normal normal bold 12px/18px Roboto !important;
      }
    }
  }
  &__tableBody {
    background-color: $WHITE_COLOR !important;
    border: 1px solid $WHITE_COLOR !important;
    border-radius: 100px !important;
    &__tablecell {
      font: normal normal normal 14px/17px Roboto !important;
      color: $BLACK_COLOR_1 !important;
    }
  }
}
.css-1pclhgl-MuiTable-root {
  @media (max-width: 721px) {
    width: 50% !important;
    min-width: 0px;
  }
}
