.adFormfirstFormButton {
    display: flex;
    justify-content: end;
}
.adVideoForm {
    display: flex;
    justify-content: end;
    Button {
        margin: 10px;
    }
    &__Button{
        margin-top: 2rem;
    }
}
.newAdd__tablecontainer {
    display: flex;
    background-color: #f8f8f8;
    align-items: center;
    padding: 0.5rem 0.5rem;
    border-radius: 6px;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    @media (max-width: 500px) {
        flex-wrap: wrap;
    }
    &__dollartextfield {
        width: 49%;
        @media (max-width: 500px) {
            width: 100%;
        }
        &__greenInput {
            display: flex;
            flex-direction: column;
            border: 2px solid #87c85b;
            background-color: #ffffff;
            border-radius: 0.7rem;
            padding: 0rem 1rem;
            label {
                color: #444444;
                font-size: 10px;
                font-weight: 600;
            }
        }
    }
}
.addForm__serviceHourTable {
    background-color: #f8f8f8;
    margin-top: 1rem;
    border-radius: 10px;
    overflow: auto;
    margin-bottom: 1rem;
    text-wrap: nowrap;
    &__container {
        display: flex;
        align-items: center;
        padding: 2rem;
        overflow: auto;
        gap: 1rem;
        justify-content: space-between;
        @media (max-width: 900px) {
            width: 130%;
        }
        @media (max-width: 550px) {
            width: 160%;
        }
        @media (max-width: 450px) {
            width: 250%;
        }
        &__day {
            color: #363636;
            font-family: "Poppins", sans-serif !important;
        }
    }
}

.error{
    color: red !important;
}