// Green Input

.greenInput {
  height: 60px;
  display: flex;
  flex-direction: column;
  border: 2px solid $GREEN_COLOR;
  border-radius: 1rem;
  padding: 0rem 1rem;
  // padding: 0.7rem 1rem;

  margin-bottom: 1rem;
  width: 100%;

  label {
    color: $GRAY_COLOR;
    font-size: 13px;
    font-weight: 600;
  }

  &__inputBox {
    display: flex;
    justify-content: flex-end;

    input {
      margin-top: 0.5rem;
      outline: unset;
      border: unset;
      color: $BLUE_COLOR;
      font-weight: 600;
      font-size: 15px;
      padding-right: 40px;
      background-color: unset;
      border-radius: 0.5rem;
      width: 100%;
      padding-left: 0.5rem;

      &::placeholder {
        font-weight: normal;
      }
    }

    &__icon {
      position: absolute;
      color: $GRAY_COLOR;
      margin-top: -10px;
    }
  }
}

// Green Select

.greenSelect {
  height: 60px;
  display: flex;
  flex-direction: column;
  border: 2px solid $GREEN_COLOR;
  border-radius: 1rem;
  padding: 0rem 1rem;
  margin-bottom: 1rem;
  width: 100%;

  label {
    color: $GRAY_COLOR;
    font-size: 13px;
    font-weight: 600;
  }

  select {
    outline: unset;
    border: unset;
    font-weight: 600;
    color: $GRAY_COLOR;
    margin-top: 0.6rem;

    option {
      font-weight: 600;
      color: $GRAY_COLOR;
    }
  }
}

// Blue Button

.blueButton {
  background-color: $BLUE_COLOR !important;
  text-transform: capitalize !important;
  width: 100% !important;
  border-radius: 0.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  span{
    color: white !important;
  }
}

// Green Radio Input

.greenRadioInput {
  border: 2px solid $GREEN_COLOR;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1rem;

  label {
    color: $DARK_GRAY_COLOR;
    margin-left: 1rem;
    font-size: 0.9rem;
  }

  input {
    accent-color: $BLUE_COLOR;
    height: 1rem;
    width: 1rem;
  }
}

// Green Textarea

.greenTextarea {
  border: 2px solid $GREEN_COLOR;
  border-radius: 1rem;
  padding: 0.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.7rem;

  label {
    color: $GRAY_COLOR;
    font-size: 13px;
    font-weight: 600;
  }

  &__textBox {
    margin-top: 0.3rem;

    textarea {
      border: unset;
      outline: unset;
      width: 100%;
      color: $BLUE_COLOR;
      font-weight: 600;
      font-size: 15px;

      &::placeholder {
        font-weight: normal;
      }
    }
  }
}

.rmsc .dropdown-container{
  margin: 12px 1px;
  border: 2px solid #87C85B !important;
  border-radius: 12px !important;
  padding: 8px;
  color: $GRAY_COLOR
}