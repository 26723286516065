.profile {
  height: 100%;
  min-height: 100vh;

  &__container {
    padding: 0rem 5rem;
    padding-top: 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1250px) {
      padding: 0rem 3rem;
      padding: 3rem;
    }

    @media (max-width: 900px) {
      padding: 0rem 1rem;
      padding-top: 3rem;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      padding-top: 3rem;
    }

    &__left {
      width: 55%;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 2rem;
      }

      &__logo {
        display: flex;
        justify-content: center;
        width: 100%;

        &__logoImage {
          width: 300px;
          object-fit: cover;
          cursor: pointer;
          @media (max-width: 768px) {
            width: 200px;
          }
        }
      }

      &__box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__image1 {
          width: 540px;
          object-fit: cover;
          margin-top: 7.3rem;
          transition: width 0.5s;

          @media (max-width: 1250px) {
            margin-top: 7.8rem;
          }

          @media (max-width: 1100px) {
            width: 450px;
            margin-top: 11.9rem;
          }

          @media (max-width: 1000px) {
            width: 400px;
            margin-top: 14.8rem;
          }

          @media (max-width: 925px) {
            width: 400px;
            margin-top: 15.5rem;
          }

          @media (max-width: 900px) {
            width: 400px;
            margin-top: 13.8rem;
          }

          @media (max-width: 768px) {
            width: 320px;
            margin-top: 5rem;
            margin-left: 1.5rem;
          }
        }
      }

      &__stripe {
        background-color: $LIGHT_GRAY_COLOR;
        width: 100%;
        height: 100px;
        margin-top: -100px;
      }
    }

    &__right {
      width: 45%;
      box-shadow: 1px 1px 15px lightgray;
      border-radius: 1rem;
      z-index: 9999999 !important;
      background-color: $WHITE_COLOR;
      padding: 1.5rem 0 2rem 0;

      @media (max-width: 768px) {
        width: 100%;
        box-shadow: unset;
      }

      &__profileDetails {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2rem 0 0 0;

        &__container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 70%;

          @media (max-width: 900px) {
            width: 90%;
          }

          &__imageUpload {
            &__label {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              &__icon {
                font-size: 2rem !important;
                color: $WHITE_COLOR !important;
                position: absolute;
                margin-top: -0.2rem;
              }

              img {
                width: 80px;
                height: 80px;
                object-fit: cover;
                border-radius: 50%;
                background-color: $LIGHT_GRAY_COLOR;
                z-index: -1;
              }
            }

            &__input {
              visibility: hidden;
            }
          }

          &__addPicture {
            margin-top: -1rem;
            font-weight: bolder !important;
            text-transform: capitalize;
            padding-bottom: 1rem;
            border-bottom: 1px solid lightgray;
            width: 100%;
            text-align: center;
          }

          &__address {
            font-weight: bolder;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            width: 100%;
          }

          &__cityState {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &__city {
              width: 49%;
            }

            &__state {
              width: 49%;
            }
          }

          &__continueButton {
            background-color: $BLUE_COLOR !important;
            text-transform: capitalize !important;
            width: 90% !important;
            margin: 2rem 0 0 0 !important;
            border-radius: 0.5rem !important;
            font-size: 1rem !important;
            padding-top: 0.7rem !important;
            padding-bottom: 0.7rem !important;
            margin-bottom: 1rem !important;
          }
        }
      }
    }
  }

  &__stripe1 {
    background-color: $LIGHT_GRAY_COLOR;
    width: 100%;
    height: 180px;
    margin-top: -220px;
    z-index: -999999;

    @media (max-width: 1250px) {
      margin-top: -260px;
    }

    @media (max-width: 1100px) {
      height: 150px;
      margin-top: -230px;
    }

    @media (max-width: 1000px) {
      height: 140px;
      margin-top: -230px;
    }

    @media (max-width: 925px) {
      height: 140px;
      margin-top: -220px;
    }

    @media (max-width: 900px) {
      height: 140px;
      margin-top: -180px;
    }
  }
}
