.provider {
  display: flex;
  margin-top: 5rem;
  margin-left: 250px;
  height: 100%;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 768px) {
    margin-left: unset;
    margin-top: 7rem;
  }
  &__container {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    left: 2rem;
    margin-top: 1rem;
    @media (max-width: 1000px) {
      width: 95%;
    }
    &__image {
      &__box {
        display: flex;
        justify-content: center;
      }
      img {
        width: 370px;
        height: 250px;
        box-shadow: 3px 4px 7px $BLACK_COLOR;
        object-fit: cover;
        border-radius: 1rem;
        @media (max-width: 768px) {
          width: 60%;
        }
        @media (max-width: 500px) {
          width: 75%;
        }
      }
      &__editIcon {
        display: flex;
        justify-content: center;
        background-color: $DARK_GREEN_COLOR;
        color: $WHITE_COLOR;
        cursor: pointer;
        position: relative;
        right: 1.5rem;
        height: 40px;
        width: 40px;
        align-items: center;
        text-align: center;
        bottom: 0.8rem;
        clip-path: circle(40%);
      }
    }

    &__title {
      font: normal normal bold 27px/10px Yu Gothic;
      color: $LIGHT_BLACK_COLOR;
      margin-right: 1rem;
      margin-top: 2rem;
    }

    &__rating {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.5rem;
      text-align: center;
      margin-right: 1rem;
      &__number {
        color: $LIGHT_BLACK_COLOR;
        font: normal normal normal 23px/0px Yu Gothic;
        text-align: center;
      }
      &__starIcon {
        color: $GLODEN_COLOR;
        position: relative;
        top: 0.1rem;
        margin-left: 0.3rem;
      }
    }
    &__text {
      font: normal normal normal 21px/0px Poppins;
      color: $DARK_GRAY_COLOR;
      margin-top: 1.5rem;
      opacity: 0.8;
      margin-right: 2.2rem;
    }

    &__about {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center !important;
      justify-content: center !important;
      &__email__container {
        display: flex;
        margin-top: 2rem;
        &__icon {
          color: $DARK_GRAY_COLOR !important;
        }
        h4 {
          margin-top: 0.2rem;
          margin-left: 1rem;
          font: normal normal normal 19px/20px Poppins;
          color: $DARK_GRAY_COLOR;
          @media (max-width: 400px) {
            font: normal normal normal 16px/20px Poppins;
          }
        }
      }
      &__contact__container {
        display: flex;
        margin-top: 1rem;
        @media (max-width: 600px) {
          margin-right: 0rem;
        }

        &__callIcon {
          color: $DARK_GRAY_COLOR;
          margin-top: 0.2rem;
        }
        &__country__image {
          margin-left: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 30px;
          }
        }
        h4 {
          color: $DARK_GRAY_COLOR;
          margin-left: 0.4rem;
          font: normal normal normal 18px/30px Poppins;
          @media (max-width: 400px) {
            font: normal normal normal 16px/20px Poppins;
            margin-top: 0.5rem;
          }
        }
      }
      &__locationInformation {
        display: flex;
        margin-top: 1rem;
        @media (max-width: 600px) {
          margin-right: 0rem;
        }
        &__cityIcon {
          color: $DARK_GRAY_COLOR;
        }
        h4 {
          font: normal normal normal 18px/27px Poppins;
          margin-left: 1rem;
          color: $DARK_GRAY_COLOR;
          @media (max-width: 400px) {
            font: normal normal normal 16px/20px Poppins;
          }
        }
      }
      &__container {
        width: 60%;
        margin-top: 0.5rem;
        margin-left: 3rem;
        text-align: justify;
        @media (max-width: 768px) {
          margin-left: 0rem;
          width: 100%;
          text-align: justify;
        }
        
        h4 {
          font-size: 23px;
          color: $DARK_GRAY_COLOR;
          position: relative;
          right: 1rem;
        }
        &__paragraph {
          text-align: justify;
          word-wrap: none;
          width: 80%;
          margin-top: 0.5rem;
          font: normal normal normal 17px/25px Poppins;
          color: $DARK_GRAY_COLOR;
        }
      }
    }
  }

  &__profilesetting {
    width: 30%;
    background-color: $LIGHT_WHEAT_COLOR;
    padding: 1rem;
    @media (max-width: 1000px) {
      background-color: transparent;
      width: auto;
      margin-top: 1rem;
    }
    &__section {
      &__title {
        text-align: left;
        margin-top: 0.5rem;
        font: normal normal medium 100px/28px Poppins;
        letter-spacing: 0.6px;
        color: $BLUE_COLOR;
        opacity: 1;
        font-size: 25px;
        font-weight: 600;
      }

      &__box {
        background: $WHITE_COLOR 0% 0% no-repeat padding-box;
        border: 1px solid $LIGHT_RED_COLOR;
        border-radius: 12px 12px 0px 0px;
        margin-top: 1rem;

        &__container {
          display: flex;
          justify-content: space-between;
          text-align: left;
          font: normal normal 600 15px/24px Yu Gothic;
          color: $LIGHT_BLACK_COLOR;
          border-bottom: 1px solid $LIGHT_RED_COLOR;
          padding: 0.5rem;
          cursor: pointer;
          &__logouttitle {
            color: $RED_COLOR;
          }
          &__icon {
            color: $LIGHT_RED_COLOR;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.termscondition__section {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__parahraph {
    margin-top: 1rem;
    color: #2b4091;
    font: normal normal normal 18px/23px Roboto;
    text-align: justify;
    word-wrap: none;
    &__one {
      margin-top: 1rem;
    }
    &__two {
      margin-top: 1rem;
    }
  }
}
.profilesection {
  @media (max-width: 768px) {
    overflow-y: auto !important;
    height: 100vh !important;
    border-radius: 0px !important;
    width: 100% !important;
  }
  @media (max-width: 450px) {
    overflow-y: auto !important;
    width: 100% !important;
    height: 100vh !important;
  }
  
}
.editprofilesection {
  @media (max-width: 830px) {
    overflow-y: auto !important;
    height: 100vh !important;
    border-radius: 0px !important;
    width: 100% !important;
  }
  @media (max-width: 450px) {
    overflow-y: auto !important;
    width: 100% !important;
    height: 100vh !important;
  }
}
.profileedit {
  width: 100%;
  background-color: transparent;

  &__container {
    padding: 0rem 0rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: padding 0.5s;
    @media (max-width: 768px) {
      box-shadow: unset;
      border-radius: unset;
      height: 100%;
    }
    @media (max-width: 487px) {
      width: 100%;
    }
    &__body {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0rem 0;
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        width: 90%;
      }
      @media (max-width: 487px) {
        display: flex;
        flex-direction: column;
        width: 90%;
      }
    }
    &__divider {
      border-left: 2px solid lightgray;
      height: 360px;
      position: relative;
      top: 2rem;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
@media (max-width: 768px) {
  .css-1q45p5z {
    width: 471px !important;
    overflow-y: auto !important;
    height: 100vh !important;
  }
}
@media (max-width: 487px) {
  .css-1q45p5z {
    width: 400px !important;
    overflow-y: auto !important;
    height: 100vh !important;
  }
}
@media (max-width: 400px) {
  .css-1q45p5z {
    width: 350px !important;
    overflow-y: auto !important;
    height: 100vh !important;
  }
}
.modal__container {
  display: flex;
  justify-content: space-between;
  &__icon {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
}
.editService__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__header{
    display: flex;
    flex-direction: column;
    &__title {
      text-align: center;
      width: 100%;
    }
    &__description{
      text-align: center;
    }
  }
  
}
.passwordChange__container__passChange {
  font-weight: 800;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  width: 100%;
  text-align: center;
}
.plan {
  display: flex;
  border-radius: 50px;
  display: flex;
  background: #e3f2fd;
  width: 20%;
  padding: 0.3rem;
  align-items: center;
  color: #46a6f4;
  font-weight: 300 !important;
  margin-bottom: 1rem;
  h6 {
    font-weight: 400;
  }
  &__title {
    margin-bottom: 0.3rem;
  }
  &__paragraph {
    color: #7b7d80;
    font-size: 15px;
    margin-bottom: 1rem;
  }
  &__rupees {
    margin-bottom: 1rem;
  }
  &__list {
    display: flex;
    border-radius: 50px;
    gap: 1rem;
    align-items: center;
    font-weight: 300 !important;
    margin-bottom: 1em;
    color: #46474b;
    font-family: "Mona Sans" "Helvetica Neue" Helvetica Arial sans-serif;
    h4 {
      font-weight: 500;
    }
    &__container {
      display: flex;
      gap: 1rem;
      width: 100%;
      justify-content: space-between;

      &__first {
        display: flex;
        gap: 1rem;
      }
      &__second {
        display: flex;
        gap: 1rem;
      }
    }
  }
}

.payment__plan {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  &__detail {
    width: 70%;
    &__title {
      margin-bottom: 0.3rem;
      color: #294b71;
    }
    &__paragraph {
      color: #7b7d80;
      font-size: 15px;
    }
  }
  &__container {
    text-wrap: nowrap;
    color: #294b71;
  }
}

.background {
  background: #436082;
  height: 400px;
  width: 55%;
  clip-path: polygon(0% 0%, 75% 0, 99% 50%, 75% 100%, 0% 141%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    clip-path: none;
    height: 120px;
    display: block;
    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.popup {
  display: flex;
  gap: 2rem;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.popup__right {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}
.updateButon {
  font-size: 18px !important;
  display: inline-block !important;
  outline: 0 !important;
  border: 0 !important;
  cursor: pointer !important;
  will-change: box-shadow, transform !important;
  background: radial-gradient(100% 100% at 100% 0%, #89e5ff 0%, #5468ff 100%) !important;
  box-shadow:
    0px 0.01em 0.01em rgb(45 35 66 / 40%),
    0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%),
    inset 0px -0.01em 0px rgb(58 65 111 / 50%) !important;
  padding: 0 2em !important;
  border-radius: 0.3em !important;
  color: #fff !important;
  height: 2.6em !important;
  text-shadow: 0 1px 0 rgb(0 0 0 / 40%) !important;
  transition:
    box-shadow 0.15s ease,
    transform 0.15s ease !important;
  &:hover {
    box-shadow:
      0px 0.1em 0.2em rgb(45 35 66 / 40%),
      0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%),
      inset 0px -0.1em 0px #3c4fe0 !important;
    transform: translateY(-0.1em) !important;
    transform: scale(1.05) !important;
  }
}

.cross__icon {
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: end;
    width: 100%;
    color: white !important;
  }
}

.circularLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}

.profileModalHeader{
  display: flex;
  flex-direction: column;
  .heading{
    margin-bottom: 5px;
  }
  .description{
    margin-bottom: 2rem;
    text-align: center;
  }
}