@media only screen and (max-width: 1000px) {
  .messages {
    display: 55%;
  }
  .chatSidebar {
    width: 50%;
  }
  .chat__sidebar {
    &__border {
      display: none;
    }
    &__messageContainer {
      padding: 0.5rem;
      width: 98%;
      &__subContainer {
        &__name {
          font: normal normal bold 13px/20px Yu Gothic;
        }
        &__message {
          &__paragraph {
            width: auto;
            font-size: 10px;
          }
          &__days {
            width: 40%;
            margin-top: 2rem;
            text-align: end;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .messages {
    display: none;
  }
  .chatSidebar {
    width: 100%;
  }
  .chat__sidebar {
    padding: 0rem;

    &__searchContainer {
      display: none;
    }
    &__title {
      display: none;
    }
    &__responsivetitlebar {
      display: block;
      display: flex;
      border-bottom: 1px solid $WIGHT_COLOR_1;
      &__icon {
        position: relative;
        top: 1.5rem;
        left: 1rem;
      }
      &__title {
        width: 100%;
        text-align: center;
        margin-top: 0.5rem;
        font: normal normal normal 30px/50px Poppins;
        color: $DARK_LIGHTGRAY_COLOR;
        opacity: 1;
        align-items: center;
        span {
          text-align: center;
        }
      }
    }
    &__border {
      display: none;
    }
    &__messageContainer {
      padding: 0.5rem 1rem;
      border-bottom: 1px solid $WIGHT_COLOR_1;
      cursor: pointer;
      width: 99%;
      &__image {
        img {
          position: relative;
          bottom: 0.2rem;
        }
      }
      &__subContainer {
        padding: 0rem;
        width: 95%;
        &__name {
          font: normal normal bold 15px/20px Yu Gothic;
        }
        &__message {
          &__paragraph {
            width: auto;
            font-size: 12px;
          }
          &__days {
            width: 40%;
            margin-top: 1.18rem;
            text-align: end;
          }
        }
      }
    }
  }
  .message {
    padding: 1rem;
    &__profileContainer {
      &__image {
        margin-left: 0.6rem;
      }
      &__namecontainer {
        margin-left: 0.5rem;
      }
    }
    &__receiver {
      &__paragraph {
        padding: 0.3rem;
      }
    }
    &__sendMessageContainer {
      width: 99%;
      &__maincontainer {
        position: relative;
        bottom: 0.3rem;
      }
    }
  }
}
@media only screen and (max-width: 435px) {
  .chat__sidebar__messageContainer {
    width: 97%;
  }
}
