.clientAdPorfile {
  display: flex;
  @include fadeIn;
  margin: 0 40px;
  flex-direction: column;
  &__profile {
    &__image {
      width: 100%;
      padding: 0px 10px;
      &__box {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      img {
        width: 100%;
        box-shadow: 3px 4px 7px $BLACK_COLOR;
        object-fit: cover;
        margin-top: 2rem;
        border-radius: 1rem;
        height: 330px;
      }
      &__heartIcon {
        display: none;
      }
    }
    &__name {
      background: #7fc550;
      border-radius: 10px;
      display: flex;

      justify-content: space-between;
      padding: 0px 10px;
      align-items: center;
      margin-top: 2.5rem;
      &__title {
        width: 55%;
        text-align: center;
        font: normal normal bold 35px/30px Yu Gothic;
      }
      &__heartIcon {
        color: $BLUE_COLOR;
        display: flex;
      }
      &__messageIcon {
        display: none;
      }
    }
    &__rating {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
      margin-top: 0.5rem;
      &__number {
        color: $DARK_GRAY_COLOR;
        font: normal normal normal 22px/10px Yu Gothic;
        margin-right: 0.2rem;
        letter-spacing: 0px;
      }
      &__starIcon {
        color: $GLODEN_COLOR;
        position: relative;
        top: 0.1rem;
        margin-right: 0.6rem;
        margin-left: 0.3rem;
      }
    }
    &__information {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0.5rem;

      &__text {
        font: normal normal normal 17px/15px Poppins;
        color: $DARK_GRAY_COLOR;
        letter-spacing: 0px;
        opacity: 0.8;
        margin-right: 0.7rem;
        height: 50px;
      }
    }
  }
  &__clientPortfolio {
    width: 75%;

    &__overview {
      width: 100px;
      background: green;
      padding: 10px;
      border-radius: 5px;
      font-style: italic;
      &__serviceInfo {
        display: flex;
        justify-content: space-between;
        &__title {
          font: normal normal bold 20px/30px Yu Gothic;
          color: $DARK_GRAY_COLOR;
        }
        &__icon {
          color: $DARK_GRAY_COLOR;
        }
      }

      &__description {
        padding: 20px 10px;
      }
    }
  }
  &__messageIcon {
    width: 5%;
    margin-top: 3rem;
    margin-left: 0.3rem;
    cursor: pointer;
    height: 5vh;
    color: $BLUE_COLOR;
    display: none;
  }

  &__basicInfo {
    margin-top: 5px;
    &__services {
      color: #102843;
      font-size: 22px;
      font-family: math !important;
      &__fullName {
        cursor: pointer;
        font-size: 18px;
        font-family: cursive;
        font-style: italic;
        text-decoration: underline;
        color: #7fc550;
      }
    }
  }
  &__mainContainer {
    margin-top: 0px !important;
    @media only screen and (max-width: 950px) {
      flex-direction: column-reverse !important;
      display: flex !important;
    }
    &__adDetails {
      overflow: auto;
      height: 100%;
      display: flex;
      justify-content: space-between;
      &__informationContainer {
        margin-top: 10px;
        &__heading {
          font-size: 22px !important;
          font-family: cursive !important;
          background-color: #7fc550;
          color: #102843;
          border-radius: 5px;
          padding: 0.2rem 0.4rem;
          font-weight: 600 !important;
        }
        &__content {
          margin-top: 10px;
          &__description {
            font-size: 18px;
            font-family: math !important;
            color: #102843;
            font-style: italic;
            margin-top: 1rem;
          }
        }
        &__table {
          margin-top: 20px;
          &__location {
            iframe {
              @media only screen and (min-width: 950px) {
                width: 800px;
              }
            }
          }
        }
      }
      &__title {
        font: normal normal bold 20px/30px Yu Gothic;
        color: $DARK_GRAY_COLOR;
      }
      &__icon {
        color: $DARK_GRAY_COLOR;
      }
    }
    &__adPhotos {
      position: fixed;
      right: 0;
      @media only screen and (max-width: 950px) {
        position: relative;
      }
      &__container {
        display: flex;
        flex-direction: column !important;
        &__contactAt {
          display: flex;
          justify-content: space-between;
          background: #7fc550;
          padding: 0.2rem 0.4rem;
          border-radius: 5px;
          font-family: cursive !important;
          align-items: center;
          &__copyButton {
            button {
              color: #102843;
            }
          }
        }

        img {
          width: 100%;
          object-fit: cover;
          border-radius: 1rem;
          height: 300px;
          @media only screen and (max-width: 750px) {
            height: 200px;
          }
        }
      }
      &__list {
        &__container {
          img {
            padding: 5px;
            width: 100px;
            height: 100px;
            cursor: pointer;
            border-radius: 8px;
          }
          @media only screen and (max-width: 750px) {
            display: flex;
            overflow: scroll;
            gap: 1;
          }
        }
      }
    }
  }
}
.profile__information {
  flex-wrap: wrap;
  @include fadeIn;
  width: 110%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  &__container__one {
    width: auto;
    img {
      width: 230px;
      height: 240px;
      box-shadow: 0px 1px 1px $BLACK_COLOR;
      object-fit: cover;
      border-radius: 1rem;
    }
  }
}
.about {
  padding: 1rem;
  @include fadeIn;
  &__email__container {
    display: flex;
    margin-top: 1rem;
    &__icon {
      color: $DARK_GRAY_COLOR;
    }
    &__name {
      margin-top: 0.2rem;
      margin-left: 1rem;
      font: normal normal normal 19px/20px Poppins;
      color: $DARK_GRAY_COLOR;
    }
  }
  &__contact__container {
    display: flex;
    margin-top: 1.3rem;

    &__callIcon {
      color: $DARK_GRAY_COLOR;
      margin-top: 0.2rem;
    }
    &__country__image {
      margin-left: 1rem;
    }
    &__phoneNumber {
      color: $DARK_GRAY_COLOR;
      margin-left: 0.4rem;
      font: normal normal normal 18px/30px Poppins;
    }
  }
  &__locationInformation {
    display: flex;
    margin-top: 1.2rem;
    &__cityIcon {
      color: $DARK_GRAY_COLOR;
    }
    &__text {
      font: normal normal normal 18px/27px Poppins;
      margin-left: 1rem;
      color: $DARK_GRAY_COLOR;
    }
  }
  &__container {
    margin-top: 1.5rem;
    &__title {
      font-size: 23px;
      color: $DARK_GRAY_COLOR;
    }
    &__paragraph {
      text-align: justify;
      margin-top: 0.8rem;
      font: normal normal normal 17px/25px Poppins;
      color: $DARK_GRAY_COLOR;
    }
  }
}
.service {
  margin-top: 0rem;
  width: 100% !important;
  transition: all 1s;
  @include fadeIn;
  height: 60vh;
  overflow-y: scroll;
  &__card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // overflow-y: scroll;
    // height: 70vh;
    width: 100%;
    padding: 1rem;
    @media only screen and (max-width: 750px) {
      gap: 0rem;
      justify-content: center;
    }
    @media only screen and (max-width: 355px) {
      padding: 0rem;
    }
  }
  &__boxContainer {
    height: 40%;
    background: $WHITE_COLOR 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $BLACK_COLOR;
    width: 100%;
    border-radius: 2rem;
    cursor: pointer;
    img {
      width: 100%;
      padding: 0.2rem 0.2rem;
      height: 30vh;
      object-fit: cover;
      border-radius: 35px 35px 0px 0px;
    }

    &__title {
      display: flex;
      justify-content: center;
      color: $BLACK_COLOR_1;
      font-size: 1.2rem;
    }
    &__rating {
      display: flex;
      align-items: center;
      justify-content: center;
      &__title {
        color: $BLACK_COLOR_1;
        font: normal normal normal 18px/29px Yu Gothic;
      }
      &__starIcon {
        margin-top: 0.1rem;
        color: $GLODEN_COLOR;
        margin-left: 0.1rem;
      }
    }
  }
  &__boxsecondContainer {
    height: 40%;
    background: $WHITE_COLOR 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $BLACK_COLOR;
    width: 100%;
    border-radius: 2rem;
    img {
      width: 100%;
      padding: 0.2rem 0.2rem;
      height: 30vh;
      object-fit: cover;
      border-radius: 35px 35px 0px 0px;
    }
  }
  &__boxthirdContainer {
    height: 40%;
    background: $WHITE_COLOR 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $BLACK_COLOR;
    width: 100%;
    border-radius: 2rem;
    img {
      width: 100%;
      padding: 0.2rem 0.2rem;
      height: 30vh;
      object-fit: cover;
      border-radius: 35pnx 35px 0px 0px;
    }
  }
  &__buttonsBox__register {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 106%;
    &__button {
      background-color: $BLUE_COLOR !important;
      width: 250px !important;
      columns: $WHITE_COLOR !important;
      padding: 0.6rem 2rem !important;
      border-radius: 0.5rem !important;
      margin-top: 3rem !important;
    }
  }
}
.review {
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 1s;
  margin-top: 1rem;
  @include fadeIn;
  &__boxContainer {
    height: 100%;
    background: $WHITE_COLOR 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $BLACK_COLOR;
    border-radius: 0.8rem;
    width: 96%;
    margin-left: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    @media only screen and (max-width: 750px) {
      width: 95%;
      margin-left: 0px;
    }
    &__rating {
      display: flex;
      justify-content: space-between;
      &__profile {
        display: flex;
        &__image {
          img {
            width: 80px;
            height: 80px;
            clip-path: circle(50%);
            object-fit: cover;
          }
        }
        &__information {
          margin-left: 1.5rem;
          align-items: center;
          &__name {
            font: normal normal bold 23px/23px Yu Gothic;
            margin-top: 1rem;
            align-items: center;
            color: $BLACK_COLOR_1;
          }
          &__point {
            display: flex;
            &__number {
              font: normal normal normal 19px/37px Roboto;
              color: $BLACK_COLOR_1;
              font-weight: 600;
            }
            &__iconbox {
              font: normal normal normal 18px/37px Roboto;
              margin-top: 0.3rem;
              color: $GLODEN_COLOR;
            }
          }
        }
      }
      &__days {
        font: normal normal normal 18px/63px Roboto;
        color: $BLACK_COLOR_1;
        opacity: 0.5;
      }
    }
    &__paragraph {
      text-align: justify;
      margin-bottom: 1rem;
      color: $BLACK_COLOR_1;
      opacity: 0.5;
      margin-top: 0.5rem;
    }
    &__buttonbox {
      display: flex;
      align-items: center;
      justify-content: center;
      position: sticky;
      bottom: 0;
      right: 0;
      left: 0;
      background: white;
      &__button {
        position: relative;
        bottom: 10px;
        background-color: $BLUE_COLOR !important;
        width: 250px !important;
        columns: $WHITE_COLOR !important;
        padding: 0.6rem 2rem !important;
        border-radius: 0.5rem !important;
        margin-top: 3rem !important;
      }
    }
  }
}
.service__tab {
  transition: all 1s;
  width: 31%;
}
.review__tab {
  transition: all 1s;
  width: 31%;
}
.about__tab {
  width: 34%;
  transition: all 1s;
}
// .css-heg063-MuiTabs-flexContainer {
//   display: flex !important;
//   justify-content: space-between !important;
// }
.review::-webkit-scrollbar {
  width: 5px;
}
.review::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}
.review::-webkit-scrollbar-thumb {
  background: #fafafa;
  border-radius: 10px;
}
.review::-webkit-scrollbar-thumb:hover {
  background: #fafafa;
}

.service::-webkit-scrollbar {
  width: 5px;
}
.service::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}
.service::-webkit-scrollbar-thumb {
  background: #fafafa;
  border-radius: 10px;
}
.service::-webkit-scrollbar-thumb:hover {
  background: #fafafa;
}

.tab__mainconatiner {
  width: 100% !important;
  padding: 1.5rem !important;
  &__box {
    border: 1px solid #e5f3dc !important;
    background-color: #e5f3dc !important;
    border-radius: 50px !important;
    margin-top: 0.5rem !important;
  }
}
