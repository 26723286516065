.authentication {
  height: 100%;
  min-height: 100vh;

  &__container {
    padding: 0rem 5rem;
    padding-top: 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1250px) {
      padding: 0rem 3rem;
      padding: 3rem;
    }

    @media (max-width: 900px) {
      padding: 0rem 1rem;
      padding-top: 3rem;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      padding-top: 3rem;
    }

    &__left {
      width: 55%;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 2rem;
      }

      &__logo {
        display: flex;
        justify-content: center;
        width: 100%;

        &__logoImage {
          width: 300px;
          object-fit: cover;
          cursor: pointer;

          @media (max-width: 768px) {
            width: 200px;
          }
        }
      }

      &__box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__image {
          width: 540px;
          object-fit: cover;
          margin-top: 7.2rem;
          transition: width 0.5s;

          @media (max-width: 1250px) {
            margin-top: 7.8rem;
          }

          @media (max-width: 1100px) {
            width: 450px;
            margin-top: 12.2rem;
          }

          @media (max-width: 900px) {
            width: 400px;
            margin-top: 14.2rem;
          }

          @media (max-width: 768px) {
            width: 320px;
            margin-top: 5rem;
            margin-left: 1.5rem;
          }
        }

        &__image1 {
          width: 540px;
          object-fit: cover;
          margin-top: 14.5rem;
          transition: width 0.5s;

          @media (max-width: 1250px) {
            margin-top: 14.8rem;
          }

          @media (max-width: 1100px) {
            width: 450px;
            margin-top: 19rem;
          }

          @media (max-width: 1025px) {
            width: 400px;
            margin-top: 22.7rem;
          }

          @media (max-width: 900px) {
            width: 400px;
            margin-top: 20.9rem;
          }

          @media (max-width: 768px) {
            width: 320px;
            margin-top: 5rem;
            margin-left: 1.5rem;
          }
        }
      }

      &__stripe {
        background-color: $LIGHT_GRAY_COLOR;
        width: 100%;
        height: 100px;
        margin-top: -100px;
      }
    }

    &__right {
      width: 45%;
      box-shadow: 1px 1px 15px lightgray;
      border-radius: 1rem;
      background-color: $WHITE_COLOR;
      padding: 1.5rem 0 2rem 0;

      @media (max-width: 768px) {
        width: 100%;
        box-shadow: unset;
      }

      &__providerSignIn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2rem 0 0 0;

        &__box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 70%;

          @media (max-width: 900px) {
            width: 90%;
          }

          &__remember {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &__me {
              display: flex;
              align-items: center;
              white-space: nowrap;

              p {
                color: $BLUE_COLOR;
                font-size: 0.9rem;
                margin-left: 0.5rem;
              }

              &__icon {
                color: $GREEN_COLOR;
                font-size: 1.8rem !important;
                background-color: $BLUE_COLOR;
                border-radius: 50%;
              }
            }

            &__forgotPassword {
              color: $GREEN_COLOR;
              font-size: 0.9rem;
              text-align: right;
              white-space: nowrap;
            }
          }

          &__signInButton {
            background-color: $BLUE_COLOR !important;
            text-transform: capitalize !important;
            width: 90% !important;
            margin: 2rem 0 0 0 !important;
            border-radius: 0.5rem !important;
            font-size: 1.2rem !important;
            padding-top: 0.7rem !important;
            padding-bottom: 0.7rem !important;
          }
        }

        &__continue {
          margin-top: 2rem;
          font-size: 0.9rem;
          font-weight: 500;
        }

        &__signInLinks {
          display: flex;
          align-items: center;
          margin: 1rem;

          &__image {
            img {
              width: 80px;
              height: 80px;
              object-fit: cover;
              margin: 1rem 1rem;
              border-radius: 1rem;
              box-shadow: 1px 1px 15px lightgray;
            }
          }
        }

        &__signUpButton {
          border-top: 1px solid lightgray;
          padding-top: 1rem;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-top: 0.5rem;
          @media (max-width: 1190px) {
            justify-content: center;
            gap: 1rem;
          }

          p {
            color: $GRAY_COLOR;
            font-size: 1rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            b {
              color: black;
              font-weight: 800 !important;
            }
          }
        }
      }
    }
  }

  &__stripe {
    background-color: $LIGHT_GRAY_COLOR;
    width: 100%;
    height: 180px;
    margin-top: -220px;
    z-index: -999999;

    @media (max-width: 1250px) {
      margin-top: -260px;
    }

    @media (max-width: 1100px) {
      height: 150px;
      margin-top: -230px;
    }

    @media (max-width: 900px) {
      height: 150px;
      margin-top: -190px;
    }
  }

  &__stripe1 {
    background-color: $LIGHT_GRAY_COLOR;
    width: 100%;
    height: 180px;
    margin-top: -220px;
    z-index: -999999;

    @media (max-width: 1250px) {
      margin-top: -260px;
    }

    @media (max-width: 1100px) {
      height: 150px;
      margin-top: -230px;
    }

    @media (max-width: 925px) {
      height: 140px;
      margin-top: -220px;
    }

    @media (max-width: 900px) {
      height: 140px;
      margin-top: -180px;
    }
  }
}

.providerSignUp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0 0 0;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;

    @media (max-width: 900px) {
      width: 90%;
    }

    &__nameContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &__1st {
        width: 49%;
      }

      &__last {
        width: 49%;
      }
    }

    &__terms {
      display: flex;
      align-items: center;

      &__icon {
        background-color: $BLUE_COLOR !important;
        color: $GREEN_COLOR !important;
        border-radius: 50%;
        margin-right: 1rem;
      }

      &__text {
        font-size: 0.9rem;
        padding-bottom: 0;

        b {
          font-size: 0.9rem;
        }
      }
    }

    &__condition {
      font-weight: 600;
      cursor: pointer;
      text-decoration: underline;
    }

    &__signUpButton {
      margin-top: 1.5rem !important;
      background-color: $BLUE_COLOR !important;
      text-transform: capitalize !important;
      width: 90% !important;
      border-radius: 0.5rem !important;
      font-size: 1.2rem !important;
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
  }

  &__signIn {
    border-top: 1px solid lightgray;
    padding-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;

    p {
      color: $GRAY_COLOR;
      font-size: 1rem;

      b {
        color: black;
        font-weight: 800 !important;
      }
    }
  }
}
.passwordErrorMessage {
  font-size: 13px !important;
  color: red;
  margin-bottom: 1rem;
  width: 100%;
}
.language__selector {
  width: 190px !important;
  margin-right: 3rem !important;
  position: relative !important;
  left: 1rem !important;
  @media (max-width: 1190px) {
    width: 100% !important;
  }
}
