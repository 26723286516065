html {
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;

  &:not(i) {
    font-family: "Poppins", sans-serif !important;
  }
}

body {
  box-sizing: border-box;
  // background-color: $BG_COLOR;
  // color: $LIGHT_BLACK;

  // @include respond(tab-port) {
  //   padding: 0;
  // }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: unset !important;
}