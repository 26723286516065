.drawer {
  display: none;
  &__sidebar {
    &__section {
      position: fixed;
      align-items: center;
      text-align: center;
      padding: 0.5rem 0.5rem;
      background-color: $BLUE_COLOR;
      cursor: pointer;
      display: none;
      :hover {
        background-color: #476485;
        border-radius: 6px;
      }

      @media (max-width: 768px) {
        display: block;
      }

      @media (max-width: 768px) {
        position: unset;
      }

      &__myprofile {
        display: flex;
        align-items: center;
        padding: 0.9rem 1.5rem;
        color: $WHITE_COLOR;
        letter-spacing: 0px;
        &__icon {
          position: relative;
          top: 0.2rem;

          &__dollar {
            display: flex;
            justify-content: centers;
            background-color: $BLUE_COLOR;
            border-radius: 0.5rem;
            align-items: center;
            position: relative;
            left: 0.6rem;
            padding: 0.5rem;
          }
        }

        &__name {
          margin-left: 1.7rem;
          font-size: 0.8rem;
          white-space: nowrap;
        }
      }
    }
  }
}

.provider__sidebar__section::-webkit-scrollbar {
  width: 5px;
}

.provider__sidebar__section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $WHITE_COLOR;
  border-radius: 10px;
}

.provider__sidebar__section::-webkit-scrollbar-thumb {
  background: $BLUE_COLOR;
  border-radius: 10px;
  height: 10px;
}

.provider__sidebar__section::-webkit-scrollbar-thumb:hover {
  height: 10px;
}

.providerSidebar {
  @media (max-width: 768px) {
    display: none;
  }
}
