.review {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  &__container {
    background-color: $WHITE_COLOR;
    box-shadow: $BOX_SHADOW;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0.5rem;

    @media (max-width: 600px) {
      box-shadow: unset;
      padding: 2rem;
      margin-top: 1rem;
    }

    &__title {
      font-weight: 600;
      font-size: 1.5rem;
      color: $DARK_BLACK_COLOR;
      text-transform: capitalize;
    }

    &__profileImage {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 0.5rem;
      margin: 0.8rem 0;
    }

    &__name {
      font-weight: 900;
      text-transform: capitalize;
    }

    &__services {
      display: flex;
      color: $WHITE_COLOR;
      font-size: 0.7rem;
      width: 100%;
      margin-top: 0.5rem;
      gap: 1rem;
      text-wrap: nowrap;
      overflow-x: scroll;
      justify-content: center;
      &__offers {
        display: flex;
        justify-content: space-around;
        margin: 0.8rem 0;

        span {
          background-color: $BLUE_COLOR;
          font-size: 0.8rem;
          color: $LIGHT_GRAY_COLOR;
          border-radius: 1rem;
          margin-right: 0.3rem;
          padding: 0.2rem 1rem;
        }
      }
    }

    &__ratings {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0.5rem 0;

      p {
        font-weight: 600;
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
      }
    }

    &__review {
      width: 100%;
      border-radius: 0.5rem;
      border: 2px solid $GREEN_COLOR;
      display: flex;
      flex-direction: column;
      padding: 0.2rem 0.5rem 0.5rem 0.5rem;
      margin-top: 1rem;

      p {
        font-size: 0.8rem;
        color: $DARK_GRAY_COLOR;
        font-weight: 500;
      }

      textarea {
        outline: unset;
        border: unset;
      }
    }

    &__postButton {
      text-transform: capitalize !important;
      background-color: $BLUE_COLOR !important;
      border-radius: 0.5rem !important;
      margin-top: 2rem !important;
      font-size: 0.8rem !important;
      width: 80% !important;
      padding-top: 0.3Comprem !important;
      padding-bottom: 0.3Comprem !important;
    }
  }
}
