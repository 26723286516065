.clientNavbar {
  background-color: $WHITE_COLOR !important;
  box-shadow: unset !important;
  color: $BLUE_COLOR !important;
  padding: 0.5rem 1.2rem;
  position: fixed !important;
  top: 0 !important;
  z-index: 1 !important;

  @media (max-width: 768px) {
    padding: 0.9rem 0rem;
  }
&__searchField{
  width: 430px;
  @media (max-width: 1050px) {
    display: none !important;
  }
}
  &__navTabs {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-left: 2rem !important;
    @media (max-width: 768px) {
      display: none !important;
    }
    &__dashboardtitle {
      &:hover {
        text-decoration: underline !important;
        transition: all 1s;
      }
    }
    &__favouritetitle {
      &:hover {
        text-decoration: underline !important;
        transition: all 1s;
      }
    }
  }

  &__buttonsBox {
    flex-grow: 0;
    display: flex;
    align-items: center;

    &__signIn {
      text-transform: uppercase;
      color: $BLUE_COLOR !important;
      margin-right: 1.5rem !important;
      background-color: $WHITE_COLOR !important;
      box-shadow: unset !important;
      padding: 0.4rem 2rem !important;
      border-radius: 0.5rem !important;
      font-weight: 600 !important;
    }

    &__register {
      columns: $WHITE_COLOR !important;
      background-color: $BLUE_COLOR !important;
      padding: 0.4rem 2rem !important;
      border-radius: 0.5rem !important;
    }

    &__iconButton {
      margin-right: 1rem !important;

      @media (max-width: 768px) {
        margin-right: 0.5rem !important;
      }

      &__searchIcon {
        color: $GRAY_COLOR !important;
      }

      &__shoppingIcon {
        color: $GRAY_COLOR !important;
      }

      &__translationIcon {
        color: $BLUE_COLOR !important;
      }
    }

    &__separator {
      color: lightgray;
      margin-right: 1rem;
      font-size: 1.5rem;
    }
  }

  &__logo {
    width: 160px;
    object-fit: cover;
    cursor: pointer;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__drawer {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
}
