.rate__tablecontainer {
  margin-top: 5rem;
  margin-left: 250px;
  @include fadeIn;
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-top: 5rem;
  }
  &__tableheader {
    margin-top: 4rem;
    padding: 1.5rem 1rem;
    &__container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &__title {
        font-size: 22px;
        color: #193d66;
        font-weight: 600;
      }
      &__buttonbox {
        align-items: center !important;
        &__button {
          background-color: $BLUE_COLOR !important;
          columns: $WHITE_COLOR !important;
          padding: 0.6rem 2rem !important;
          border-radius: 0.5rem !important;
          color: white !important;
          align-items: center !important;
          @media (max-width: 550px) {
            padding: 0.3rem 2rem !important;
          }
        }
      }
    }
  }
  &__table {
    width: 100%;
    height: 92vh;
    &__section {
      display: flex;
      flex-direction: column;
      border-top: 1px solid lightgray;
      padding-top: 10px;
      // height: 90vh !important;
      overflow-y: auto;
      h4 {
        margin-bottom: 10px;
      }
      @media (max-width: 768px) {
        overflow: auto;
      }
      &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #f8f8f8;
        align-items: center;
        padding: 0.5rem 0.5rem;
        border-radius: 6px;
        text-wrap: nowrap;
        @media (max-width: 900px) {
          width: 130%;
        }
        @media (max-width: 768px) {
          width: 250%;
        }
        &__dollartextfield {
          &__greenInput {
            display: flex;
            flex-direction: column;
            border: 2px solid $GREEN_COLOR;
            background-color: #ffffff;
            border-radius: 0.7rem;
            padding: 0rem 1rem;
            width: 90%;
            label {
              color: #444444;
              font-size: 10px;
              font-weight: 600;
            }
            &__inputBox {
              display: flex;

              input {
                position: relative;
                bottom: 0.5rem;
                margin-top: 0.8rem;
                outline: unset;
                border: unset;
                color: #444444;
                font-weight: lighter;
                font-size: 12px;
                padding-right: 40px;
                background-color: unset;
                border-radius: 0.5rem;
                width: 100%;
                padding-left: 0rem;
              }
            }
          }
        }
        &__selectbox {
          align-items: center;
          position: relative;
          top: 0.5rem;
          .greenSelect {
            height: 50px;
            display: flex;
            flex-direction: column;
            border: 2px solid $GREEN_COLOR;
            background-color: #ffffff;
            border-radius: 0.7rem;
            padding: 0rem 0.5rem;
            width: 90%;
            label {
              color: #444444;
              font-size: 10px !important;
              font-weight: 600;
              align-items: center;
            }

            select {
              position: relative;
              bottom: 0.3rem;
              outline: unset;
              border: unset;
              font-weight: lighter;
              color: #444444;
              margin-top: 0.5rem;
              background-color: transparent;
              align-items: center;
              option {
                font-weight: 600;
                color: #444444;
              }
            }
          }
        }
        &__Regularbox {
          .greenSelect {
            height: 50px;
            position: relative;
            top: 0.5rem;
            display: flex;
            flex-direction: column;
            border: 2px solid $GREEN_COLOR;
            background-color: #ffffff;
            border-radius: 0.7rem;
            padding: 0rem 0.5rem;
            width: 90%;
            label {
              color: #222222;
              font-size: 10px;
              font-weight: 600;
              align-items: center;
            }

            select {
              position: relative;
              bottom: 0.3rem;
              outline: unset;
              border: unset;
              font-weight: lighter;
              color: #444444;
              margin-top: 0.5rem;
              background-color: transparent;
              align-items: center;
              option {
                font-weight: 600;
                color: #444444;
              }
            }
          }
        }
        &__buttonbox {
          display: flex;
          align-items: center;
        }
      }
      &__buttonbox {
        display: flex;
        align-items: center !important;
        align-items: center;
        justify-content: center;
        display: none;
        @media (max-width: 768px) {
          margin-top: 1rem;
          display: block;
          display: flex;
          align-items: center !important;
          align-items: center;
          justify-content: center;
        }
        &__button {
          width: 250px;
          background-color: $BLUE_COLOR !important;
          columns: $WHITE_COLOR !important;
          padding: 0.6rem 2rem !important;
          border-radius: 0.5rem !important;
          color: white !important;
          align-items: center !important;
        }
      }
    }
  }
}
.rate__sidebar__section > :nth-child(6) {
  background-color: #476499;
  border-radius: 8px;
  opacity: 1;
  padding: 0.3rem 0.4rem;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: $DARK_GREEN_COLOR !important;
  border-radius: 12px !important;
}
.MuiFormLabel-root {
  color: black;
}
.rate__tablecontainer__table__section::-webkit-scrollbar {
  width: 5px;
}
@media (max-width: 768px) {
  .rate__tablecontainer__table__section::-webkit-scrollbar {
    width: 0 !important;
  }
}
.rate__tablecontainer__table__section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $WHITE_COLOR;
  border-radius: 10px;
}
.rate__tablecontainer__table__section::-webkit-scrollbar-thumb {
  background: #717171;
  border-radius: 10px;
}
.rate__tablecontainer__table__section::-webkit-scrollbar-thumb:hover {
  background: #717171;
  height: 10px;
}
