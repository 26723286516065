.footer {
  width: 100%;
  &__top {
    background-color: $DARK_BLACK_COLOR;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    padding: 2rem 4rem 1rem 4rem;
    color: $FONT_GRAY_COLOR;
    cursor: pointer;
    @media (max-width: 800px) {
      padding: 2rem 2rem 1rem 2rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    &__phone {
      margin-bottom: 1rem;
      margin-right: 2rem;

      p {
        font-size: 1.2rem;
        &:hover {
          text-decoration: underline !important;
          transition: all 1s;
        }
      }
    }

    &__social {
      margin-bottom: 1rem;

      p {
        font-size: 1.2rem;
      }

      &__icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;

        &__icon {
          color: $LIGHT_GRAY_COLOR !important;
          font-size: 1.2rem !important;
        }
      }
    }

    &__subscription {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      margin-left: 2rem;

      @media (max-width: 600px) {
        margin-left: 0;
      }

      input {
        outline: unset;
        border: unset;
        padding: 0.5rem;
        width: 200px;
      }

      input::placeholder {
        text-transform: uppercase;
        font-size: 0.7rem;
      }

      Button {
        color: $LIGHT_GRAY_COLOR;
        border-radius: unset;
        background-color: $LIGHT_BLACK_COLOR !important;
        font-size: 0.7rem;
        padding: 0.55rem 0.8rem;
      }
    }
  }

  &__body {
    background-color: $LIGHT_BLACK_COLOR;
    color: $LIGHT_GRAY_COLOR;
    padding: 2rem 4rem 1rem 4rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      padding: 2rem 2rem 1rem 2rem;
      display: flex;
      flex-direction: column;
    }

    &__left {
      display: flex;
      flex-direction: column;
      width: 60%;

      @media (max-width: 768px) {
        width: 100%;
      }

      p {
        font-size: 1.2rem;
        text-transform: uppercase;
      }

      &__citiesBox {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        &__cities {
          display: flex;
          flex-direction: column;

          span {
            font-size: 0.8rem;
            color: $FONT_GRAY_COLOR;
            margin-top: 1rem;
            margin-right: 2rem;
          }
        }
      }
    }

    &__right {
      width: 25%;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 2rem;
      }

      &__box {
        display: flex;
        flex-direction: column;

        @media (max-width: 768px) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        &__aboutUs {
          p {
            font-size: 1.2rem;
            text-transform: capitalize;
          }

          &__options {
            display: flex;
            flex-direction: column;

            span {
              font-size: 0.7rem;
              color: $FONT_GRAY_COLOR;
              margin-top: 0.5rem;
            }
          }
        }

        &__aboutMassage {
          margin-top: 5rem;

          @media (max-width: 768px) {
            margin-top: 0;
          }

          p {
            font-size: 1.2rem;
            text-transform: capitalize;
          }

          &__options {
            display: flex;
            flex-direction: column;

            span {
              font-size: 0.7rem;
              color: $FONT_GRAY_COLOR;
              margin-top: 0.5rem;
            }
          }
        }
      }

      &__policy {
        margin-top: 5rem;

        @media (max-width: 768px) {
          margin-top: 2rem;
        }

        a {
          color: $LIGHT_GRAY_COLOR;
          text-decoration: unset;
          font-size: 0.7rem;
          font-weight: 800;
          text-transform: capitalize;
        }
      }
    }
  }
}
