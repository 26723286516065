.successMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 1px 1px 15px lightgray;
  padding: 3rem 2rem;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;

  &__iconBox {
    border-top: 2px solid lightgray;
    padding-top: 1rem;
    width: 70%;
  }

  h3 {
    margin: 1rem 0;
    font-weight: 900 !important;
    text-transform: capitalize;
  }

  img {
    object-fit: cover;
    width: 60px;
  }

  p {
    margin: 1rem 0 1rem 0;
    font-size: .9rem;
    text-align: center;
  }


  &__verifyButton {
    width: 80%;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 1px 1px 15px lightgray;
  padding: 3rem 2rem;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;

  &__iconBox {
    border-top: 2px solid lightgray;
    padding-top: 1.5rem;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__phoneIcon {
      color: $GREEN_COLOR;
      background-color: $LIGHT_GREEN_COLOR;
      font-size: 3rem !important;
      border-radius: 50%;
      padding: .5rem;
    }
  }

  h3 {
    margin: 1rem 0;
    font-weight: 900 !important;
  }

  p {
    margin: 1rem 0 1rem 0;
    font-size: .9rem;
    text-align: center;
  }

  input {
    margin: 1.5rem 0;
    outline: unset;
    border: unset;
    border-bottom: 2px solid $GREEN_COLOR;
    width: 100%;
    color: black;
    font-size: 3rem;
    font-weight: 900;
    text-align: center;
  }

  &__verifyButton {
    width: 80%;
  }
}