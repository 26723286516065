.clientreview__container {
  margin-top: 5rem;
  margin-left: 250px;
  display: flex;
  flex-wrap: wrap;
  @include fadeIn;

  @media (max-width: 768px) {
    margin-top: 6rem;
    margin-left: 0px;
  }
  &__client {
    width: 65%;
    @media (max-width: 1310px) {
      width: 60%;
    }
    @media (max-width: 1315px) {
      width: 100%;
    }
    &__title {
      margin-left: 1.5rem;
      font: normal normal medium 30px/28px Poppins;
      letter-spacing: 0.6px;
      color: #193d66;
    }
    &__review {
      margin-top: 0.5rem;
      overflow-y: auto;
      width: 100%;
      overflow-x: hidden;
      height: 100vh;
      padding: 1rem;
      &__boxContainer {
        background: $WHITE_COLOR 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $BLACK_COLOR;
        border-radius: 0.8rem;
        width: 98%;
        margin-left: 0.5rem;
        &__rating {
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          &__profile {
            display: flex;
            &__image {
              img {
                width: 60px;
                height: 60px;
                clip-path: circle(50%);
                object-fit: cover;
                @media (max-width: 466px) {
                  width: 45px;
                  height: 45px;
                }
                @media (max-width: 375px) {
                  width: 40px;
                  height: 40px;
                }
              }
            }
            &__information {
              margin-left: 1rem;
              align-items: center;
              @media (max-width: 376px) {
                margin-left: 0.5rem;
              }
              &__name {
                font: normal normal bold 23px/23px Yu Gothic;
                margin-top: 0rem;
                align-items: center;
                color: $BLACK_COLOR_1;
                @media (max-width: 466px) {
                  font: normal normal bold 17px/20px Yu Gothic;
                }
                @media (max-width: 375px) {
                  font: normal normal bold 14px/15px Yu Gothic;
                }
              }
              &__point {
                display: flex;
                &__number {
                  font: normal normal normal 19px/37px Roboto;
                  color: $BLACK_COLOR_1;
                  font-weight: 600;
                  @media (max-width: 466px) {
                    font: normal normal normal 15px/25px Roboto;
                  }
                  @media (max-width: 375px) {
                    font: normal normal bold 13px/25px Yu Gothic;
                  }
                }
                &__iconbox {
                  font: normal normal normal 18px/37px Roboto;
                  margin-top: 0.3rem;
                  color: $GLODEN_COLOR;
                  @media (max-width: 466px) {
                    font: normal normal normal 0px/0px Roboto;
                    position: relative;
                    bottom: 0.29rem;
                    font-size: 1px;
                  }
                  @media (max-width: 375px) {
                    font-size: 0px;
                  }
                }
              }
            }
          }
          &__container {
            display: flex;
            &__days {
              font: normal normal normal 18px/63px Roboto;
              color: $BLACK_COLOR_1;
              opacity: 0.5;
              margin-right: 1rem;
              @media (max-width: 466px) {
                position: relative;
                left: 0.8rem;
                font-size: 12px;
                position: relative;
                bottom: 1.25rem;
              }
              @media (max-width: 375px) {
                font: normal normal bold 14px/15px Yu Gothic;
                position: relative;
                left: 0.8rem;
                font-size: 12px;
                position: relative;
                bottom: 0rem;
              }
            }
            &__icon {
              position: relative;
              top: 1.3rem;
              color: #ff0000;
              cursor: pointer;
              @media (max-width: 768px) {
                display: none;
              }
            }
          }
        }
        &__paragraph {
          word-wrap: none;
          word-break: none;
          padding: 0rem 1rem;
          text-align: justify;
          margin-bottom: 1rem;
          color: $BLACK_COLOR_1;
          opacity: 0.5;
          position: relative;
          bottom: 0.7rem;
        }
      }
    }
  }
  &__totalrating {
    width: 33.5%;
    background-color: #f7f5f5;
    @media (max-width: 1310px) {
      width: 38%;
      height: 100%;
    }
    @media (max-width: 1310px) {
      width: 100%;
      background-color: transparent;
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    margin-left: 1rem;
    &__section {
      padding: 2rem 1rem;
      &__title {
        font: normal normal medium 30px/28px Poppins;
        letter-spacing: 0.6px;
        color: #193d66;
      }
      &__box {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 13px;
        padding: 1rem;
        margin-top: 1rem;
        width: 100%;
        &__container {
          display: flex;
          justify-content: space-between;
          &__title {
            font: normal normal medium 500px/28px Poppins;
            letter-spacing: 0.6px;
            color: #363636;
            font-size: 20px;
          }
          &__trending {
            width: 100px;
            display: flex;
            justify-content: space-evenly;
            margin-top: 0.6rem;
            background: #e2f1d4 0% 0% no-repeat padding-box;
            border-radius: 17px;
            align-items: center;
            height: 25px;
            &__percentage {
              font: normal normal normal 16px/0px Poppins;
              letter-spacing: 0.36px;
              color: #6eb829;
            }
            &__icon {
              color: #6eb829;
              position: relative;
              top: 0.2rem;
            }
          }
        }
        &__paragraph {
          font: normal normal normal 15px/25px Poppins;
          letter-spacing: 0.36px;
          color: #363636;
          opacity: 0.5;
          position: relative;
          top: 0.2rem;
        }
      }
      &__averageratingtitle {
        font: normal normal medium 30px/28px Poppins;
        letter-spacing: 0.6px;
        color: #193d66;
        margin-top: 1rem;
      }
      &__averageratingcontainer {
        display: flex;
        &__title {
          width: 27%;
          font: normal normal medium 500px/28px Poppins;
          letter-spacing: 0.6px;
          color: #363636;
          font-size: 20px;
        }
        &__icon {
          color: #d7bd73;
          position: relative;
        }
      }
      &__ratingstat {
        display: flex;
        align-items: center;
        &__ratingpoint {
          align-items: center;
          display: flex;
          position: relative;
          top: 0.3rem;
          &__icon {
            margin-top: 0.28rem;
          }
          &__number {
            margin-left: 0.8rem;
            font: normal normal medium 24px/28px Poppins;
            letter-spacing: 0.48px;
            color: #363636;
            opacity: 1;
            font-size: 17px;
          }
        }
        &__border {
          width: 90%;
          border: 4px solid #39b49d;
          border-radius: 100px;
          height: 1px;
          margin-top: 0.5rem;
          margin-left: 0.5rem;
          &__one {
            width: 65%;
            border: 4px solid #e84de3;
            border-radius: 100px;
            height: 1px;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
          }
          &__two {
            width: 55%;
            border: 4px solid #efc153;
            border-radius: 100px;
            height: 1px;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
          }
          &__three {
            width: 45%;
            border: 4px solid #42bfe8;
            border-radius: 100px;
            height: 1px;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
          }
          &__four {
            width: 35%;
            border: 4px solid #f72253;
            border-radius: 100px;
            height: 1px;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
          }
        }
        &__number {
          width: 10%;
          margin-left: 0.5rem;
          margin-top: 0.5rem;
          span {
            letter-spacing: 0.48px;
            color: #363636;
            opacity: 1;
            font-size: 17px;
          }
        }
      }
    }
  }
}

.clientreview__container__client__review::-webkit-scrollbar {
  width: 5px;
}
.clientreview__container__client__review::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}
.clientreview__container__client__review::-webkit-scrollbar-thumb {
  background: #537198;
  border-radius: 10px;
}
.clientreview__container__client__review::-webkit-scrollbar-thumb:hover {
  background: #537198;
}
