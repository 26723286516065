.chat {
  display: flex;
}
.chatSidebar {
  width: 35%;
}
.messages {
  width: 65%;
}
.chat__sidebar {
  padding: 2rem;
  width: 100%;
  height: 100%;
  &__searchContainer {
    display: flex;
    width: 93%;
    padding: 0.5rem 1rem;
    background: $WHITE_COLOR 0% 0% no-epeat padding-box;
    border: 2px solid $DARK_GREEN_COLOR;
    border-radius: 10px;
    outline: none;
    color: $DARK_LIGHTGRAY_COLOR;
    align-items: center;
    font-size: 17px;
    &__textfield {
      width: 100%;
      input {
        margin-left: 0.3rem;
        width: 100%;
        outline: unset;
        border: unset;
        margin-right: 0.5rem;
        color: $DARK_LIGHTGRAY_COLOR;
      }
      &__searchIcon {
        color: $DARK_LIGHTGRAY_COLOR;
        margin-top: 0.2rem;
      }
    }
  }
  &__title {
    text-align: center;
    margin-top: 0.5rem;
    font: normal normal normal 30px/55px Poppins;
    color: $LIGHT_BLUE;
    opacity: 1;
  }
  &__responsivetitlebar {
    display: none;
  }
  &__border {
    width: 93%;
    border-bottom: 1px solid $WIGHT_COLOR_1;
  }
  &__scrollcontainer {
    height: 90vh;
    overflow: auto;
    width: 100%;
  }
  &__messageContainer {
    cursor: pointer;
    display: flex;
    margin-top: 1.5rem;
    margin-left: 0.3rem;
    justify-content: space-between;
    width: 93%;
    &__image {
      img {
        width: 50px;
      }
    }
    &__subContainer {
      margin-left: 1rem;
      width: 80%;
      &__name {
        font: normal normal bold 12px/20px Yu Gothic;

        color: $BLACK_COLOR_1;
      }
      &__message {
        width: 65%;
        &__paragraph {
          color: $BLACK_COLOR_1;
          font-size: 13px;
        }
        &__days {
          width: 20%;
          margin-top: 2.5rem;
          font: normal normal normal 11px/23px Roboto;

          color: $BLACK_COLOR_1;
          opacity: 0.44;
        }
      }
    }
  }
}
.message {
  padding: 2rem;
  &__profileContainer {
    display: flex;
    align-items: center;
    &__icon {
      color: $BLUE_COLOR;
      cursor: pointer;
    }
    &__image {
      margin-left: 1.5rem;

      img {
        width: 50px;
      }
    }
    &__namecontainer {
      margin-left: 0.8rem;
      &__name {
        font: normal normal bold 14px/20px Yu Gothic;

        color: $BLACK_COLOR_1;
      }
      &__lastseen {
        text-align: left;
        font: normal normal normal 12px/20px Yu Gothic;

        color: $BLACK_COLOR_1;
        opacity: 0.3;
      }
    }
  }
  &__receiver {
    &__paragraph {
      padding: 0.3rem;
      display: inline-block;
      background: $BLUE_COLOR 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
      width: auto;
      align-items: center;
      p {
        font: normal normal normal 12px/29px Roboto;

        color: $WHITE_COLOR;
      }
    }
    &__timeStamp {
      font: normal normal normal 10px/30px Roboto;

      color: $BLACK_COLOR_1;
      opacity: 0.3;
    }
  }
  &__sender {
    display: flex;
    align-items: end;
    flex-direction: column;
    &__paragraph {
      padding: 0.3rem;
      display: inline-block;
      background: $BLACK_COLOR_1 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 0.6;
      width: auto;
      align-items: center;

      p {
        font: normal normal normal 12px/29px Roboto;

        color: $WHITE_COLOR;
        text-align: end;
      }
    }
    &__timeStamp {
      font: normal normal normal 10px/30px Roboto;

      color: $BLACK_COLOR_1;
      opacity: 0.3;
    }
  }
  &__sendMessageContainer {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: -webkit-fill-available;
    background: $WHITE_COLOR;
    &__maincontainer {
      position: relative;
      bottom: 0.6rem;
      display: flex;
      width: 96%;
      border: 2px solid $DARK_GREEN_COLOR;
      outline: none;
      border-radius: 6px;
      padding: 0.6rem;
      justify-content: space-between;
      &__textfiled {
        width: 100%;
        input {
          margin-left: 0.3rem;
          width: 100%;
          outline: unset;
          border: unset;
        }
      }
      &__sendicon {
        color: $BLUE_COLOR;
        cursor: pointer;
      }
    }
  }
}
.chat__sidebar__scrollcontainer::-webkit-scrollbar {
  width: 5px;
}
.chat__sidebar__scrollcontainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $WHITE_COLOR;
  border-radius: 10px;
}
.chat__sidebar__scrollcontainer::-webkit-scrollbar-thumb {
  background: $DARK_LIGHTGRAY_COLOR;
  border-radius: 10px;
  height: 10px;
}
.chat__sidebar__scrollcontainer::-webkit-scrollbar-thumb:hover {
  background: $DARK_LIGHTGRAY_COLOR;
  height: 10px;
}
