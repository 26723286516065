.adsProfile {
  margin-top: 5rem;
  &__profilePhoto {
    height: 0;
    @media (max-width: 1000px) {
      height: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
    }
    img {
      height: 170px;
      width: 170px;
      border-radius: 50%;
      object-fit: cover;
      position: relative;
      bottom: 6rem;
      cursor: pointer;
      @media (max-width: 1000px) {
        height: 170px;
        bottom: 0rem;
      }
    }
  }
  &__profileData {
    display: flex;
    margin-left: 3rem;
    gap: 1rem;
   
    @media (max-width: 1000px) {
      margin-left: 0rem;
    }
    @media (max-width: 1000px) {
      flex-direction: column;
    }
    &__description {
      width: 100%;
      @media (max-width: 1000px) {
        padding: 3rem;
        margin-top: 1rem;
      }
      &__rating {
        display: flex;
        align-items: center;
        &__star {
          margin-top: 0.5rem !important;
        }
      }
    }
    &__title {
      font-weight: bold;
      margin-bottom: 0.5rem;
      margin-top: 1rem;
      color: $BLUE_COLOR;
      word-break: break-all;
      &:hover {
        font-style: italic;
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
    &__img {
      width: 40px;
    }
    &__location {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      margin-bottom: 0.5rem;
      color: $BLUE_COLOR;
      font-weight: bolder;
    }
    &__Information {
      background-color: #193d66;
      border-radius: 1rem;
      padding: 0.2rem 1rem;
      margin-bottom: 0.3rem;
      font-weight: 500;
      color: white;
      width: max-content;
      font-size: 11px;
      text-transform: capitalize;
    }
  }
  &__profileData:hover {
    cursor: pointer;
    filter: blur(0.5px);
  }
}

.serviceHour__tab {
  width: 23%;
  transition: all 1s;
}
.rate__tab {
  width: 23%;
  transition: all 1s;
}
.photo__tab {
  width: 23%;
  transition: all 1s;
}
.video__tab {
  width: 23%;
  transition: all 1s;
}

@media only screen and (max-width: 550px) {
  .serviceHour__tab {
    width: 50%;
    transition: all 1s;
  }
  .rate__tab {
    width: 50%;
    transition: all 1s;
  }
  .photo__tab {
    width: 50%;
    transition: all 1s;
  }
  .video__tab {
    width: 50%;
    transition: all 1s;
  }
}
.profileTab {
  padding: 1.5rem !important;
  &__container {
    border: 1px solid #e5f3dc !important;
    background-color: #e5f3dc !important;
    border-radius: 50px !important;
    margin-top: 0.5rem !important;
  }
}
.flexdata {
  display: flex;
  align-items: center;
  justify-content: center;
}
.font__style {
  font-style: italic;
  font-weight: bolder;
}

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #193d66;
  color: white;
  text-align: center;
  position: relative;
}

.not-found-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Add a dark overlay for better text readability */
  z-index: 0;
}

.not-found-content {
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.icon-container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container svg {
  font-size: 4rem;
  color: #ffc107; /* Change icon color to a contrasting shade */
}

.not-found-content h1 {
  font-size: 6rem;
  margin: 0;
  animation: bounce 2s infinite;
}

.not-found-content p {
  font-size: 1.5rem;
  margin: 1rem 0;
  animation: slideUp 1s ease-in-out;
}

.home-link {
  text-decoration: none;
}

.home-button {
  font-size: 1rem;
  padding: 0.8rem 1.5rem;
  color: #193d66; /* Change text color to match background */
  background-color: #ffc107; /* Button background color */
  border: none;
  border-radius: 5px;
  transition:
    background 0.3s,
    transform 0.3s;
  animation: pulse 1s infinite;
}

.home-button:hover {
  background-color: #e0a806; /* Darken button background on hover */
  transform: scale(1.1);
}

/* Animations */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.noVideo__title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  color: #193d66;
}
.create_title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #193d66;
  height: 75%;
  @media (max-width: 768px) {
    display: none;
  }
}
