@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin transition($properties...) {
  transition: $properties $transition-speed ease;
}

@mixin respond($breakpoint) {
  @if $breakpoint== phone {
    @media (max-width: 37.5em) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    }
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    }
  }
}



@mixin fadeIn {
  animation: fadeIn 1s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}