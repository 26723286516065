.servicesOffer {
  
  margin: 5% 15% 10% 15%;
  @media (max-width: 1200px) {
    width: 80%;
  }

  @media (max-width: 1000px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0px;
  }

  &__container {
    box-shadow: $BOX_SHADOW;
    padding: 2rem 3rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: padding .5s;

    @media (max-width: 768px) {
      box-shadow: unset;
      border-radius: unset;
      padding: 2rem 1rem;
    }

    header {
      text-align: center;
      margin-bottom: 20px;

      h3 {
        font-weight: 800;
        margin-top: 1rem;
        text-transform: capitalize;
      }
    }

    &__body {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      margin: 15px 0;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      &__left {
        width: 45%;
        &__singleImg{
          width: 70%;
          margin: 70px;
        }
        @media (max-width: 768px) {
          width: 90%;
        }

        @media (max-width: 500px) {
          width: 100%;
        }

        &__flexImageLeft{
          display: flex;
          justify-content: flex-start;
          img{
            width: 50%;
            border: 3px solid black;
          }
        }
        &__flexImageRight{
          display: flex;
          justify-content: flex-end;
          img {
            width: 50%;
            border: 3px solid black;
          }
        }
      }

      &__right {
        display: flex;
        flex-direction: column;
        padding: 0 2rem;
        width: 55%;
        height: 700px;
        overflow: auto;
        @media (max-width: 768px) {
          width: 100%;
          padding: unset;
          margin-top: 2rem;
        }

        &__radioInput2 {
          margin-left: 3rem !important;
          margin-top: 1rem;

        }

        &__radioInput {
          display: flex;
          align-items: center;
          margin-left: 1rem;

          label {
            color: $DARK_GRAY_COLOR;
            font-size: .9rem;
            margin-left: 1rem;
          }

          input {
            accent-color: $BLUE_COLOR;
            height: 1rem;
            width: 1rem;
          }
        }
      }
    }

    &__nextButton {
      width: 300px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}