.profileSetup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.5s;
  width: 70%;

  @media (max-width: 1200px) {
    width: 80%;
  }

  @media (max-width: 1000px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 100%;
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
  }

  &__container {
    box-shadow: $BOX_SHADOW;
    padding: 1rem 3rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: padding 0.5s;

    @media (max-width: 1000px) {
      padding: 2rem 1rem;
    }
    @media (max-width: 768px) {
      box-shadow: unset;
      border-radius: unset;
    }

    header {
      text-align: center;

      h3 {
        font-weight: 800;
        margin-top: 1rem;
        text-transform: capitalize;
      }
    }

    &__body {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 2rem 0;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      &__left {
        width: 48%;
        padding: 0 2rem;

        @media (max-width: 768px) {
          padding: unset;
          width: 100%;
        }

        h3 {
          margin-bottom: 1rem;
        }

        &__profilePicture {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h3 {
            border-bottom: 1px solid lightgray;
            padding-bottom: 1rem;
            width: 100%;
            text-align: center;
          }

          &__profilePictureBox {
            width: 100%;

            input {
              visibility: hidden;
            }

            &__icon {
              color: $LIGHT_GRAY_COLOR !important;
              position: absolute;
            }

            label {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              img {
                width: 80px;
                height: 80px;
                object-fit: cover;
                border-radius: 50%;
                background-color: $GRAY_COLOR;
              }
            }
          }
        }

        &__inputBox {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;

          div {
            width: 100%;

            &:nth-child(1) {
              margin-right: 1rem;
            }
          }
        }
      }

      &__divider {
        border-left: 2px solid lightgray;

        @media (max-width: 768px) {
          display: none;
        }
      }

      &__right {
        padding: 2rem 2rem;
        width: 48%;

        @media (max-width: 768px) {
          width: 100%;
          padding: unset;
          margin-top: 2rem;
        }

        h3 {
          margin-bottom: 1rem;
        }

        &__profilePictureBox {
          width: 100%;
          margin-top: 1.5rem;

          p {
            color: $BLUE_COLOR;
            font-weight: 600;
            margin-bottom: 1rem;
          }

          input {
            visibility: hidden;
          }

          &__imagesBox {
            display: flex;
            flex-wrap: wrap;

            &__label {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: $LIGHT_GRAY_COLOR;
              height: 80px;
              width: 80px;
              border-radius: 0.5rem;
              margin-right: 0.5rem;

              &__icon {
                color: $WHITE_COLOR;
                font-size: 2.5rem !important;
              }
            }

            img {
              width: 80px;
              height: 80px;
              object-fit: cover;
              border-radius: 0.5rem;
              margin-right: 0.5rem;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }

    &__nextButton {
      width: 300px;
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
