.landingPage {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include fadeIn;

    @media (max-width: 768px) {
      padding: 2rem 1rem 5rem 1rem;
    }

    &__searchBox {
      background-color: $WHITE_COLOR;
      width: 500px;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #e5f3dc;
      @media (max-width: 1250px) {
        width: 300px;
      }

      @media (max-width: 1100px) {
        width: 200px;
      }
      @media (max-width: 1000px) {
        width: 130px;
      }
      @media (max-width: 900px) {
        width: 100%;
      }

      &__search {
        display: flex;
        align-items: center;
        flex: 1;

        &__searchIcon {
          color: $GRAY_COLOR;
          font-size: 1.2rem !important;
          margin-right: 0.5rem;
        }

        &__searchInput {
          width: 100%;
          outline: unset;
          border: unset;
          margin-right: 0.5rem;
        }
      }

      &__locationButton {
        background-color: $BLUE_COLOR !important;
        text-transform: unset !important;
        border-radius: 1rem !important;
        font-size: 0.6rem !important;
        display: flex;
        align-items: center;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;

        &__icon {
          font-size: 0.8rem !important;
          margin-right: 0.3rem;
        }
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 500px;

      &__left {
        width: 50%;
        margin-top: 3.5rem;

        &__textBox {
          width: 80%;

          @media (max-width: 1000px) {
            width: 100%;
            margin-right: 1rem;
          }

          h3 {
            font-weight: 800;
          }

          &__text1 {
            margin-top: 2rem;
          }

          &__text2 {
            margin-top: 2rem;
          }

          &__iconBox {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            &__icon {
              color: $DARK_GRAY_COLOR !important;
              font-size: 1.5rem !important;
              margin-right: 1rem;
            }
          }
        }
      }

      &__right {
        width: 50%;
        display: flex;
        justify-content: flex-start;

        &__image1 {
          margin-top: 5rem;
          object-fit: cover;
          width: 500px;
          transition: width 0.5s;
          margin-left: 2rem;

          @media (max-width: 1150px) {
            width: 450px;
          }

          @media (max-width: 1000px) {
            width: 400px;
            margin-top: 7rem;
          }

          @media (max-width: 900px) {
            width: 350px;
          }

          @media (max-width: 800px) {
            width: 300px;
          }
        }

        &__image2 {
          object-fit: cover;
          width: 380px;
          height: 550px;
          transition: width 0.5s;
          padding-top: 0.2rem;
          margin-left: 4rem;

          @media (max-width: 900px) {
            width: 350px;
            margin-left: 2rem;
          }

          @media (max-width: 800px) {
            width: 300px;
          }
        }
      }
    }

    &__featured {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 5rem;
      @media (max-width: 768px) {
        margin-top: 1rem;
      }
      &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5rem;
        font-size: 2.2rem;
        color: $DARK_BLACK_COLOR;
        text-transform: uppercase;
        font-weight: 700;
        @media (max-width: 768px) {
          margin-top: 1rem;
          font-size: 1.2rem;
        }
        h1 {
          font-size: 2.2rem;
          color: $DARK_BLACK_COLOR;
          text-transform: uppercase;
          font-weight: 700;

          @media (max-width: 768px) {
            font-size: 1.2rem;
          }
        }
      }
    }

    &__cardsContainer1 {
      width: 100%;

      &__arrowIcons {
        display: flex;
        width: 100%;
        justify-content: end;
        gap: 1rem;
        position: relative;
        right: 1rem;
        &__backward {
          background-color: $BLUE_COLOR;
          color: $WHITE_COLOR;
          padding: 0.5rem;
          border-radius: 50%;
          font-size: 3rem !important;
          margin-top: 3rem;
          @media (max-width: 768px) {
            margin-top: 1rem;
            font-size: 2rem !important;
          }
        }

        &__forward {
          background-color: $BLUE_COLOR;
          color: $WHITE_COLOR;
          padding: 0.5rem;
          border-radius: 50%;
          font-size: 3rem !important;
          margin-top: 3rem;

          @media (max-width: 768px) {
            margin-top: 1rem;
            font-size: 2rem !important;
          }
        }
      }

      &__viewButton {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;

        Button {
          background-color: $LIGHT_GRAY_COLOR !important;
          color: $GRAY_COLOR;
          font-weight: 800;
          border-radius: 1rem;
          font-size: 0.6rem;
          padding: 0.2rem 0.8rem;
        }
      }

      &__cards {
        display: flex !important;
        justify-content: space-around !important;
        align-items: flex-start !important;
        // overflow-x: auto !important;
        margin-top: 2rem !important;
        background-color: $LIGHT_OYSTER_BAY_COLOR !important;
        padding: 0rem 1rem 1.3rem 1rem !important;

        @media (max-width: 768px) {
          padding: 0 0.5rem 1rem 0.5rem;
        }

        &__card {
          min-height: 300px;
          max-height: 550px;
          margin-top: 1rem;
          background-color: $WHITE_COLOR;
          box-shadow: $BOX_SHADOW;
          border-radius: 1rem;
          padding: 1.5rem 1.5rem 0.5rem 1.5rem;
          width: 300px;
          min-width: 300px;
          &__imageBox {
            display: flex;
            min-height: 50px;
            max-height: 100px;
            overflow: auto;

            &__image {
              width: 80px;
              height: 90px;
              object-fit: cover;
              border-radius: 0.5rem;
            }

            &__details {
              display: flex;
              flex-direction: column;
              margin-left: 1rem;
              width: 100%;

              &__container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                  font-weight: 900;
                  font-size: 1.2rem;
                }

                // &__favIcon {
                //   color: $BLUE_COLOR;
                // }
              }

              &__rating {
                display: flex;
                align-items: center;
                margin-top: 0.2rem;

                &__icon {
                  color: $YELLOW_COLOR;
                  margin-left: 0.3rem;
                }
              }

              &__bio {
                margin-top: 0.2rem;
                color: $GRAY_COLOR;
                font-size: 0.8rem;
              }
            }
          }

          &__services {
            border-top: 1px solid lightgray;
            margin-top: 0.5rem;
            padding-top: 0.3rem;

            p {
              color: $GRAY_COLOR;
              font-size: 0.75rem;
              text-transform: capitalize;
              font-weight: 600;
            }

            &__offers {
              display: flex;
              justify-content: space-between;
              color: $WHITE_COLOR;
              font-size: 0.7rem;
              width: 100%;
              margin-top: 0.3rem;

              &__offer {
                background-color: $BLUE_COLOR;
                border-radius: 1rem;
                padding: 0.2rem 1rem;
              }
            }
          }

          &__location {
            display: flex;
            align-items: flex-start;
            margin-top: 0.5rem;
            min-height: 30px;
            max-height: 40px;
            overflow: auto;
            p {
              margin-bottom: 0;
              margin-left: 0.3rem;
              font-size: 0.8rem;
              font-weight: 600;
            }

            &__icon {
              font-size: 1rem !important;
            }
          }

          &__descBox {
            border-top: 1px solid lightgray;
            margin-top: 0.8rem;
            padding-top: 0.5rem;
            display: flex;
            justify-content: space-between;

            &__desc {
              display: flex;
              flex-direction: column;

              &__review {
                display: flex;
                align-items: center;

                &__icon {
                  font-size: 1.2rem !important;
                  margin-right: 0.3rem;
                }

                span {
                  font-size: 0.8rem;
                  color: $DARK_GRAY_COLOR;
                  font-weight: 600;
                }
              }

              &__services {
                display: flex;
                align-items: center;
                margin-top: 0.3rem;

                &__icon {
                  font-size: 1.2rem !important;
                  margin-right: 0.3rem;
                }

                span {
                  font-size: 0.8rem;
                  color: $DARK_GRAY_COLOR;
                  font-weight: 600;
                }
              }
            }

            &__price {
              display: flex;
              flex-direction: column;

              p {
                font-size: 0.7rem;
                text-transform: capitalize;
                margin-bottom: 0;
                padding-bottom: 0;
              }

              span {
                color: $BLUE_COLOR;
                font-size: 1.5rem;
                font-weight: 800;
              }
            }
          }
        }
      }
    }

    &__cardsContainer {
      margin: 1rem 0rem;
      width: 100%;
      // padding: 0 2rem;

      &__viewButton {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;

        Button {
          background-color: $LIGHT_GRAY_COLOR !important;
          color: $GRAY_COLOR;
          font-weight: 800;
          border-radius: 1rem;
          font-size: 0.6rem;
          padding: 0.2rem 0.8rem;
        }
      }

      &__cards {
        display: flex;
        align-items: flex-start;
        overflow-y: auto;
        padding: 0.8rem 0;
        gap: 1rem;
        &__card {
          margin-top: 1rem;
          background-color: $WHITE_COLOR;
          box-shadow: $BOX_SHADOW;
          border-radius: 1rem;
          padding: 1.5rem 1.5rem 0.5rem 1.5rem;
          width: 330px;
          @media (max-width: 700px) {
            width: 420px;
          }
          @media (max-width: 520px) {
            width: 320px;
          }
          @media (max-width: 420px) {
            width: 260px;
          }
          @media (max-width: 320px) {
            width: 180px;
          }
          &__imageBox {
            display: flex;

            &__image {
              width: 80px;
              height: 90px;
              object-fit: cover;
              border-radius: 0.5rem;
            }

            &__details {
              display: flex;
              flex-direction: column;
              margin-left: 1rem;
              width: 100%;

              &__container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                word-break: break-all;
                h4:hover {
                  font-style: italic;
                  text-decoration: underline !important;
                  cursor: pointer;
                }
                p {
                  font-weight: 900;
                  font-size: 1.2rem;
                }

                &__favIcon {
                  color: $BLUE_COLOR;
                }
              }

              &__rating {
                display: flex;
                align-items: center;
                margin-top: 0.2rem;

                &__icon {
                  color: $YELLOW_COLOR;
                  margin-left: 0.3rem;
                }
              }

              &__bio {
                margin-top: 0.2rem;
                color: $GRAY_COLOR;
                font-size: 0.8rem;
                font-weight: 500;
              }
            }
          }

          &__services {
            border-top: 1px solid lightgray;
            margin-top: 0.5rem;
            padding-top: 0.5rem;

            h5 {
              color: $GRAY_COLOR;
              font-size: 0.75rem;
              text-transform: capitalize;
              font-weight: 600;
              word-break: break-all;
            }

            &__offers {
              display: flex;
              color: $WHITE_COLOR;
              font-size: 0.7rem;
              width: 100%;
              margin-top: 0.5rem;
              gap: 1rem;
              text-wrap: nowrap;
              overflow-x: scroll;
              // flex-wrap: wrap;
              &__offer {
                background-color: $BLUE_COLOR;
                border-radius: 1rem;
                padding: 0.2rem 1rem;
                margin-bottom: 0.3rem;
                font-weight: 500;
              }
            }
          }

          &__location {
            display: flex;
            align-items: flex-start;
            margin-top: 0.5rem;
            word-break: break-all;
            h5 {
              margin-bottom: 0;
              margin-left: 0.3rem;
              font-size: 0.8rem;
              font-weight: 600;
            }

            &__icon {
              font-size: 1rem !important;
            }
          }

          &__descBox {
            border-top: 1px solid lightgray;
            margin-top: 0.8rem;
            padding-top: 0.8rem;
            display: flex;
            justify-content: space-between;
            &__desc {
              display: flex;
              flex-direction: column;

              &__review {
                display: flex;
                align-items: center;

                &__icon {
                  font-size: 1.2rem !important;
                  margin-right: 0.3rem;
                }

                h5 {
                  font-size: 0.8rem;
                  color: $DARK_GRAY_COLOR;
                  font-weight: 600;
                }
              }

              &__services {
                display: flex;
                align-items: center;
                margin-top: 0.3rem;

                &__icon {
                  font-size: 1.2rem !important;
                  margin-right: 0.3rem;
                }

                h5 {
                  font-size: 0.8rem;
                  color: $DARK_GRAY_COLOR;
                  font-weight: 600;
                }
              }
            }

            &__price {
              display: flex;
              flex-direction: column;
              h5 {
                font-size: 0.7rem;
                text-transform: capitalize;
                margin-bottom: 0;
                padding-bottom: 0;
              }

              h5 {
                color: $BLUE_COLOR;
                font-size: 1.5rem;
                font-weight: 800;
                word-break: break-all;
                @media (max-width: 1100px) {
                  font-size: small;
                }
              }
            }
          }
        }
      }
    }
  }
  &__imageCard {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    &__images {
      cursor: pointer;
      width: 200px;
      height: 200px;
      object-fit: cover;
      border-radius: 6px;
      &:hover {
        filter: brightness(70%) sepia(1) hue-rotate(180deg) saturate(200%);
      }
      &__title {
        z-index: 1;
        width: 200px;
        position: relative;
        color: white !important;
        font-size: 14px;
        padding: 0.2rem;
        bottom: 1rem;
        background-color: #537198;
        text-wrap: noWrap;
      }
    }
  }
}

.landingPageCarousel {
  display: flex !important;
  justify-content: space-around !important;
  align-items: flex-start !important;
  // overflow-x: auto !important;
  margin-top: 2rem !important;
  padding: 0rem 1rem 1.3rem 1rem !important;
  //  &__imagesSection{
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //  }
  &__search {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 0 2rem;

    &__left {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 3rem;

      &__image {
        width: 300px;

        @media (max-width: 650px) {
          width: 100%;
        }

        img {
          height: 120px;
          border-radius: 0.5rem;
          object-fit: cover;

          @media (max-width: 650px) {
            height: auto;
          }
        }
      }

      &__text {
        margin-top: 2rem;
        color: $WHITE_COLOR;
        width: 80%;

        @media (max-width: 768px) {
          width: 100%;
        }

        @media (max-width: 500px) {
          font-size: 0.85rem;
          line-height: 16px;
          font-weight: normal;
        }

        &__text2 {
          margin-top: 1rem;

          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }

    &__right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      &__image {
        width: 450px;

        @media (max-width: 900px) {
          width: 350px;
        }

        @media (max-width: 768px) {
          width: auto;
          margin-left: 0.5rem;
        }

        img {
          object-fit: cover;
          margin-top: 2rem;

          @media (max-width: 768px) {
            margin-top: 9rem;
          }
        }
      }
    }
  }

  &__text2 {
    display: none;

    @media (max-width: 768px) {
      display: block;
      color: $WHITE_COLOR;
      margin-top: 1rem;
      padding: 0 1rem;
    }

    @media (max-width: 500px) {
      font-size: 0.85rem;
      line-height: 16px;
      font-weight: normal;
    }
  }
}

.landingPageCarousel2 {
  background-color: $BLUE_COLOR;
  height: 550px;

  @media (max-width: 500px) {
    height: 500px;
  }

  &__search {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 0 2rem;

    &__left {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 3rem;

      &__image {
        width: 300px;

        @media (max-width: 650px) {
          width: 100%;
        }

        img {
          height: 120px;
          border-radius: 0.5rem;
          object-fit: cover;

          @media (max-width: 650px) {
            height: auto;
          }
        }
      }

      &__text {
        margin-top: 2rem;
        color: $WHITE_COLOR;
        width: 80%;

        @media (max-width: 500px) {
          font-size: 0.85rem;
          line-height: 16px;
          font-weight: normal;
        }

        @media (max-width: 768px) {
          width: 100%;
        }

        &__text2 {
          margin-top: 1rem;

          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }

    &__right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      &__image {
        width: 450px;

        @media (max-width: 900px) {
          width: 350px;
        }

        img {
          object-fit: cover;
          margin-top: 9.9rem;
        }
      }

      &__image1 {
        width: 350px;

        @media (max-width: 900px) {
          width: 300px;
        }

        @media (max-width: 768px) {
          width: auto;
          margin-left: 0.5rem;
        }

        img {
          object-fit: cover;
          height: 550px;
          margin-top: 2rem;
          @media (max-width: 1390px) {
            margin-top: 1rem;
          }
          @media (max-width: 768px) {
            height: 300px;
          }
        }
      }
    }
  }

  &__text2 {
    display: none;
    color: $WHITE_COLOR;
    margin-top: 1rem;
    padding: 0 1rem;

    @media (max-width: 768px) {
      display: block;
    }

    @media (max-width: 500px) {
      font-size: 0.85rem;
      line-height: 16px;
      font-weight: normal;
    }
  }
}

.landingPageCarousel3 {
  background-color: $DARK_BLACK_COLOR;
  height: 550px;

  @media (max-width: 500px) {
    height: 500px;
  }

  &__search {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 0 2rem;

    &__left {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 3rem;

      &__image {
        width: 300px;

        @media (max-width: 650px) {
          width: 100%;
        }

        img {
          height: 120px;
          border-radius: 0.5rem;
          object-fit: cover;

          @media (max-width: 650px) {
            height: auto;
          }
        }
      }

      &__text {
        margin-top: 2rem;
        color: $WHITE_COLOR;
        width: 80%;

        @media (max-width: 768px) {
          width: 100%;
        }

        @media (max-width: 500px) {
          font-size: 0.85rem;
          line-height: 16px;
          font-weight: normal;
        }

        &__text2 {
          margin-top: 1rem;

          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }

    &__right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      &__image {
        width: 450px;

        @media (max-width: 900px) {
          width: 350px;
        }

        img {
          object-fit: cover;
          margin-top: 9.9rem;
        }
      }

      &__image1 {
        width: 350px;

        @media (max-width: 900px) {
          width: 300px;
        }

        @media (max-width: 768px) {
          width: auto;
          margin-left: 0.5rem;
        }

        img {
          object-fit: cover;
          height: 550px;
          margin-top: 2rem;
          @media (max-width: 1390px) {
            margin-top: 1rem;
          }
          @media (max-width: 768px) {
            height: 300px;
          }
        }
      }
    }
  }

  &__text2 {
    display: none;
    color: $WHITE_COLOR;
    margin-top: 1rem;
    padding: 0 1rem;

    @media (max-width: 768px) {
      display: block;
    }

    @media (max-width: 500px) {
      font-size: 0.85rem;
      line-height: 16px;
      font-weight: normal;
    }
  }
}

.massageTab {
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }

  &__filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 1250px) {
      display: flex;
      flex-direction: column-reverse;
    }

    &__filter {
      display: flex;
      align-items: center;
      margin-left: 13%;

      @media (max-width: 1250px) {
        margin-bottom: 1rem;
        margin-left: unset;
      }

      &__sortButton {
        background-color: $WHITE_COLOR !important;
        color: $BLUE_COLOR !important;
        text-transform: capitalize !important;
        border-radius: 0.5rem !important;
        font-weight: 600 !important;
        font-size: 0.7rem !important;
        padding-left: 1.2rem !important;
        white-space: nowrap;

        &__icon {
          margin-left: 0.5rem;
          font-size: 1rem !important;
        }
      }

      &__box {
        display: flex;
        align-items: center;
        margin-left: 1rem;

        &__icon {
          color: $BLUE_COLOR;
          margin-right: 0.3rem;
        }

        p {
          font-weight: 600;
          font-size: 0.8rem;
          color: $BLUE_COLOR;
        }
      }
    }
  }

  &__cards {
    padding: 1rem 0 !important;
    display: flex !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
  }
}

.sortMenuHead {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0rem 0 1rem 0;

  p {
    color: $DARK_BLACK_COLOR;
    font-weight: 600;
  }
}

.dot.selected {
  background-color: $BLUE_COLOR !important;
  height: 15px !important;
  width: 15px !important;
}

.dot {
  height: 12px !important;
  width: 12px !important;
}

.carousel .carousel-status {
  display: none;
}

.css-1nww0z5-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root .MuiMenuItem-root {
  background-color: $BLUE_COLOR !important;
  border-radius: 0.8rem !important;
}

// .swiper-slide {
//   display: flex !important;
//   width: 25% !important;
//   gap: 1rem !important;
// }
// .swiper-slide {
//   width: 25% !important;
// }
// .swiper-slide {
//   @media (max-width: 1500px) {
//     width: auto !important;
//   }
// }
// .swiper-wrapper{
//   display: flex !important;
//   justify-content: space-between !important;
// }
.landingPage__container__cardsContainer__cards__card__services__offers::-webkit-scrollbar {
  height: 5px;
}
.landingPage__container__cardsContainer__cards__card__services__offers::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px white;
  border-radius: 10px;
}
.landingPage__container__cardsContainer__cards__card__services__offers::-webkit-scrollbar-thumb {
  background: #537198;
  border-radius: 10px;
}
.landingPage__container__cardsContainer__cards__card__services__offers::-webkit-scrollbar-thumb:hover {
  background: #537198;
}
.homeTab__container__cardsContainer__cards__card {
  margin-top: 1rem;
  background-color: $WHITE_COLOR;
  box-shadow: $BOX_SHADOW;
  border-radius: 1rem;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  margin: 1rem;
}
.massageTab__container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  @media (max-width: 1100px) {
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
}
.favourite {
  width: 100%;
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
}
.css-1tklgxf {
  margin-right: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.flex {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.skeleton {
  display: flex;
  background-color: $LIGHT_OYSTER_BAY_COLOR !important;
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: center;
  }
}
.landingPage__container__cardsContainer__cards__card__profileSection:hover {
  cursor: pointer;
  filter: blur(0.5px);
}

.landingPageCarousel {
  padding: 20px;
  width: 100%;
}

.landingPageCarousel__swiper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.landingPageCarousel__image {
  width: 100%;
  height: 250px; /* Set your desired height */
  margin: 0;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintains aspect ratio while filling container */
  display: block;
}

/* Swiper navigation styling */
.swiper-button-next,
.swiper-button-prev {
  color: #ffffff;
}

/* Swiper pagination styling */
.swiper-pagination-bullet {
  background: #ffffff;
  opacity: 0.7;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #87c85b;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .landingPageCarousel__image {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .landingPageCarousel__image {
    height: 150px;
  }
}
.image-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: $grid-gap;
  padding: 2rem;
  max-width: $max-width;
  margin: 0 auto;
}

.image-card {
  position: relative;
  cursor: pointer;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: $primary-shadow;
  @include transition(transform, box-shadow);

  &:hover {
    transform: translateY(-5px);
    box-shadow: $hover-shadow;

    .image-card__image {
      transform: scale(1.05);
    }
  }

  &__container {
    position: relative;
    width: 100%;
    padding-top: 125%; // Aspect ratio 4:5
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include transition(transform);
  }

  &__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    padding: 2rem 1rem 1rem;
    color: white;
  }

  &__title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.3;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .image-card-grid {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }

  .image-card {
    &__title {
      font-size: 1.1rem;
    }
  }
}

@media (max-width: 480px) {
  .image-card-grid {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .image-card {
    &__title {
      font-size: 1rem;
    }
  }
}
