.mybatch {
    margin-top: 5rem;
    margin-left: 250px;
    padding: 1rem;
    @include fadeIn;

    @media (max-width: 768px) {
        padding: 1rem 1rem;
        margin-left: 0;
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 3rem;
        gap: 1rem;
        @media (max-width: 768px) {
            justify-content: center;
        }
        &__box {
            background: #193d66 0% 0% no-repeat padding-box;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 15px #00000029;
            border: 1px solid #b4b6b840;
            border-radius: 15px;
            opacity: 1;
            width: 290px;
            &__title {
                text-align: center;
                font: normal normal normal 13px/30px Poppins;
                letter-spacing: 0px;
                color: #888b8d;
                opacity: 1;
                margin-bottom: .5rem;
            }
            &__messsauretitle {
                text-align: center;
                font: normal normal medium 13px/30px Poppins;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                margin-bottom: .5rem;

            }
            &__servicetitle {
                text-align: center;
                font: normal normal bold 20px/39px Poppins;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
            }
            &__container {
                background: #193d66 0% 0% no-repeat padding-box;
                // clip-path: polygon(46% 47%, 82% 21%, 100% 0, 100% 97%, 80% 98%, 50% 100%, 21% 100%, 0 100%, 0 0, 19% 23%);
                padding: 1rem;
                color: white;
            //   clip-path: polygon(38% 13%, 60% 13%, 100% 0, 100% 72%, 100% 100%, 32% 100%, 1% 100%, 0 0, 10% 7%);
            // clip-path: polygon(49% 18%, 80% 10%, 100% 0, 100% 70%, 100% 100%, 50% 100%, 0 100%, 0% 70%, 0 0, 20% 10%);

            // clip-path: polygon(50% 21%, 80% 10%, 100% 0, 100% 70%, 100% 100%, 50% 100%, 0 100%, 0% 70%, 0 0, 20% 10%);
            clip-path: polygon(48% 16%, 80% 9%, 100% 0, 100% 70%, 100% 100%, 50% 100%, 0 100%, 0% 70%, 0 0, 20% 9%);

                &__title {
                    text-align: center;
                    font: normal normal normal 13px/30px Poppins;
                    letter-spacing: 0px;
                    color: white;
                    opacity: 1;
                    margin-top: 1rem;
                }
                &__paragraph {
                    text-align: center;
                    font: normal normal normal 13px/30px Poppins;
                    letter-spacing: 0px;
                    color: white;
                    opacity: 1;
                    margin-top: 0.3rem;
                }
            }
            &__image {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                bottom: 1rem;
                img {
                    width: 50px;
                    opacity: 1;
                    border-radius: 50px;
                }
            }
        }
    }
}
