//BASIC
// $THEME_COLOR: #1e293b;
// $PRIMARY_COLOR: #738cf8;

// OTHER COLORS
$WHITE_COLOR: #FFFFFF;
$DARK_BLUE_COLOR: #102843;
$BLUE_COLOR: #193D66;
$GRAY_COLOR: #858585;
$DARK_GRAY_COLOR: #4E4E4E;
$LIGHT_GRAY_COLOR: #E6EAEE;
$FONT_GRAY_COLOR: #C2C1C0;
$GREEN_COLOR: #87C85B;
$LIGHT_GREEN_COLOR: #D8EDCA;
$DARK_GREEN_COLOR: #7FC550;
$OYSTER_BAY_COLOR: #D9F1F1;
$DARK_OYSTER_BAY_COLOR: #D9EFF6;
$LIGHT_OYSTER_BAY_COLOR: #E5F3DC;
$YELLOW_COLOR: #D7BD73;
$LIGHT_BLACK_COLOR: #4B4A4A;
$DARK_BLACK_COLOR: #313131;
$DARK_LIGHTGRAY_COLOR: #717171;
$LIGHT_BLUE: #2597f6;
$WIGHT_COLOR_1: #e7e7e7;
$PLUM_COLOR: #DCD1E0;
$DARK_PLUM_COLOR: #4F1966;
$LIGHT_BLUE_COLOR: #CED6E2;
$GOLD_COLOR: #FFE177;
$BLUE_COLOR_1: #2e3d4e;
$MID_BLUE_COLOR: #3FB1D3;   
$LIGHT_WHEAT_COLOR:#f7f5f5;
$LIGHT_RED_COLOR:#e9e9e9;
$RED_COLOR:red;
$primary-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
$hover-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
$border-radius: 12px;
$grid-gap: 1.5rem;
$max-width: 1400px;
$transition-speed: 0.3s;
// BOX SHADOW
$BOX_SHADOW: 1px 1px 15px lightgray;
$BLACK_COLOR: #0000004a;
$BLACK_COLOR_1: #000000;
$GLODEN_COLOR: #dbc37a;
$BLACK_COLOR_2: #00000029;
$BLACK_COLOR_3: #70707008;
$BLACK_COLOR_4:#222222;