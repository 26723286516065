html {
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}
*:not(i),
*::after:not(i),
*::before:not(i) {
  font-family: "Poppins", sans-serif !important;
}

body {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: unset !important;
}

.clientNavbar {
  background-color: #FFFFFF !important;
  box-shadow: unset !important;
  color: #193D66 !important;
  padding: 0.5rem 1.2rem;
  position: fixed !important;
  top: 0 !important;
  z-index: 1 !important;
}
@media (max-width: 768px) {
  .clientNavbar {
    padding: 0.9rem 0rem;
  }
}
.clientNavbar__searchField {
  width: 430px;
}
@media (max-width: 1050px) {
  .clientNavbar__searchField {
    display: none !important;
  }
}
.clientNavbar__navTabs {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-left: 2rem !important;
}
@media (max-width: 768px) {
  .clientNavbar__navTabs {
    display: none !important;
  }
}
.clientNavbar__navTabs__dashboardtitle:hover {
  text-decoration: underline !important;
  transition: all 1s;
}
.clientNavbar__navTabs__favouritetitle:hover {
  text-decoration: underline !important;
  transition: all 1s;
}
.clientNavbar__buttonsBox {
  flex-grow: 0;
  display: flex;
  align-items: center;
}
.clientNavbar__buttonsBox__signIn {
  text-transform: uppercase;
  color: #193D66 !important;
  margin-right: 1.5rem !important;
  background-color: #FFFFFF !important;
  box-shadow: unset !important;
  padding: 0.4rem 2rem !important;
  border-radius: 0.5rem !important;
  font-weight: 600 !important;
}
.clientNavbar__buttonsBox__register {
  columns: #FFFFFF !important;
  background-color: #193D66 !important;
  padding: 0.4rem 2rem !important;
  border-radius: 0.5rem !important;
}
.clientNavbar__buttonsBox__iconButton {
  margin-right: 1rem !important;
}
@media (max-width: 768px) {
  .clientNavbar__buttonsBox__iconButton {
    margin-right: 0.5rem !important;
  }
}
.clientNavbar__buttonsBox__iconButton__searchIcon {
  color: #858585 !important;
}
.clientNavbar__buttonsBox__iconButton__shoppingIcon {
  color: #858585 !important;
}
.clientNavbar__buttonsBox__iconButton__translationIcon {
  color: #193D66 !important;
}
.clientNavbar__buttonsBox__separator {
  color: lightgray;
  margin-right: 1rem;
  font-size: 1.5rem;
}
.clientNavbar__logo {
  width: 160px;
  object-fit: cover;
  cursor: pointer;
}
@media (max-width: 768px) {
  .clientNavbar__logo {
    display: none;
  }
}
.clientNavbar__drawer {
  display: none;
}
@media (max-width: 768px) {
  .clientNavbar__drawer {
    display: block;
  }
}

.greenInput {
  height: 60px;
  display: flex;
  flex-direction: column;
  border: 2px solid #87C85B;
  border-radius: 1rem;
  padding: 0rem 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.greenInput label {
  color: #858585;
  font-size: 13px;
  font-weight: 600;
}
.greenInput__inputBox {
  display: flex;
  justify-content: flex-end;
}
.greenInput__inputBox input {
  margin-top: 0.5rem;
  outline: unset;
  border: unset;
  color: #193D66;
  font-weight: 600;
  font-size: 15px;
  padding-right: 40px;
  background-color: unset;
  border-radius: 0.5rem;
  width: 100%;
  padding-left: 0.5rem;
}
.greenInput__inputBox input::placeholder {
  font-weight: normal;
}
.greenInput__inputBox__icon {
  position: absolute;
  color: #858585;
  margin-top: -10px;
}

.greenSelect {
  height: 60px;
  display: flex;
  flex-direction: column;
  border: 2px solid #87C85B;
  border-radius: 1rem;
  padding: 0rem 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.greenSelect label {
  color: #858585;
  font-size: 13px;
  font-weight: 600;
}
.greenSelect select {
  outline: unset;
  border: unset;
  font-weight: 600;
  color: #858585;
  margin-top: 0.6rem;
}
.greenSelect select option {
  font-weight: 600;
  color: #858585;
}

.blueButton {
  background-color: #193D66 !important;
  text-transform: capitalize !important;
  width: 100% !important;
  border-radius: 0.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.blueButton span {
  color: white !important;
}

.greenRadioInput {
  border: 2px solid #87C85B;
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.greenRadioInput label {
  color: #4E4E4E;
  margin-left: 1rem;
  font-size: 0.9rem;
}
.greenRadioInput input {
  accent-color: #193D66;
  height: 1rem;
  width: 1rem;
}

.greenTextarea {
  border: 2px solid #87C85B;
  border-radius: 1rem;
  padding: 0.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.7rem;
}
.greenTextarea label {
  color: #858585;
  font-size: 13px;
  font-weight: 600;
}
.greenTextarea__textBox {
  margin-top: 0.3rem;
}
.greenTextarea__textBox textarea {
  border: unset;
  outline: unset;
  width: 100%;
  color: #193D66;
  font-weight: 600;
  font-size: 15px;
}
.greenTextarea__textBox textarea::placeholder {
  font-weight: normal;
}

.rmsc .dropdown-container {
  margin: 12px 1px;
  border: 2px solid #87C85B !important;
  border-radius: 12px !important;
  padding: 8px;
  color: #858585;
}

.footer {
  width: 100%;
}
.footer__top {
  background-color: #313131;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  padding: 2rem 4rem 1rem 4rem;
  color: #C2C1C0;
  cursor: pointer;
}
@media (max-width: 800px) {
  .footer__top {
    padding: 2rem 2rem 1rem 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
.footer__top__phone {
  margin-bottom: 1rem;
  margin-right: 2rem;
}
.footer__top__phone p {
  font-size: 1.2rem;
}
.footer__top__phone p:hover {
  text-decoration: underline !important;
  transition: all 1s;
}
.footer__top__social {
  margin-bottom: 1rem;
}
.footer__top__social p {
  font-size: 1.2rem;
}
.footer__top__social__icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}
.footer__top__social__icons__icon {
  color: #E6EAEE !important;
  font-size: 1.2rem !important;
}
.footer__top__subscription {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 2rem;
}
@media (max-width: 600px) {
  .footer__top__subscription {
    margin-left: 0;
  }
}
.footer__top__subscription input {
  outline: unset;
  border: unset;
  padding: 0.5rem;
  width: 200px;
}
.footer__top__subscription input::placeholder {
  text-transform: uppercase;
  font-size: 0.7rem;
}
.footer__top__subscription Button {
  color: #E6EAEE;
  border-radius: unset;
  background-color: #4B4A4A !important;
  font-size: 0.7rem;
  padding: 0.55rem 0.8rem;
}
.footer__body {
  background-color: #4B4A4A;
  color: #E6EAEE;
  padding: 2rem 4rem 1rem 4rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .footer__body {
    padding: 2rem 2rem 1rem 2rem;
    display: flex;
    flex-direction: column;
  }
}
.footer__body__left {
  display: flex;
  flex-direction: column;
  width: 60%;
}
@media (max-width: 768px) {
  .footer__body__left {
    width: 100%;
  }
}
.footer__body__left p {
  font-size: 1.2rem;
  text-transform: uppercase;
}
.footer__body__left__citiesBox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer__body__left__citiesBox__cities {
  display: flex;
  flex-direction: column;
}
.footer__body__left__citiesBox__cities span {
  font-size: 0.8rem;
  color: #C2C1C0;
  margin-top: 1rem;
  margin-right: 2rem;
}
.footer__body__right {
  width: 25%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .footer__body__right {
    width: 100%;
    margin-top: 2rem;
  }
}
.footer__body__right__box {
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .footer__body__right__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.footer__body__right__box__aboutUs p {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.footer__body__right__box__aboutUs__options {
  display: flex;
  flex-direction: column;
}
.footer__body__right__box__aboutUs__options span {
  font-size: 0.7rem;
  color: #C2C1C0;
  margin-top: 0.5rem;
}
.footer__body__right__box__aboutMassage {
  margin-top: 5rem;
}
@media (max-width: 768px) {
  .footer__body__right__box__aboutMassage {
    margin-top: 0;
  }
}
.footer__body__right__box__aboutMassage p {
  font-size: 1.2rem;
  text-transform: capitalize;
}
.footer__body__right__box__aboutMassage__options {
  display: flex;
  flex-direction: column;
}
.footer__body__right__box__aboutMassage__options span {
  font-size: 0.7rem;
  color: #C2C1C0;
  margin-top: 0.5rem;
}
.footer__body__right__policy {
  margin-top: 5rem;
}
@media (max-width: 768px) {
  .footer__body__right__policy {
    margin-top: 2rem;
  }
}
.footer__body__right__policy a {
  color: #E6EAEE;
  text-decoration: unset;
  font-size: 0.7rem;
  font-weight: 800;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .provider {
    margin-left: unset;
  }
}
.provider__sidebar__section {
  position: fixed;
  align-items: center;
  text-align: center;
  padding: 0.5rem 0.5rem;
  background-color: #193D66;
  width: 250px;
  height: 100%;
  cursor: pointer;
}
.provider__sidebar__section :hover {
  background-color: #476485;
  border-radius: 6px;
  transition: all 1.5s;
}
@media (max-width: 768px) {
  .provider__sidebar__section {
    display: none;
  }
}
@media (max-width: 768px) {
  .provider__sidebar__section {
    position: unset;
  }
}
.provider__sidebar__section__myprofile {
  display: flex;
  align-items: center;
  padding: 0.9rem 1.5rem;
  color: #FFFFFF;
  letter-spacing: 0px;
}
.provider__sidebar__section__myprofile__icon {
  position: relative;
  top: 0.2rem;
}
.provider__sidebar__section__myprofile__icon__dollar {
  display: flex;
  justify-content: centers;
  background-color: #193D66;
  border-radius: 0.5rem;
  align-items: center;
  position: relative;
  left: 0.6rem;
  padding: 0.5rem;
}
.provider__sidebar__section__myprofile__name {
  margin-left: 1.7rem;
  font-size: 0.8rem;
  white-space: nowrap;
  font-weight: 500;
}

.provider__sidebar__section::-webkit-scrollbar {
  width: 5px;
}

.provider__sidebar__section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFFFFF;
  border-radius: 10px;
}

.provider__sidebar__section::-webkit-scrollbar-thumb {
  background: #193D66;
  border-radius: 10px;
  height: 10px;
}

.provider__sidebar__section::-webkit-scrollbar-thumb:hover {
  height: 10px;
}

@media (max-width: 768px) {
  .providerSidebar {
    display: none;
  }
}

.navbar__languageButton {
  background-color: transparent !important;
  color: #193D66 !important;
  text-transform: capitalize !important;
  border-radius: 0.5rem !important;
  font-weight: 600 !important;
  font-size: 0.7rem !important;
  white-space: nowrap;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
}

.label {
  font-weight: 600;
  color: gray;
  font-size: 16px;
}

.toastMessage {
  left: 45% !important;
  border: 3px solid black;
  border-radius: 13px;
}
@media (max-width: 768px) {
  .toastMessage {
    left: 5% !important;
  }
}
.toastMessage div {
  font-weight: 600;
}

.contactUs {
  margin: 120px 200px;
  border: 2px solid #193d66;
  padding: 20px;
  border-radius: 20px;
}
@media (max-width: 768px) {
  .contactUs {
    margin: 120px 20px;
  }
}
.contactUs h1 {
  text-align: center;
}

html,
body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.clientPage {
  height: 100%;
  /* Pushes the footer to the bottom */
}

.landingPage {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}
.landingPage__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .landingPage__container {
    padding: 2rem 1rem 5rem 1rem;
  }
}
.landingPage__container__searchBox {
  background-color: #FFFFFF;
  width: 500px;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e5f3dc;
}
@media (max-width: 1250px) {
  .landingPage__container__searchBox {
    width: 300px;
  }
}
@media (max-width: 1100px) {
  .landingPage__container__searchBox {
    width: 200px;
  }
}
@media (max-width: 1000px) {
  .landingPage__container__searchBox {
    width: 130px;
  }
}
@media (max-width: 900px) {
  .landingPage__container__searchBox {
    width: 100%;
  }
}
.landingPage__container__searchBox__search {
  display: flex;
  align-items: center;
  flex: 1;
}
.landingPage__container__searchBox__search__searchIcon {
  color: #858585;
  font-size: 1.2rem !important;
  margin-right: 0.5rem;
}
.landingPage__container__searchBox__search__searchInput {
  width: 100%;
  outline: unset;
  border: unset;
  margin-right: 0.5rem;
}
.landingPage__container__searchBox__locationButton {
  background-color: #193D66 !important;
  text-transform: unset !important;
  border-radius: 1rem !important;
  font-size: 0.6rem !important;
  display: flex;
  align-items: center;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.landingPage__container__searchBox__locationButton__icon {
  font-size: 0.8rem !important;
  margin-right: 0.3rem;
}
.landingPage__container__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 500px;
}
.landingPage__container__content__left {
  width: 50%;
  margin-top: 3.5rem;
}
.landingPage__container__content__left__textBox {
  width: 80%;
}
@media (max-width: 1000px) {
  .landingPage__container__content__left__textBox {
    width: 100%;
    margin-right: 1rem;
  }
}
.landingPage__container__content__left__textBox h3 {
  font-weight: 800;
}
.landingPage__container__content__left__textBox__text1 {
  margin-top: 2rem;
}
.landingPage__container__content__left__textBox__text2 {
  margin-top: 2rem;
}
.landingPage__container__content__left__textBox__iconBox {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landingPage__container__content__left__textBox__iconBox__icon {
  color: #4E4E4E !important;
  font-size: 1.5rem !important;
  margin-right: 1rem;
}
.landingPage__container__content__right {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}
.landingPage__container__content__right__image1 {
  margin-top: 5rem;
  object-fit: cover;
  width: 500px;
  transition: width 0.5s;
  margin-left: 2rem;
}
@media (max-width: 1150px) {
  .landingPage__container__content__right__image1 {
    width: 450px;
  }
}
@media (max-width: 1000px) {
  .landingPage__container__content__right__image1 {
    width: 400px;
    margin-top: 7rem;
  }
}
@media (max-width: 900px) {
  .landingPage__container__content__right__image1 {
    width: 350px;
  }
}
@media (max-width: 800px) {
  .landingPage__container__content__right__image1 {
    width: 300px;
  }
}
.landingPage__container__content__right__image2 {
  object-fit: cover;
  width: 380px;
  height: 550px;
  transition: width 0.5s;
  padding-top: 0.2rem;
  margin-left: 4rem;
}
@media (max-width: 900px) {
  .landingPage__container__content__right__image2 {
    width: 350px;
    margin-left: 2rem;
  }
}
@media (max-width: 800px) {
  .landingPage__container__content__right__image2 {
    width: 300px;
  }
}
.landingPage__container__featured {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
}
@media (max-width: 768px) {
  .landingPage__container__featured {
    margin-top: 1rem;
  }
}
.landingPage__container__featured__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  font-size: 2.2rem;
  color: #313131;
  text-transform: uppercase;
  font-weight: 700;
}
@media (max-width: 768px) {
  .landingPage__container__featured__header {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
}
.landingPage__container__featured__header h1 {
  font-size: 2.2rem;
  color: #313131;
  text-transform: uppercase;
  font-weight: 700;
}
@media (max-width: 768px) {
  .landingPage__container__featured__header h1 {
    font-size: 1.2rem;
  }
}
.landingPage__container__cardsContainer1 {
  width: 100%;
}
.landingPage__container__cardsContainer1__arrowIcons {
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 1rem;
  position: relative;
  right: 1rem;
}
.landingPage__container__cardsContainer1__arrowIcons__backward {
  background-color: #193D66;
  color: #FFFFFF;
  padding: 0.5rem;
  border-radius: 50%;
  font-size: 3rem !important;
  margin-top: 3rem;
}
@media (max-width: 768px) {
  .landingPage__container__cardsContainer1__arrowIcons__backward {
    margin-top: 1rem;
    font-size: 2rem !important;
  }
}
.landingPage__container__cardsContainer1__arrowIcons__forward {
  background-color: #193D66;
  color: #FFFFFF;
  padding: 0.5rem;
  border-radius: 50%;
  font-size: 3rem !important;
  margin-top: 3rem;
}
@media (max-width: 768px) {
  .landingPage__container__cardsContainer1__arrowIcons__forward {
    margin-top: 1rem;
    font-size: 2rem !important;
  }
}
.landingPage__container__cardsContainer1__viewButton {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.landingPage__container__cardsContainer1__viewButton Button {
  background-color: #E6EAEE !important;
  color: #858585;
  font-weight: 800;
  border-radius: 1rem;
  font-size: 0.6rem;
  padding: 0.2rem 0.8rem;
}
.landingPage__container__cardsContainer1__cards {
  display: flex !important;
  justify-content: space-around !important;
  align-items: flex-start !important;
  margin-top: 2rem !important;
  background-color: #E5F3DC !important;
  padding: 0rem 1rem 1.3rem 1rem !important;
}
@media (max-width: 768px) {
  .landingPage__container__cardsContainer1__cards {
    padding: 0 0.5rem 1rem 0.5rem;
  }
}
.landingPage__container__cardsContainer1__cards__card {
  min-height: 300px;
  max-height: 550px;
  margin-top: 1rem;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 15px lightgray;
  border-radius: 1rem;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  width: 300px;
  min-width: 300px;
}
.landingPage__container__cardsContainer1__cards__card__imageBox {
  display: flex;
  min-height: 50px;
  max-height: 100px;
  overflow: auto;
}
.landingPage__container__cardsContainer1__cards__card__imageBox__image {
  width: 80px;
  height: 90px;
  object-fit: cover;
  border-radius: 0.5rem;
}
.landingPage__container__cardsContainer1__cards__card__imageBox__details {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  width: 100%;
}
.landingPage__container__cardsContainer1__cards__card__imageBox__details__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.landingPage__container__cardsContainer1__cards__card__imageBox__details__container p {
  font-weight: 900;
  font-size: 1.2rem;
}
.landingPage__container__cardsContainer1__cards__card__imageBox__details__rating {
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
}
.landingPage__container__cardsContainer1__cards__card__imageBox__details__rating__icon {
  color: #D7BD73;
  margin-left: 0.3rem;
}
.landingPage__container__cardsContainer1__cards__card__imageBox__details__bio {
  margin-top: 0.2rem;
  color: #858585;
  font-size: 0.8rem;
}
.landingPage__container__cardsContainer1__cards__card__services {
  border-top: 1px solid lightgray;
  margin-top: 0.5rem;
  padding-top: 0.3rem;
}
.landingPage__container__cardsContainer1__cards__card__services p {
  color: #858585;
  font-size: 0.75rem;
  text-transform: capitalize;
  font-weight: 600;
}
.landingPage__container__cardsContainer1__cards__card__services__offers {
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
  font-size: 0.7rem;
  width: 100%;
  margin-top: 0.3rem;
}
.landingPage__container__cardsContainer1__cards__card__services__offers__offer {
  background-color: #193D66;
  border-radius: 1rem;
  padding: 0.2rem 1rem;
}
.landingPage__container__cardsContainer1__cards__card__location {
  display: flex;
  align-items: flex-start;
  margin-top: 0.5rem;
  min-height: 30px;
  max-height: 40px;
  overflow: auto;
}
.landingPage__container__cardsContainer1__cards__card__location p {
  margin-bottom: 0;
  margin-left: 0.3rem;
  font-size: 0.8rem;
  font-weight: 600;
}
.landingPage__container__cardsContainer1__cards__card__location__icon {
  font-size: 1rem !important;
}
.landingPage__container__cardsContainer1__cards__card__descBox {
  border-top: 1px solid lightgray;
  margin-top: 0.8rem;
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
}
.landingPage__container__cardsContainer1__cards__card__descBox__desc {
  display: flex;
  flex-direction: column;
}
.landingPage__container__cardsContainer1__cards__card__descBox__desc__review {
  display: flex;
  align-items: center;
}
.landingPage__container__cardsContainer1__cards__card__descBox__desc__review__icon {
  font-size: 1.2rem !important;
  margin-right: 0.3rem;
}
.landingPage__container__cardsContainer1__cards__card__descBox__desc__review span {
  font-size: 0.8rem;
  color: #4E4E4E;
  font-weight: 600;
}
.landingPage__container__cardsContainer1__cards__card__descBox__desc__services {
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
}
.landingPage__container__cardsContainer1__cards__card__descBox__desc__services__icon {
  font-size: 1.2rem !important;
  margin-right: 0.3rem;
}
.landingPage__container__cardsContainer1__cards__card__descBox__desc__services span {
  font-size: 0.8rem;
  color: #4E4E4E;
  font-weight: 600;
}
.landingPage__container__cardsContainer1__cards__card__descBox__price {
  display: flex;
  flex-direction: column;
}
.landingPage__container__cardsContainer1__cards__card__descBox__price p {
  font-size: 0.7rem;
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 0;
}
.landingPage__container__cardsContainer1__cards__card__descBox__price span {
  color: #193D66;
  font-size: 1.5rem;
  font-weight: 800;
}
.landingPage__container__cardsContainer {
  margin: 1rem 0rem;
  width: 100%;
}
.landingPage__container__cardsContainer__viewButton {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.landingPage__container__cardsContainer__viewButton Button {
  background-color: #E6EAEE !important;
  color: #858585;
  font-weight: 800;
  border-radius: 1rem;
  font-size: 0.6rem;
  padding: 0.2rem 0.8rem;
}
.landingPage__container__cardsContainer__cards {
  display: flex;
  align-items: flex-start;
  overflow-y: auto;
  padding: 0.8rem 0;
  gap: 1rem;
}
.landingPage__container__cardsContainer__cards__card {
  margin-top: 1rem;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 15px lightgray;
  border-radius: 1rem;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  width: 330px;
}
@media (max-width: 700px) {
  .landingPage__container__cardsContainer__cards__card {
    width: 420px;
  }
}
@media (max-width: 520px) {
  .landingPage__container__cardsContainer__cards__card {
    width: 320px;
  }
}
@media (max-width: 420px) {
  .landingPage__container__cardsContainer__cards__card {
    width: 260px;
  }
}
@media (max-width: 320px) {
  .landingPage__container__cardsContainer__cards__card {
    width: 180px;
  }
}
.landingPage__container__cardsContainer__cards__card__imageBox {
  display: flex;
}
.landingPage__container__cardsContainer__cards__card__imageBox__image {
  width: 80px;
  height: 90px;
  object-fit: cover;
  border-radius: 0.5rem;
}
.landingPage__container__cardsContainer__cards__card__imageBox__details {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  width: 100%;
}
.landingPage__container__cardsContainer__cards__card__imageBox__details__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-all;
}
.landingPage__container__cardsContainer__cards__card__imageBox__details__container h4:hover {
  font-style: italic;
  text-decoration: underline !important;
  cursor: pointer;
}
.landingPage__container__cardsContainer__cards__card__imageBox__details__container p {
  font-weight: 900;
  font-size: 1.2rem;
}
.landingPage__container__cardsContainer__cards__card__imageBox__details__container__favIcon {
  color: #193D66;
}
.landingPage__container__cardsContainer__cards__card__imageBox__details__rating {
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
}
.landingPage__container__cardsContainer__cards__card__imageBox__details__rating__icon {
  color: #D7BD73;
  margin-left: 0.3rem;
}
.landingPage__container__cardsContainer__cards__card__imageBox__details__bio {
  margin-top: 0.2rem;
  color: #858585;
  font-size: 0.8rem;
  font-weight: 500;
}
.landingPage__container__cardsContainer__cards__card__services {
  border-top: 1px solid lightgray;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}
.landingPage__container__cardsContainer__cards__card__services h5 {
  color: #858585;
  font-size: 0.75rem;
  text-transform: capitalize;
  font-weight: 600;
  word-break: break-all;
}
.landingPage__container__cardsContainer__cards__card__services__offers {
  display: flex;
  color: #FFFFFF;
  font-size: 0.7rem;
  width: 100%;
  margin-top: 0.5rem;
  gap: 1rem;
  text-wrap: nowrap;
  overflow-x: scroll;
}
.landingPage__container__cardsContainer__cards__card__services__offers__offer {
  background-color: #193D66;
  border-radius: 1rem;
  padding: 0.2rem 1rem;
  margin-bottom: 0.3rem;
  font-weight: 500;
}
.landingPage__container__cardsContainer__cards__card__location {
  display: flex;
  align-items: flex-start;
  margin-top: 0.5rem;
  word-break: break-all;
}
.landingPage__container__cardsContainer__cards__card__location h5 {
  margin-bottom: 0;
  margin-left: 0.3rem;
  font-size: 0.8rem;
  font-weight: 600;
}
.landingPage__container__cardsContainer__cards__card__location__icon {
  font-size: 1rem !important;
}
.landingPage__container__cardsContainer__cards__card__descBox {
  border-top: 1px solid lightgray;
  margin-top: 0.8rem;
  padding-top: 0.8rem;
  display: flex;
  justify-content: space-between;
}
.landingPage__container__cardsContainer__cards__card__descBox__desc {
  display: flex;
  flex-direction: column;
}
.landingPage__container__cardsContainer__cards__card__descBox__desc__review {
  display: flex;
  align-items: center;
}
.landingPage__container__cardsContainer__cards__card__descBox__desc__review__icon {
  font-size: 1.2rem !important;
  margin-right: 0.3rem;
}
.landingPage__container__cardsContainer__cards__card__descBox__desc__review h5 {
  font-size: 0.8rem;
  color: #4E4E4E;
  font-weight: 600;
}
.landingPage__container__cardsContainer__cards__card__descBox__desc__services {
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
}
.landingPage__container__cardsContainer__cards__card__descBox__desc__services__icon {
  font-size: 1.2rem !important;
  margin-right: 0.3rem;
}
.landingPage__container__cardsContainer__cards__card__descBox__desc__services h5 {
  font-size: 0.8rem;
  color: #4E4E4E;
  font-weight: 600;
}
.landingPage__container__cardsContainer__cards__card__descBox__price {
  display: flex;
  flex-direction: column;
}
.landingPage__container__cardsContainer__cards__card__descBox__price h5 {
  font-size: 0.7rem;
  text-transform: capitalize;
  margin-bottom: 0;
  padding-bottom: 0;
}
.landingPage__container__cardsContainer__cards__card__descBox__price h5 {
  color: #193D66;
  font-size: 1.5rem;
  font-weight: 800;
  word-break: break-all;
}
@media (max-width: 1100px) {
  .landingPage__container__cardsContainer__cards__card__descBox__price h5 {
    font-size: small;
  }
}
.landingPage__imageCard {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.landingPage__imageCard__images {
  cursor: pointer;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 6px;
}
.landingPage__imageCard__images:hover {
  filter: brightness(70%) sepia(1) hue-rotate(180deg) saturate(200%);
}
.landingPage__imageCard__images__title {
  z-index: 1;
  width: 200px;
  position: relative;
  color: white !important;
  font-size: 14px;
  padding: 0.2rem;
  bottom: 1rem;
  background-color: #537198;
  text-wrap: noWrap;
}

.landingPageCarousel {
  display: flex !important;
  justify-content: space-around !important;
  align-items: flex-start !important;
  margin-top: 2rem !important;
  padding: 0rem 1rem 1.3rem 1rem !important;
}
.landingPageCarousel__search {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}
.landingPageCarousel__body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0 2rem;
}
.landingPageCarousel__body__left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}
.landingPageCarousel__body__left__image {
  width: 300px;
}
@media (max-width: 650px) {
  .landingPageCarousel__body__left__image {
    width: 100%;
  }
}
.landingPageCarousel__body__left__image img {
  height: 120px;
  border-radius: 0.5rem;
  object-fit: cover;
}
@media (max-width: 650px) {
  .landingPageCarousel__body__left__image img {
    height: auto;
  }
}
.landingPageCarousel__body__left__text {
  margin-top: 2rem;
  color: #FFFFFF;
  width: 80%;
}
@media (max-width: 768px) {
  .landingPageCarousel__body__left__text {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .landingPageCarousel__body__left__text {
    font-size: 0.85rem;
    line-height: 16px;
    font-weight: normal;
  }
}
.landingPageCarousel__body__left__text__text2 {
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .landingPageCarousel__body__left__text__text2 {
    display: none;
  }
}
.landingPageCarousel__body__right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.landingPageCarousel__body__right__image {
  width: 450px;
}
@media (max-width: 900px) {
  .landingPageCarousel__body__right__image {
    width: 350px;
  }
}
@media (max-width: 768px) {
  .landingPageCarousel__body__right__image {
    width: auto;
    margin-left: 0.5rem;
  }
}
.landingPageCarousel__body__right__image img {
  object-fit: cover;
  margin-top: 2rem;
}
@media (max-width: 768px) {
  .landingPageCarousel__body__right__image img {
    margin-top: 9rem;
  }
}
.landingPageCarousel__text2 {
  display: none;
}
@media (max-width: 768px) {
  .landingPageCarousel__text2 {
    display: block;
    color: #FFFFFF;
    margin-top: 1rem;
    padding: 0 1rem;
  }
}
@media (max-width: 500px) {
  .landingPageCarousel__text2 {
    font-size: 0.85rem;
    line-height: 16px;
    font-weight: normal;
  }
}

.landingPageCarousel2 {
  background-color: #193D66;
  height: 550px;
}
@media (max-width: 500px) {
  .landingPageCarousel2 {
    height: 500px;
  }
}
.landingPageCarousel2__search {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}
.landingPageCarousel2__body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0 2rem;
}
.landingPageCarousel2__body__left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}
.landingPageCarousel2__body__left__image {
  width: 300px;
}
@media (max-width: 650px) {
  .landingPageCarousel2__body__left__image {
    width: 100%;
  }
}
.landingPageCarousel2__body__left__image img {
  height: 120px;
  border-radius: 0.5rem;
  object-fit: cover;
}
@media (max-width: 650px) {
  .landingPageCarousel2__body__left__image img {
    height: auto;
  }
}
.landingPageCarousel2__body__left__text {
  margin-top: 2rem;
  color: #FFFFFF;
  width: 80%;
}
@media (max-width: 500px) {
  .landingPageCarousel2__body__left__text {
    font-size: 0.85rem;
    line-height: 16px;
    font-weight: normal;
  }
}
@media (max-width: 768px) {
  .landingPageCarousel2__body__left__text {
    width: 100%;
  }
}
.landingPageCarousel2__body__left__text__text2 {
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .landingPageCarousel2__body__left__text__text2 {
    display: none;
  }
}
.landingPageCarousel2__body__right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.landingPageCarousel2__body__right__image {
  width: 450px;
}
@media (max-width: 900px) {
  .landingPageCarousel2__body__right__image {
    width: 350px;
  }
}
.landingPageCarousel2__body__right__image img {
  object-fit: cover;
  margin-top: 9.9rem;
}
.landingPageCarousel2__body__right__image1 {
  width: 350px;
}
@media (max-width: 900px) {
  .landingPageCarousel2__body__right__image1 {
    width: 300px;
  }
}
@media (max-width: 768px) {
  .landingPageCarousel2__body__right__image1 {
    width: auto;
    margin-left: 0.5rem;
  }
}
.landingPageCarousel2__body__right__image1 img {
  object-fit: cover;
  height: 550px;
  margin-top: 2rem;
}
@media (max-width: 1390px) {
  .landingPageCarousel2__body__right__image1 img {
    margin-top: 1rem;
  }
}
@media (max-width: 768px) {
  .landingPageCarousel2__body__right__image1 img {
    height: 300px;
  }
}
.landingPageCarousel2__text2 {
  display: none;
  color: #FFFFFF;
  margin-top: 1rem;
  padding: 0 1rem;
}
@media (max-width: 768px) {
  .landingPageCarousel2__text2 {
    display: block;
  }
}
@media (max-width: 500px) {
  .landingPageCarousel2__text2 {
    font-size: 0.85rem;
    line-height: 16px;
    font-weight: normal;
  }
}

.landingPageCarousel3 {
  background-color: #313131;
  height: 550px;
}
@media (max-width: 500px) {
  .landingPageCarousel3 {
    height: 500px;
  }
}
.landingPageCarousel3__search {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}
.landingPageCarousel3__body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0 2rem;
}
.landingPageCarousel3__body__left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}
.landingPageCarousel3__body__left__image {
  width: 300px;
}
@media (max-width: 650px) {
  .landingPageCarousel3__body__left__image {
    width: 100%;
  }
}
.landingPageCarousel3__body__left__image img {
  height: 120px;
  border-radius: 0.5rem;
  object-fit: cover;
}
@media (max-width: 650px) {
  .landingPageCarousel3__body__left__image img {
    height: auto;
  }
}
.landingPageCarousel3__body__left__text {
  margin-top: 2rem;
  color: #FFFFFF;
  width: 80%;
}
@media (max-width: 768px) {
  .landingPageCarousel3__body__left__text {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .landingPageCarousel3__body__left__text {
    font-size: 0.85rem;
    line-height: 16px;
    font-weight: normal;
  }
}
.landingPageCarousel3__body__left__text__text2 {
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .landingPageCarousel3__body__left__text__text2 {
    display: none;
  }
}
.landingPageCarousel3__body__right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.landingPageCarousel3__body__right__image {
  width: 450px;
}
@media (max-width: 900px) {
  .landingPageCarousel3__body__right__image {
    width: 350px;
  }
}
.landingPageCarousel3__body__right__image img {
  object-fit: cover;
  margin-top: 9.9rem;
}
.landingPageCarousel3__body__right__image1 {
  width: 350px;
}
@media (max-width: 900px) {
  .landingPageCarousel3__body__right__image1 {
    width: 300px;
  }
}
@media (max-width: 768px) {
  .landingPageCarousel3__body__right__image1 {
    width: auto;
    margin-left: 0.5rem;
  }
}
.landingPageCarousel3__body__right__image1 img {
  object-fit: cover;
  height: 550px;
  margin-top: 2rem;
}
@media (max-width: 1390px) {
  .landingPageCarousel3__body__right__image1 img {
    margin-top: 1rem;
  }
}
@media (max-width: 768px) {
  .landingPageCarousel3__body__right__image1 img {
    height: 300px;
  }
}
.landingPageCarousel3__text2 {
  display: none;
  color: #FFFFFF;
  margin-top: 1rem;
  padding: 0 1rem;
}
@media (max-width: 768px) {
  .landingPageCarousel3__text2 {
    display: block;
  }
}
@media (max-width: 500px) {
  .landingPageCarousel3__text2 {
    font-size: 0.85rem;
    line-height: 16px;
    font-weight: normal;
  }
}

.massageTab {
  padding: 2rem;
}
@media (max-width: 768px) {
  .massageTab {
    padding: 1rem;
  }
}
.massageTab__filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 1250px) {
  .massageTab__filters {
    display: flex;
    flex-direction: column-reverse;
  }
}
.massageTab__filters__filter {
  display: flex;
  align-items: center;
  margin-left: 13%;
}
@media (max-width: 1250px) {
  .massageTab__filters__filter {
    margin-bottom: 1rem;
    margin-left: unset;
  }
}
.massageTab__filters__filter__sortButton {
  background-color: #FFFFFF !important;
  color: #193D66 !important;
  text-transform: capitalize !important;
  border-radius: 0.5rem !important;
  font-weight: 600 !important;
  font-size: 0.7rem !important;
  padding-left: 1.2rem !important;
  white-space: nowrap;
}
.massageTab__filters__filter__sortButton__icon {
  margin-left: 0.5rem;
  font-size: 1rem !important;
}
.massageTab__filters__filter__box {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.massageTab__filters__filter__box__icon {
  color: #193D66;
  margin-right: 0.3rem;
}
.massageTab__filters__filter__box p {
  font-weight: 600;
  font-size: 0.8rem;
  color: #193D66;
}
.massageTab__cards {
  padding: 1rem 0 !important;
  display: flex !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
}

.sortMenuHead {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0rem 0 1rem 0;
}
.sortMenuHead p {
  color: #313131;
  font-weight: 600;
}

.dot.selected {
  background-color: #193D66 !important;
  height: 15px !important;
  width: 15px !important;
}

.dot {
  height: 12px !important;
  width: 12px !important;
}

.carousel .carousel-status {
  display: none;
}

.css-1nww0z5-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root .MuiMenuItem-root {
  background-color: #193D66 !important;
  border-radius: 0.8rem !important;
}

.landingPage__container__cardsContainer__cards__card__services__offers::-webkit-scrollbar {
  height: 5px;
}

.landingPage__container__cardsContainer__cards__card__services__offers::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px white;
  border-radius: 10px;
}

.landingPage__container__cardsContainer__cards__card__services__offers::-webkit-scrollbar-thumb {
  background: #537198;
  border-radius: 10px;
}

.landingPage__container__cardsContainer__cards__card__services__offers::-webkit-scrollbar-thumb:hover {
  background: #537198;
}

.homeTab__container__cardsContainer__cards__card {
  margin-top: 1rem;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 15px lightgray;
  border-radius: 1rem;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  margin: 1rem;
}

.massageTab__container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
@media (max-width: 1100px) {
  .massageTab__container {
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  .massageTab__container {
    justify-content: center;
  }
}

.favourite {
  width: 100%;
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
}

.css-1tklgxf {
  margin-right: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.flex {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.skeleton {
  display: flex;
  background-color: #E5F3DC !important;
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .skeleton {
    justify-content: center;
  }
}

.landingPage__container__cardsContainer__cards__card__profileSection:hover {
  cursor: pointer;
  filter: blur(0.5px);
}

.landingPageCarousel {
  padding: 20px;
  width: 100%;
}

.landingPageCarousel__swiper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.landingPageCarousel__image {
  width: 100%;
  height: 250px; /* Set your desired height */
  margin: 0;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintains aspect ratio while filling container */
  display: block;
}

/* Swiper navigation styling */
.swiper-button-next,
.swiper-button-prev {
  color: #ffffff;
}

/* Swiper pagination styling */
.swiper-pagination-bullet {
  background: #ffffff;
  opacity: 0.7;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #87c85b;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .landingPageCarousel__image {
    height: 200px;
  }
}
@media (max-width: 768px) {
  .landingPageCarousel__image {
    height: 150px;
  }
}
.image-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.image-card {
  position: relative;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform, box-shadow 0.3s ease;
}
.image-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.image-card:hover .image-card__image {
  transform: scale(1.05);
}
.image-card__container {
  position: relative;
  width: 100%;
  padding-top: 125%;
}
.image-card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.image-card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  padding: 2rem 1rem 1rem;
  color: white;
}
.image-card__title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.3;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .image-card-grid {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }
  .image-card__title {
    font-size: 1.1rem;
  }
}
@media (max-width: 480px) {
  .image-card-grid {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }
  .image-card__title {
    font-size: 1rem;
  }
}
.authentication {
  height: 100%;
  min-height: 100vh;
}
.authentication__container {
  padding: 0rem 5rem;
  padding-top: 3rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 1250px) {
  .authentication__container {
    padding: 0rem 3rem;
    padding: 3rem;
  }
}
@media (max-width: 900px) {
  .authentication__container {
    padding: 0rem 1rem;
    padding-top: 3rem;
  }
}
@media (max-width: 768px) {
  .authentication__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-top: 3rem;
  }
}
.authentication__container__left {
  width: 55%;
}
@media (max-width: 768px) {
  .authentication__container__left {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}
.authentication__container__left__logo {
  display: flex;
  justify-content: center;
  width: 100%;
}
.authentication__container__left__logo__logoImage {
  width: 300px;
  object-fit: cover;
  cursor: pointer;
}
@media (max-width: 768px) {
  .authentication__container__left__logo__logoImage {
    width: 200px;
  }
}
.authentication__container__left__box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.authentication__container__left__box__image {
  width: 540px;
  object-fit: cover;
  margin-top: 7.2rem;
  transition: width 0.5s;
}
@media (max-width: 1250px) {
  .authentication__container__left__box__image {
    margin-top: 7.8rem;
  }
}
@media (max-width: 1100px) {
  .authentication__container__left__box__image {
    width: 450px;
    margin-top: 12.2rem;
  }
}
@media (max-width: 900px) {
  .authentication__container__left__box__image {
    width: 400px;
    margin-top: 14.2rem;
  }
}
@media (max-width: 768px) {
  .authentication__container__left__box__image {
    width: 320px;
    margin-top: 5rem;
    margin-left: 1.5rem;
  }
}
.authentication__container__left__box__image1 {
  width: 540px;
  object-fit: cover;
  margin-top: 14.5rem;
  transition: width 0.5s;
}
@media (max-width: 1250px) {
  .authentication__container__left__box__image1 {
    margin-top: 14.8rem;
  }
}
@media (max-width: 1100px) {
  .authentication__container__left__box__image1 {
    width: 450px;
    margin-top: 19rem;
  }
}
@media (max-width: 1025px) {
  .authentication__container__left__box__image1 {
    width: 400px;
    margin-top: 22.7rem;
  }
}
@media (max-width: 900px) {
  .authentication__container__left__box__image1 {
    width: 400px;
    margin-top: 20.9rem;
  }
}
@media (max-width: 768px) {
  .authentication__container__left__box__image1 {
    width: 320px;
    margin-top: 5rem;
    margin-left: 1.5rem;
  }
}
.authentication__container__left__stripe {
  background-color: #E6EAEE;
  width: 100%;
  height: 100px;
  margin-top: -100px;
}
.authentication__container__right {
  width: 45%;
  box-shadow: 1px 1px 15px lightgray;
  border-radius: 1rem;
  background-color: #FFFFFF;
  padding: 1.5rem 0 2rem 0;
}
@media (max-width: 768px) {
  .authentication__container__right {
    width: 100%;
    box-shadow: unset;
  }
}
.authentication__container__right__providerSignIn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0 0 0;
}
.authentication__container__right__providerSignIn__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}
@media (max-width: 900px) {
  .authentication__container__right__providerSignIn__box {
    width: 90%;
  }
}
.authentication__container__right__providerSignIn__box__remember {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.authentication__container__right__providerSignIn__box__remember__me {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.authentication__container__right__providerSignIn__box__remember__me p {
  color: #193D66;
  font-size: 0.9rem;
  margin-left: 0.5rem;
}
.authentication__container__right__providerSignIn__box__remember__me__icon {
  color: #87C85B;
  font-size: 1.8rem !important;
  background-color: #193D66;
  border-radius: 50%;
}
.authentication__container__right__providerSignIn__box__remember__forgotPassword {
  color: #87C85B;
  font-size: 0.9rem;
  text-align: right;
  white-space: nowrap;
}
.authentication__container__right__providerSignIn__box__signInButton {
  background-color: #193D66 !important;
  text-transform: capitalize !important;
  width: 90% !important;
  margin: 2rem 0 0 0 !important;
  border-radius: 0.5rem !important;
  font-size: 1.2rem !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}
.authentication__container__right__providerSignIn__continue {
  margin-top: 2rem;
  font-size: 0.9rem;
  font-weight: 500;
}
.authentication__container__right__providerSignIn__signInLinks {
  display: flex;
  align-items: center;
  margin: 1rem;
}
.authentication__container__right__providerSignIn__signInLinks__image img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 1rem 1rem;
  border-radius: 1rem;
  box-shadow: 1px 1px 15px lightgray;
}
.authentication__container__right__providerSignIn__signUpButton {
  border-top: 1px solid lightgray;
  padding-top: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}
@media (max-width: 1190px) {
  .authentication__container__right__providerSignIn__signUpButton {
    justify-content: center;
    gap: 1rem;
  }
}
.authentication__container__right__providerSignIn__signUpButton p {
  color: #858585;
  font-size: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.authentication__container__right__providerSignIn__signUpButton p b {
  color: black;
  font-weight: 800 !important;
}
.authentication__stripe {
  background-color: #E6EAEE;
  width: 100%;
  height: 180px;
  margin-top: -220px;
  z-index: -999999;
}
@media (max-width: 1250px) {
  .authentication__stripe {
    margin-top: -260px;
  }
}
@media (max-width: 1100px) {
  .authentication__stripe {
    height: 150px;
    margin-top: -230px;
  }
}
@media (max-width: 900px) {
  .authentication__stripe {
    height: 150px;
    margin-top: -190px;
  }
}
.authentication__stripe1 {
  background-color: #E6EAEE;
  width: 100%;
  height: 180px;
  margin-top: -220px;
  z-index: -999999;
}
@media (max-width: 1250px) {
  .authentication__stripe1 {
    margin-top: -260px;
  }
}
@media (max-width: 1100px) {
  .authentication__stripe1 {
    height: 150px;
    margin-top: -230px;
  }
}
@media (max-width: 925px) {
  .authentication__stripe1 {
    height: 140px;
    margin-top: -220px;
  }
}
@media (max-width: 900px) {
  .authentication__stripe1 {
    height: 140px;
    margin-top: -180px;
  }
}

.providerSignUp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0 0 0;
}
.providerSignUp__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}
@media (max-width: 900px) {
  .providerSignUp__container {
    width: 90%;
  }
}
.providerSignUp__container__nameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.providerSignUp__container__nameContainer__1st {
  width: 49%;
}
.providerSignUp__container__nameContainer__last {
  width: 49%;
}
.providerSignUp__container__terms {
  display: flex;
  align-items: center;
}
.providerSignUp__container__terms__icon {
  background-color: #193D66 !important;
  color: #87C85B !important;
  border-radius: 50%;
  margin-right: 1rem;
}
.providerSignUp__container__terms__text {
  font-size: 0.9rem;
  padding-bottom: 0;
}
.providerSignUp__container__terms__text b {
  font-size: 0.9rem;
}
.providerSignUp__container__condition {
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}
.providerSignUp__container__signUpButton {
  margin-top: 1.5rem !important;
  background-color: #193D66 !important;
  text-transform: capitalize !important;
  width: 90% !important;
  border-radius: 0.5rem !important;
  font-size: 1.2rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.providerSignUp__signIn {
  border-top: 1px solid lightgray;
  padding-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}
.providerSignUp__signIn p {
  color: #858585;
  font-size: 1rem;
}
.providerSignUp__signIn p b {
  color: black;
  font-weight: 800 !important;
}

.passwordErrorMessage {
  font-size: 13px !important;
  color: red;
  margin-bottom: 1rem;
  width: 100%;
}

.language__selector {
  width: 190px !important;
  margin-right: 3rem !important;
  position: relative !important;
  left: 1rem !important;
}
@media (max-width: 1190px) {
  .language__selector {
    width: 100% !important;
  }
}

.profile {
  height: 100%;
  min-height: 100vh;
}
.profile__container {
  padding: 0rem 5rem;
  padding-top: 3rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 1250px) {
  .profile__container {
    padding: 0rem 3rem;
    padding: 3rem;
  }
}
@media (max-width: 900px) {
  .profile__container {
    padding: 0rem 1rem;
    padding-top: 3rem;
  }
}
@media (max-width: 768px) {
  .profile__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-top: 3rem;
  }
}
.profile__container__left {
  width: 55%;
}
@media (max-width: 768px) {
  .profile__container__left {
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}
.profile__container__left__logo {
  display: flex;
  justify-content: center;
  width: 100%;
}
.profile__container__left__logo__logoImage {
  width: 300px;
  object-fit: cover;
  cursor: pointer;
}
@media (max-width: 768px) {
  .profile__container__left__logo__logoImage {
    width: 200px;
  }
}
.profile__container__left__box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.profile__container__left__box__image1 {
  width: 540px;
  object-fit: cover;
  margin-top: 7.3rem;
  transition: width 0.5s;
}
@media (max-width: 1250px) {
  .profile__container__left__box__image1 {
    margin-top: 7.8rem;
  }
}
@media (max-width: 1100px) {
  .profile__container__left__box__image1 {
    width: 450px;
    margin-top: 11.9rem;
  }
}
@media (max-width: 1000px) {
  .profile__container__left__box__image1 {
    width: 400px;
    margin-top: 14.8rem;
  }
}
@media (max-width: 925px) {
  .profile__container__left__box__image1 {
    width: 400px;
    margin-top: 15.5rem;
  }
}
@media (max-width: 900px) {
  .profile__container__left__box__image1 {
    width: 400px;
    margin-top: 13.8rem;
  }
}
@media (max-width: 768px) {
  .profile__container__left__box__image1 {
    width: 320px;
    margin-top: 5rem;
    margin-left: 1.5rem;
  }
}
.profile__container__left__stripe {
  background-color: #E6EAEE;
  width: 100%;
  height: 100px;
  margin-top: -100px;
}
.profile__container__right {
  width: 45%;
  box-shadow: 1px 1px 15px lightgray;
  border-radius: 1rem;
  z-index: 9999999 !important;
  background-color: #FFFFFF;
  padding: 1.5rem 0 2rem 0;
}
@media (max-width: 768px) {
  .profile__container__right {
    width: 100%;
    box-shadow: unset;
  }
}
.profile__container__right__profileDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0 0 0;
}
.profile__container__right__profileDetails__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}
@media (max-width: 900px) {
  .profile__container__right__profileDetails__container {
    width: 90%;
  }
}
.profile__container__right__profileDetails__container__imageUpload__label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile__container__right__profileDetails__container__imageUpload__label__icon {
  font-size: 2rem !important;
  color: #FFFFFF !important;
  position: absolute;
  margin-top: -0.2rem;
}
.profile__container__right__profileDetails__container__imageUpload__label img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  background-color: #E6EAEE;
  z-index: -1;
}
.profile__container__right__profileDetails__container__imageUpload__input {
  visibility: hidden;
}
.profile__container__right__profileDetails__container__addPicture {
  margin-top: -1rem;
  font-weight: bolder !important;
  text-transform: capitalize;
  padding-bottom: 1rem;
  border-bottom: 1px solid lightgray;
  width: 100%;
  text-align: center;
}
.profile__container__right__profileDetails__container__address {
  font-weight: bolder;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
}
.profile__container__right__profileDetails__container__cityState {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.profile__container__right__profileDetails__container__cityState__city {
  width: 49%;
}
.profile__container__right__profileDetails__container__cityState__state {
  width: 49%;
}
.profile__container__right__profileDetails__container__continueButton {
  background-color: #193D66 !important;
  text-transform: capitalize !important;
  width: 90% !important;
  margin: 2rem 0 0 0 !important;
  border-radius: 0.5rem !important;
  font-size: 1rem !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  margin-bottom: 1rem !important;
}
.profile__stripe1 {
  background-color: #E6EAEE;
  width: 100%;
  height: 180px;
  margin-top: -220px;
  z-index: -999999;
}
@media (max-width: 1250px) {
  .profile__stripe1 {
    margin-top: -260px;
  }
}
@media (max-width: 1100px) {
  .profile__stripe1 {
    height: 150px;
    margin-top: -230px;
  }
}
@media (max-width: 1000px) {
  .profile__stripe1 {
    height: 140px;
    margin-top: -230px;
  }
}
@media (max-width: 925px) {
  .profile__stripe1 {
    height: 140px;
    margin-top: -220px;
  }
}
@media (max-width: 900px) {
  .profile__stripe1 {
    height: 140px;
    margin-top: -180px;
  }
}

.verificationCode {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 1px 1px 15px lightgray;
  padding: 3rem 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
}
.verificationCode__resend {
  width: 140px;
  display: flex;
  justify-content: end;
}
.verificationCode__resend span {
  width: 20px;
}
.verificationCode__iconBox {
  border-top: 2px solid lightgray;
  padding-top: 1.5rem;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.verificationCode__iconBox__phoneIcon {
  color: #87C85B;
  background-color: #D8EDCA;
  font-size: 3rem !important;
  border-radius: 50%;
  padding: 0.5rem;
}
.verificationCode h3 {
  margin: 1rem 0;
  font-weight: 900 !important;
}
.verificationCode p {
  margin: 1rem 0 0 0;
  color: #858585;
  font-size: 0.9rem;
  text-align: center;
}
.verificationCode input {
  margin: 1.5rem 0;
  outline: unset;
  border: unset;
  border-bottom: 2px solid #87C85B;
  width: 100%;
  color: black;
  font-size: 3rem;
  font-weight: 900;
  text-align: center;
}
.verificationCode__verifyButton {
  width: 80%;
}

.successMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 1px 1px 15px lightgray;
  padding: 3rem 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 1px 1px 15px lightgray;
  padding: 3rem 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
}
.successMessage__iconBox {
  border-top: 2px solid lightgray;
  padding-top: 1rem;
  width: 70%;
}
.successMessage h3 {
  margin: 1rem 0;
  font-weight: 900 !important;
  text-transform: capitalize;
}
.successMessage img {
  object-fit: cover;
  width: 60px;
}
.successMessage p {
  margin: 1rem 0 1rem 0;
  font-size: 0.9rem;
  text-align: center;
}
.successMessage__verifyButton {
  width: 80%;
}
.successMessage__iconBox {
  border-top: 2px solid lightgray;
  padding-top: 1.5rem;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.successMessage__iconBox__phoneIcon {
  color: #87C85B;
  background-color: #D8EDCA;
  font-size: 3rem !important;
  border-radius: 50%;
  padding: 0.5rem;
}
.successMessage h3 {
  margin: 1rem 0;
  font-weight: 900 !important;
}
.successMessage p {
  margin: 1rem 0 1rem 0;
  font-size: 0.9rem;
  text-align: center;
}
.successMessage input {
  margin: 1.5rem 0;
  outline: unset;
  border: unset;
  border-bottom: 2px solid #87C85B;
  width: 100%;
  color: black;
  font-size: 3rem;
  font-weight: 900;
  text-align: center;
}
.successMessage__verifyButton {
  width: 80%;
}

.review__container {
  background-color: #FFFFFF;
  box-shadow: 1px 1px 15px lightgray;
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
}
@media (max-width: 600px) {
  .review__container {
    box-shadow: unset;
    padding: 2rem;
    margin-top: 1rem;
  }
}
.review__container__title {
  font-weight: 600;
  font-size: 1.5rem;
  color: #313131;
  text-transform: capitalize;
}
.review__container__profileImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 0.5rem;
  margin: 0.8rem 0;
}
.review__container__name {
  font-weight: 900;
  text-transform: capitalize;
}
.review__container__services {
  display: flex;
  color: #FFFFFF;
  font-size: 0.7rem;
  width: 100%;
  margin-top: 0.5rem;
  gap: 1rem;
  text-wrap: nowrap;
  overflow-x: scroll;
  justify-content: center;
}
.review__container__services__offers {
  display: flex;
  justify-content: space-around;
  margin: 0.8rem 0;
}
.review__container__services__offers span {
  background-color: #193D66;
  font-size: 0.8rem;
  color: #E6EAEE;
  border-radius: 1rem;
  margin-right: 0.3rem;
  padding: 0.2rem 1rem;
}
.review__container__ratings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0;
}
.review__container__ratings p {
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}
.review__container__review {
  width: 100%;
  border-radius: 0.5rem;
  border: 2px solid #87C85B;
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0.5rem 0.5rem 0.5rem;
  margin-top: 1rem;
}
.review__container__review p {
  font-size: 0.8rem;
  color: #4E4E4E;
  font-weight: 500;
}
.review__container__review textarea {
  outline: unset;
  border: unset;
}
.review__container__postButton {
  text-transform: capitalize !important;
  background-color: #193D66 !important;
  border-radius: 0.5rem !important;
  margin-top: 2rem !important;
  font-size: 0.8rem !important;
  width: 80% !important;
  padding-top: 0.3Comprem !important;
  padding-bottom: 0.3Comprem !important;
}

.editProfile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.editProfile__container {
  background-color: #FFFFFF;
  box-shadow: 1px 1px 15px lightgray;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
  max-width: 400px;
}
@media (max-width: 600px) {
  .editProfile__container {
    box-shadow: unset;
    padding: unset;
  }
}
.editProfile__container__imageBox {
  margin-top: 2rem;
}
.editProfile__container__imageBox__label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.editProfile__container__imageBox__label img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 50%;
  background-color: #4E4E4E;
}
.editProfile__container__imageBox__label__icon {
  color: #E6EAEE;
  margin-bottom: -3.5rem;
  z-index: 1;
}
.editProfile__container__imageBox input {
  visibility: hidden;
}
.editProfile__container__editProfile {
  font-weight: 900;
  text-transform: capitalize;
}
.editProfile__container__nameBox {
  border-top: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}
.editProfile__container__nameBox div {
  width: 98%;
}
.editProfile__container__userIcon {
  background-color: #D8EDCA;
  color: #87C85B;
  border-radius: 50% !important;
  font-weight: bolder !important;
}
.editProfile__container__saveButton {
  text-transform: capitalize !important;
  background-color: #193D66 !important;
  border-radius: 0.5rem !important;
  margin-top: 2rem !important;
  font-size: 0.8rem !important;
  width: 100% !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  color: white !important;
}

.passwordChange {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.passwordChange__container {
  background-color: #FFFFFF;
  box-shadow: 1px 1px 15px lightgray;
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 1rem;
  min-width: 400px;
}
@media (max-width: 600px) {
  .passwordChange__container {
    box-shadow: unset;
    padding: unset;
  }
}
.passwordChange__container__saveButton {
  text-transform: capitalize !important;
  background-color: #193D66 !important;
  border-radius: 0.5rem !important;
  margin-top: 2rem !important;
  font-size: 0.8rem !important;
  width: 100% !important;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  color: white !important;
}
.passwordChange__container__passChange {
  font-weight: 800;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.clientPorfile {
  display: flex;
  margin-top: 5rem;
  animation: fadeIn 1s ease-in-out;
  margin-bottom: 5rem;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.clientPorfile__profile {
  width: 35%;
}
.clientPorfile__profile__image {
  display: flex;
  width: 100%;
}
.clientPorfile__profile__image__box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.clientPorfile__profile__image img {
  width: 60%;
  box-shadow: 3px 4px 7px rgba(0, 0, 0, 0.2901960784);
  object-fit: cover;
  margin-top: 2rem;
  border-radius: 1rem;
}
.clientPorfile__profile__image__heartIcon {
  display: none;
}
.clientPorfile__profile__name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
}
.clientPorfile__profile__name__title {
  width: 55%;
  text-align: center;
  font: normal normal bold 35px/30px Yu Gothic;
}
.clientPorfile__profile__name__heartIcon {
  color: #193D66;
  display: flex;
}
.clientPorfile__profile__name__messageIcon {
  display: none;
}
.clientPorfile__profile__rating {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  margin-top: 0.5rem;
}
.clientPorfile__profile__rating__number {
  color: #4E4E4E;
  font: normal normal normal 22px/10px Yu Gothic;
  margin-right: 0.2rem;
  letter-spacing: 0px;
}
.clientPorfile__profile__rating__starIcon {
  color: #dbc37a;
  position: relative;
  top: 0.1rem;
  margin-right: 0.6rem;
  margin-left: 0.3rem;
}
.clientPorfile__profile__information {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
}
.clientPorfile__profile__information__text {
  font: normal normal normal 17px/15px Poppins;
  color: #4E4E4E;
  letter-spacing: 0px;
  opacity: 0.8;
  margin-right: 0.7rem;
  height: 50px;
}
.clientPorfile__clientPortfolio {
  width: 55%;
}
.clientPorfile__messageIcon {
  width: 5%;
  margin-top: 3rem;
  margin-left: 0.3rem;
  cursor: pointer;
  height: 5vh;
  color: #193D66;
  display: none;
}

.profile__information {
  flex-wrap: wrap;
  animation: fadeIn 1s ease-in-out;
  width: 110%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.profile__information__container__one {
  width: auto;
}
.profile__information__container__one img {
  width: 230px;
  height: 240px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2901960784);
  object-fit: cover;
  border-radius: 1rem;
}

.about {
  padding: 1rem;
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.about__email__container {
  display: flex;
  margin-top: 1rem;
}
.about__email__container__icon {
  color: #4E4E4E;
}
.about__email__container__name {
  margin-top: 0.2rem;
  margin-left: 1rem;
  font: normal normal normal 19px/20px Poppins;
  color: #4E4E4E;
}
.about__contact__container {
  display: flex;
  margin-top: 1.3rem;
}
.about__contact__container__callIcon {
  color: #4E4E4E;
  margin-top: 0.2rem;
}
.about__contact__container__country__image {
  margin-left: 1rem;
}
.about__contact__container__phoneNumber {
  color: #4E4E4E;
  margin-left: 0.4rem;
  font: normal normal normal 18px/30px Poppins;
}
.about__locationInformation {
  display: flex;
  margin-top: 1.2rem;
}
.about__locationInformation__cityIcon {
  color: #4E4E4E;
}
.about__locationInformation__text {
  font: normal normal normal 18px/27px Poppins;
  margin-left: 1rem;
  color: #4E4E4E;
}
.about__container {
  margin-top: 1.5rem;
}
.about__container__title {
  font-size: 23px;
  color: #4E4E4E;
}
.about__container__paragraph {
  text-align: justify;
  margin-top: 0.8rem;
  font: normal normal normal 17px/25px Poppins;
  color: #4E4E4E;
}

.service {
  margin-top: 0rem;
  width: 100% !important;
  transition: all 1s;
  animation: fadeIn 1s ease-in-out;
  height: 60vh;
  overflow-y: scroll;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.service__card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
}
@media only screen and (max-width: 750px) {
  .service__card {
    gap: 0rem;
    justify-content: center;
  }
}
@media only screen and (max-width: 355px) {
  .service__card {
    padding: 0rem;
  }
}
.service__boxContainer {
  height: 40%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2901960784);
  width: 100%;
  border-radius: 2rem;
  cursor: pointer;
}
.service__boxContainer img {
  width: 100%;
  padding: 0.2rem 0.2rem;
  height: 30vh;
  object-fit: cover;
  border-radius: 35px 35px 0px 0px;
}
.service__boxContainer__title {
  display: flex;
  justify-content: center;
  color: #000000;
  font-size: 1.2rem;
}
.service__boxContainer__rating {
  display: flex;
  align-items: center;
  justify-content: center;
}
.service__boxContainer__rating__title {
  color: #000000;
  font: normal normal normal 18px/29px Yu Gothic;
}
.service__boxContainer__rating__starIcon {
  margin-top: 0.1rem;
  color: #dbc37a;
  margin-left: 0.1rem;
}
.service__boxsecondContainer {
  height: 40%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2901960784);
  width: 100%;
  border-radius: 2rem;
}
.service__boxsecondContainer img {
  width: 100%;
  padding: 0.2rem 0.2rem;
  height: 30vh;
  object-fit: cover;
  border-radius: 35px 35px 0px 0px;
}
.service__boxthirdContainer {
  height: 40%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2901960784);
  width: 100%;
  border-radius: 2rem;
}
.service__boxthirdContainer img {
  width: 100%;
  padding: 0.2rem 0.2rem;
  height: 30vh;
  object-fit: cover;
  border-radius: 35pnx 35px 0px 0px;
}
.service__buttonsBox__register {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 106%;
}
.service__buttonsBox__register__button {
  background-color: #193D66 !important;
  width: 250px !important;
  columns: #FFFFFF !important;
  padding: 0.6rem 2rem !important;
  border-radius: 0.5rem !important;
  margin-top: 3rem !important;
}

.service__tab {
  transition: all 1s;
  width: 31%;
}

.review__tab {
  transition: all 1s;
  width: 31%;
}

.about__tab {
  width: 34%;
  transition: all 1s;
}

.service::-webkit-scrollbar {
  width: 5px;
}

.service::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

.service::-webkit-scrollbar-thumb {
  background: #fafafa;
  border-radius: 10px;
}

.service::-webkit-scrollbar-thumb:hover {
  background: #fafafa;
}

.tab__mainconatiner {
  width: 100% !important;
  padding: 1.5rem !important;
}
.tab__mainconatiner__box {
  border: 1px solid #e5f3dc !important;
  background-color: #e5f3dc !important;
  border-radius: 50px !important;
  margin-top: 0.5rem !important;
}

@media only screen and (max-width: 1070px) {
  .clientPorfile {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 25px;
  }
  .clientPorfile__profile {
    width: 100%;
  }
  .clientPorfile__profile__image {
    display: flex;
  }
  .clientPorfile__profile__image__box {
    width: 95%;
    padding: 0 2rem;
    margin: 0 3rem;
    margin-right: 2rem;
  }
  .clientPorfile__profile__image img {
    width: 100%;
  }
  .clientPorfile__profile__image__heartIcon {
    width: 6%;
    display: block;
    color: #193D66;
    margin-top: 2rem;
    position: relative;
    right: 0.8rem;
  }
  .clientPorfile__profile__name__heartIcon {
    display: none;
  }
  .clientPorfile__profile__name__title {
    width: 50%;
    font: normal normal bold 50px/30px Yu Gothic;
  }
  .clientPorfile__profile__name__messageIcon {
    color: #193D66;
    display: flex;
  }
  .clientPorfile__profile__rating__number {
    font: normal normal normal 30px/40px Yu Gothic;
    margin-top: 0.5rem;
  }
  .clientPorfile__profile__rating__starIcon {
    position: relative;
    top: 0.3rem;
  }
  .clientPorfile__profile__information__text {
    font: normal normal normal 22px/15px Poppins;
    margin-right: 1.6rem;
  }
  .clientPorfile__clientPortfolio {
    width: 100%;
  }
  .clientPorfile__messageIcon {
    display: none;
  }
  .profile__information {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
  .profile__information__container__one {
    width: auto;
  }
  .tab__mainconatiner {
    width: 100% !important;
  }
  .about__locationInformation__text {
    font: normal normal normal 16px/27px Poppins;
  }
  .about__container__paragraph {
    width: 100%;
  }
  .service {
    display: flex;
    width: 100% !important;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 0rem;
    position: relative;
    right: 0 !important;
  }
  .service__boxContainer {
    height: 100% !important;
    width: 50%;
  }
  .service__boxContainer__image {
    width: 100%;
  }
  .service__boxContainer__image img {
    width: 100%;
    height: 100%;
  }
  .service__boxsecondContainer {
    height: 100% !important;
    width: 50% !important;
    position: relative;
    left: 1rem;
    text-align: center;
  }
  .service__buttonsBox__register {
    display: none !important;
  }
  .service > :nth-child(3) {
    margin-top: 1rem;
  }
  .review {
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
    height: 90vh;
  }
  .review__boxContainer {
    width: 90%;
    position: relative;
    right: 0rem;
  }
  .review__boxContainer__paragraph {
    height: 85px;
  }
}
@media only screen and (max-width: 560px) {
  .clientPorfile {
    display: flex;
    flex-wrap: wrap;
  }
  .clientPorfile__profile {
    width: 100%;
  }
  .clientPorfile__profile__image {
    display: flex;
  }
  .clientPorfile__profile__image__box {
    width: 95%;
    padding: 0 2rem;
    margin: 0 3rem;
    margin-right: 2rem;
  }
  .clientPorfile__profile__image img {
    width: 100%;
  }
  .clientPorfile__profile__image__heartIcon {
    width: 6%;
    display: block;
    color: #193D66;
    margin-top: 2rem;
    position: relative;
    right: 1.2rem;
  }
  .clientPorfile__profile__name__title {
    width: 60%;
    font: normal normal bold 40px/20px Yu Gothic;
  }
  .clientPorfile__profile__rating__number {
    font: normal normal normal 20px/20px Yu Gothic;
    margin-top: 0.5rem;
  }
  .clientPorfile__profile__information__text {
    font: normal normal normal 18px/15px Poppins;
    margin-right: 0.5rem;
  }
  .clientPorfile__clientPortfolio {
    width: 100%;
  }
  .clientPorfile .tab__mainconatiner {
    width: 100% !important;
  }
  .profile__information {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
  .profile__information__container__one {
    width: auto;
  }
  .review__boxContainer {
    position: relative;
    right: 0rem;
    width: 100%;
  }
  .review__boxContainer__rating__profile__image img {
    width: 50px;
    height: 50px;
  }
  .review__boxContainer__rating__profile__information {
    margin-left: 0.5rem;
  }
  .review__boxContainer__rating__profile__information__name {
    font: normal normal bold 20px/10px Yu Gothic;
  }
  .review__boxContainer__rating__profile__information__point__number {
    font: normal normal normal 15px/37px Roboto;
  }
  .review__boxContainer__rating__profile__information__point__iconbox {
    font: normal normal normal 15px/37px Roboto;
  }
  .review__boxContainer__rating__days {
    font: normal normal normal 11px/63px Roboto;
  }
  .review__boxContainer__paragraph {
    height: 90px;
    font: normal normal normal 14px Roboto;
  }
  .portfolio__tab {
    width: 1% !important;
  }
}
@media only screen and (max-width: 420px) {
  .clientPorfile {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .clientPorfile__profile {
    width: 100%;
    margin-left: 0.6rem;
  }
  .clientPorfile__profile__image {
    display: flex;
  }
  .clientPorfile__profile__image__box {
    width: 100%;
  }
  .clientPorfile__profile__image__box img {
    width: 200;
  }
  .clientPorfile__profile__name__title {
    width: 50%;
    font: normal normal bold 25px/0px Yu Gothic;
  }
  .clientPorfile__profile__rating__number {
    font: normal normal normal 20px/10px Yu Gothic;
    margin-top: 0.5rem;
  }
  .clientPorfile__profile__information__text {
    font: normal normal normal 18px/25px Poppins;
    margin-left: 0.6rem;
  }
  .clientPorfile .about__tab {
    width: 50% !important;
  }
  .clientPorfile .service__tab {
    width: 50% !important;
  }
  .clientPorfile .review__tab {
    width: 50% !important;
  }
  .clientPorfile .portfolio__tab {
    width: 50% !important;
  }
  .review__boxContainer__rating__profile__image img {
    width: 30px;
    height: 30px;
  }
  .review__boxContainer__rating__profile__information__name {
    font: normal normal bold 13px/0px Yu Gothic;
    position: relative;
    bottom: 0.5rem;
  }
  .review__boxContainer__rating__profile__information__point__number {
    font: normal normal normal 12px/20px Roboto;
  }
  .review__boxContainer__rating__profile__information__point__iconbox {
    font: normal normal normal 0px/20px Roboto;
    position: relative;
    bottom: 0.34rem;
    width: 85%;
  }
  .review__boxContainer__rating__days {
    font: normal normal normal 8px/20px Roboto;
  }
  .review__boxContainer__paragraph {
    font: normal normal normal 12px Roboto;
  }
}
@media only screen and (max-width: 370px) {
  .tab__mainconatiner {
    width: 100% !important;
    padding: 1rem !important;
  }
}
.css-19kzrtu {
  padding: 0rem !important;
}

.chat {
  display: flex;
}

.chatSidebar {
  width: 35%;
}

.messages {
  width: 65%;
}

.chat__sidebar {
  padding: 2rem;
  width: 100%;
  height: 100%;
}
.chat__sidebar__searchContainer {
  display: flex;
  width: 93%;
  padding: 0.5rem 1rem;
  background: #FFFFFF 0% 0% no-epeat padding-box;
  border: 2px solid #7FC550;
  border-radius: 10px;
  outline: none;
  color: #717171;
  align-items: center;
  font-size: 17px;
}
.chat__sidebar__searchContainer__textfield {
  width: 100%;
}
.chat__sidebar__searchContainer__textfield input {
  margin-left: 0.3rem;
  width: 100%;
  outline: unset;
  border: unset;
  margin-right: 0.5rem;
  color: #717171;
}
.chat__sidebar__searchContainer__textfield__searchIcon {
  color: #717171;
  margin-top: 0.2rem;
}
.chat__sidebar__title {
  text-align: center;
  margin-top: 0.5rem;
  font: normal normal normal 30px/55px Poppins;
  color: #2597f6;
  opacity: 1;
}
.chat__sidebar__responsivetitlebar {
  display: none;
}
.chat__sidebar__border {
  width: 93%;
  border-bottom: 1px solid #e7e7e7;
}
.chat__sidebar__scrollcontainer {
  height: 90vh;
  overflow: auto;
  width: 100%;
}
.chat__sidebar__messageContainer {
  cursor: pointer;
  display: flex;
  margin-top: 1.5rem;
  margin-left: 0.3rem;
  justify-content: space-between;
  width: 93%;
}
.chat__sidebar__messageContainer__image img {
  width: 50px;
}
.chat__sidebar__messageContainer__subContainer {
  margin-left: 1rem;
  width: 80%;
}
.chat__sidebar__messageContainer__subContainer__name {
  font: normal normal bold 12px/20px Yu Gothic;
  color: #000000;
}
.chat__sidebar__messageContainer__subContainer__message {
  width: 65%;
}
.chat__sidebar__messageContainer__subContainer__message__paragraph {
  color: #000000;
  font-size: 13px;
}
.chat__sidebar__messageContainer__subContainer__message__days {
  width: 20%;
  margin-top: 2.5rem;
  font: normal normal normal 11px/23px Roboto;
  color: #000000;
  opacity: 0.44;
}

.message {
  padding: 2rem;
}
.message__profileContainer {
  display: flex;
  align-items: center;
}
.message__profileContainer__icon {
  color: #193D66;
  cursor: pointer;
}
.message__profileContainer__image {
  margin-left: 1.5rem;
}
.message__profileContainer__image img {
  width: 50px;
}
.message__profileContainer__namecontainer {
  margin-left: 0.8rem;
}
.message__profileContainer__namecontainer__name {
  font: normal normal bold 14px/20px Yu Gothic;
  color: #000000;
}
.message__profileContainer__namecontainer__lastseen {
  text-align: left;
  font: normal normal normal 12px/20px Yu Gothic;
  color: #000000;
  opacity: 0.3;
}
.message__receiver__paragraph {
  padding: 0.3rem;
  display: inline-block;
  background: #193D66 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  width: auto;
  align-items: center;
}
.message__receiver__paragraph p {
  font: normal normal normal 12px/29px Roboto;
  color: #FFFFFF;
}
.message__receiver__timeStamp {
  font: normal normal normal 10px/30px Roboto;
  color: #000000;
  opacity: 0.3;
}
.message__sender {
  display: flex;
  align-items: end;
  flex-direction: column;
}
.message__sender__paragraph {
  padding: 0.3rem;
  display: inline-block;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 0.6;
  width: auto;
  align-items: center;
}
.message__sender__paragraph p {
  font: normal normal normal 12px/29px Roboto;
  color: #FFFFFF;
  text-align: end;
}
.message__sender__timeStamp {
  font: normal normal normal 10px/30px Roboto;
  color: #000000;
  opacity: 0.3;
}
.message__sendMessageContainer {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
  background: #FFFFFF;
}
.message__sendMessageContainer__maincontainer {
  position: relative;
  bottom: 0.6rem;
  display: flex;
  width: 96%;
  border: 2px solid #7FC550;
  outline: none;
  border-radius: 6px;
  padding: 0.6rem;
  justify-content: space-between;
}
.message__sendMessageContainer__maincontainer__textfiled {
  width: 100%;
}
.message__sendMessageContainer__maincontainer__textfiled input {
  margin-left: 0.3rem;
  width: 100%;
  outline: unset;
  border: unset;
}
.message__sendMessageContainer__maincontainer__sendicon {
  color: #193D66;
  cursor: pointer;
}

.chat__sidebar__scrollcontainer::-webkit-scrollbar {
  width: 5px;
}

.chat__sidebar__scrollcontainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFFFFF;
  border-radius: 10px;
}

.chat__sidebar__scrollcontainer::-webkit-scrollbar-thumb {
  background: #717171;
  border-radius: 10px;
  height: 10px;
}

.chat__sidebar__scrollcontainer::-webkit-scrollbar-thumb:hover {
  background: #717171;
  height: 10px;
}

@media only screen and (max-width: 1000px) {
  .messages {
    display: 55%;
  }
  .chatSidebar {
    width: 50%;
  }
  .chat__sidebar__border {
    display: none;
  }
  .chat__sidebar__messageContainer {
    padding: 0.5rem;
    width: 98%;
  }
  .chat__sidebar__messageContainer__subContainer__name {
    font: normal normal bold 13px/20px Yu Gothic;
  }
  .chat__sidebar__messageContainer__subContainer__message__paragraph {
    width: auto;
    font-size: 10px;
  }
  .chat__sidebar__messageContainer__subContainer__message__days {
    width: 40%;
    margin-top: 2rem;
    text-align: end;
  }
}
@media only screen and (max-width: 768px) {
  .messages {
    display: none;
  }
  .chatSidebar {
    width: 100%;
  }
  .chat__sidebar {
    padding: 0rem;
  }
  .chat__sidebar__searchContainer {
    display: none;
  }
  .chat__sidebar__title {
    display: none;
  }
  .chat__sidebar__responsivetitlebar {
    display: block;
    display: flex;
    border-bottom: 1px solid #e7e7e7;
  }
  .chat__sidebar__responsivetitlebar__icon {
    position: relative;
    top: 1.5rem;
    left: 1rem;
  }
  .chat__sidebar__responsivetitlebar__title {
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
    font: normal normal normal 30px/50px Poppins;
    color: #717171;
    opacity: 1;
    align-items: center;
  }
  .chat__sidebar__responsivetitlebar__title span {
    text-align: center;
  }
  .chat__sidebar__border {
    display: none;
  }
  .chat__sidebar__messageContainer {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #e7e7e7;
    cursor: pointer;
    width: 99%;
  }
  .chat__sidebar__messageContainer__image img {
    position: relative;
    bottom: 0.2rem;
  }
  .chat__sidebar__messageContainer__subContainer {
    padding: 0rem;
    width: 95%;
  }
  .chat__sidebar__messageContainer__subContainer__name {
    font: normal normal bold 15px/20px Yu Gothic;
  }
  .chat__sidebar__messageContainer__subContainer__message__paragraph {
    width: auto;
    font-size: 12px;
  }
  .chat__sidebar__messageContainer__subContainer__message__days {
    width: 40%;
    margin-top: 1.18rem;
    text-align: end;
  }
  .message {
    padding: 1rem;
  }
  .message__profileContainer__image {
    margin-left: 0.6rem;
  }
  .message__profileContainer__namecontainer {
    margin-left: 0.5rem;
  }
  .message__receiver__paragraph {
    padding: 0.3rem;
  }
  .message__sendMessageContainer {
    width: 99%;
  }
  .message__sendMessageContainer__maincontainer {
    position: relative;
    bottom: 0.3rem;
  }
}
@media only screen and (max-width: 435px) {
  .chat__sidebar__messageContainer {
    width: 97%;
  }
}
.servicesOffer {
  margin: 5% 15% 10% 15%;
}
@media (max-width: 1200px) {
  .servicesOffer {
    width: 80%;
  }
}
@media (max-width: 1000px) {
  .servicesOffer {
    width: 90%;
  }
}
@media (max-width: 768px) {
  .servicesOffer {
    width: 100%;
    margin: 0px;
  }
}
.servicesOffer__container {
  box-shadow: 1px 1px 15px lightgray;
  padding: 2rem 3rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: padding 0.5s;
}
@media (max-width: 768px) {
  .servicesOffer__container {
    box-shadow: unset;
    border-radius: unset;
    padding: 2rem 1rem;
  }
}
.servicesOffer__container header {
  text-align: center;
  margin-bottom: 20px;
}
.servicesOffer__container header h3 {
  font-weight: 800;
  margin-top: 1rem;
  text-transform: capitalize;
}
.servicesOffer__container__body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 15px 0;
}
@media (max-width: 768px) {
  .servicesOffer__container__body {
    display: flex;
    flex-direction: column;
  }
}
.servicesOffer__container__body__left {
  width: 45%;
}
.servicesOffer__container__body__left__singleImg {
  width: 70%;
  margin: 70px;
}
@media (max-width: 768px) {
  .servicesOffer__container__body__left {
    width: 90%;
  }
}
@media (max-width: 500px) {
  .servicesOffer__container__body__left {
    width: 100%;
  }
}
.servicesOffer__container__body__left__flexImageLeft {
  display: flex;
  justify-content: flex-start;
}
.servicesOffer__container__body__left__flexImageLeft img {
  width: 50%;
  border: 3px solid black;
}
.servicesOffer__container__body__left__flexImageRight {
  display: flex;
  justify-content: flex-end;
}
.servicesOffer__container__body__left__flexImageRight img {
  width: 50%;
  border: 3px solid black;
}
.servicesOffer__container__body__right {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  width: 55%;
  height: 700px;
  overflow: auto;
}
@media (max-width: 768px) {
  .servicesOffer__container__body__right {
    width: 100%;
    padding: unset;
    margin-top: 2rem;
  }
}
.servicesOffer__container__body__right__radioInput2 {
  margin-left: 3rem !important;
  margin-top: 1rem;
}
.servicesOffer__container__body__right__radioInput {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.servicesOffer__container__body__right__radioInput label {
  color: #4E4E4E;
  font-size: 0.9rem;
  margin-left: 1rem;
}
.servicesOffer__container__body__right__radioInput input {
  accent-color: #193D66;
  height: 1rem;
  width: 1rem;
}
.servicesOffer__container__nextButton {
  width: 300px;
}
@media (max-width: 768px) {
  .servicesOffer__container__nextButton {
    width: 100%;
  }
}

.profileSetup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.5s;
  width: 70%;
}
@media (max-width: 1200px) {
  .profileSetup {
    width: 80%;
  }
}
@media (max-width: 1000px) {
  .profileSetup {
    width: 90%;
  }
}
@media (max-width: 768px) {
  .profileSetup {
    width: 100%;
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
  }
}
.profileSetup__container {
  box-shadow: 1px 1px 15px lightgray;
  padding: 1rem 3rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: padding 0.5s;
}
@media (max-width: 1000px) {
  .profileSetup__container {
    padding: 2rem 1rem;
  }
}
@media (max-width: 768px) {
  .profileSetup__container {
    box-shadow: unset;
    border-radius: unset;
  }
}
.profileSetup__container header {
  text-align: center;
}
.profileSetup__container header h3 {
  font-weight: 800;
  margin-top: 1rem;
  text-transform: capitalize;
}
.profileSetup__container__body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 2rem 0;
}
@media (max-width: 768px) {
  .profileSetup__container__body {
    display: flex;
    flex-direction: column;
  }
}
.profileSetup__container__body__left {
  width: 48%;
  padding: 0 2rem;
}
@media (max-width: 768px) {
  .profileSetup__container__body__left {
    padding: unset;
    width: 100%;
  }
}
.profileSetup__container__body__left h3 {
  margin-bottom: 1rem;
}
.profileSetup__container__body__left__profilePicture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profileSetup__container__body__left__profilePicture h3 {
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  width: 100%;
  text-align: center;
}
.profileSetup__container__body__left__profilePicture__profilePictureBox {
  width: 100%;
}
.profileSetup__container__body__left__profilePicture__profilePictureBox input {
  visibility: hidden;
}
.profileSetup__container__body__left__profilePicture__profilePictureBox__icon {
  color: #E6EAEE !important;
  position: absolute;
}
.profileSetup__container__body__left__profilePicture__profilePictureBox label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profileSetup__container__body__left__profilePicture__profilePictureBox label img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  background-color: #858585;
}
.profileSetup__container__body__left__inputBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.profileSetup__container__body__left__inputBox div {
  width: 100%;
}
.profileSetup__container__body__left__inputBox div:nth-child(1) {
  margin-right: 1rem;
}
.profileSetup__container__body__divider {
  border-left: 2px solid lightgray;
}
@media (max-width: 768px) {
  .profileSetup__container__body__divider {
    display: none;
  }
}
.profileSetup__container__body__right {
  padding: 2rem 2rem;
  width: 48%;
}
@media (max-width: 768px) {
  .profileSetup__container__body__right {
    width: 100%;
    padding: unset;
    margin-top: 2rem;
  }
}
.profileSetup__container__body__right h3 {
  margin-bottom: 1rem;
}
.profileSetup__container__body__right__profilePictureBox {
  width: 100%;
  margin-top: 1.5rem;
}
.profileSetup__container__body__right__profilePictureBox p {
  color: #193D66;
  font-weight: 600;
  margin-bottom: 1rem;
}
.profileSetup__container__body__right__profilePictureBox input {
  visibility: hidden;
}
.profileSetup__container__body__right__profilePictureBox__imagesBox {
  display: flex;
  flex-wrap: wrap;
}
.profileSetup__container__body__right__profilePictureBox__imagesBox__label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E6EAEE;
  height: 80px;
  width: 80px;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
}
.profileSetup__container__body__right__profilePictureBox__imagesBox__label__icon {
  color: #FFFFFF;
  font-size: 2.5rem !important;
}
.profileSetup__container__body__right__profilePictureBox__imagesBox img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.profileSetup__container__nextButton {
  width: 300px;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .profileSetup__container__nextButton {
    width: 100%;
  }
}

.selectLevel {
  margin-left: 250px;
  margin-top: 5rem;
}
@media (max-width: 768px) {
  .selectLevel {
    margin-left: unset;
  }
}
.selectLevel__body {
  width: 100%;
}
.selectLevel__body__heading {
  text-align: center;
  padding-top: 1rem;
}
.selectLevel__body__subHeading {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.selectLevel__body__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}
.selectLevel__body__cards__cardBasic:hover {
  border: 2px solid lightblue !important;
  cursor: pointer;
}
.selectLevel__body__cards__cardStandard:hover {
  border: 2px solid #4F1966 !important;
}
.selectLevel__body__cards__cardPremium:hover {
  border: 2px solid #193D66 !important;
}
.selectLevel__body__cards__card {
  box-shadow: 1px 1px 15px lightgray;
  padding: 1.5rem;
  border-radius: 1.5rem;
  margin: 0 0.5rem;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  z-index: 2;
  background-color: #FFFFFF;
  height: 570px;
}
.selectLevel__body__cards__card:hover {
  border: 2px solid #87C85B;
  cursor: pointer;
}
.selectLevel__body__cards__card__headingBasic {
  border-bottom: solid #3FB1D3 !important;
}
.selectLevel__body__cards__card__headingStandard {
  border-bottom: solid #4F1966 !important;
}
.selectLevel__body__cards__card__headingPremium {
  border-bottom: solid #193D66 !important;
}
.selectLevel__body__cards__card__heading {
  border-bottom: solid #87C85B;
  padding-bottom: 0.2rem;
  white-space: nowrap;
  text-align: center;
}
.selectLevel__body__cards__card__body {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.selectLevel__body__cards__card__body__textBox2 {
  height: 320px !important;
}
.selectLevel__body__cards__card__body__textBox {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  height: 300px;
}
.selectLevel__body__cards__card__body__textBox__text {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.8rem;
}
.selectLevel__body__cards__card__body__textBox__text p {
  font-size: 0.75rem;
  margin-left: 0.5rem;
  color: #858585;
  text-transform: capitalize;
  font-weight: 600;
}
.selectLevel__body__cards__card__body__textBox__text__icon {
  background-color: black !important;
  border-radius: 50%;
  color: #FFFFFF !important;
  font-size: 0.85rem !important;
  padding: 1px !important;
}
.selectLevel__body__cards__card__body__trialBasic {
  background-color: #D9EFF6 !important;
}
.selectLevel__body__cards__card__body__trialStandard {
  background-color: #DCD1E0 !important;
}
.selectLevel__body__cards__card__body__trialPremium {
  background-color: #CED6E2 !important;
}
.selectLevel__body__cards__card__body__trial {
  background-color: #D8EDCA;
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  text-align: center;
  color: #4E4E4E;
  display: flex;
  flex-direction: column;
  width: auto;
}
.selectLevel__body__cards__card__body__trial p {
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: unset;
}
.selectLevel__body__cards__card__body__trial span {
  font-size: 0.8rem;
  margin-top: -0.1rem;
}
.selectLevel__body__cards__card__body__trial__saving {
  background-color: red;
  position: absolute;
  color: #FFFFFF;
  text-align: center;
  border-radius: 50%;
  padding: 8px;
  font-size: 0.7rem !important;
  margin-left: 9.5rem;
}
.selectLevel__body__cards__card__body__trial2 {
  background-color: #D8EDCA;
  border-radius: 2rem;
  padding: 0.3rem 0.5rem;
  text-align: center;
  color: #4E4E4E;
  display: flex;
  flex-direction: column;
  width: auto;
}
.selectLevel__body__cards__card__body__trial2 p {
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: unset;
}
.selectLevel__body__cards__card__body__trial2 span {
  font-size: 0.8rem;
  margin-top: -0.2rem;
}
.selectLevel__body__cards__card__body__trial2__saving {
  background-color: red;
  position: absolute;
  color: #FFFFFF;
  text-align: center;
  border-radius: 50%;
  padding: 7px;
  font-size: 0.6rem !important;
  margin-left: 11.8rem;
  margin-top: -0.7rem !important;
}
.selectLevel__body__cards__card__body__dividerStandard p {
  background-color: #4F1966 !important;
}
.selectLevel__body__cards__card__body__dividerPremium p {
  background-color: #193D66 !important;
}
.selectLevel__body__cards__card__body__divider {
  border-bottom: 1px solid lightgray;
  padding-top: 1.1rem;
  margin-bottom: 1.1rem;
}
.selectLevel__body__cards__card__body__divider p {
  background-color: #3FB1D3;
  border-radius: 50%;
  position: absolute;
  color: #FFFFFF;
  padding: 4px;
  font-size: 0.7rem;
  margin-left: 6.5rem;
  margin-top: -0.8rem;
}
.selectLevel__body__grayBottom {
  background-color: #E6EAEE;
  height: 180px;
  margin-top: -180px;
}
@media (max-width: 1184px) {
  .selectLevel__body__grayBottom {
    display: none;
  }
}
.selectLevel__body__continueButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.selectLevel__body__continueButton div {
  width: 300px;
}

.basicCardModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.basicCardModal__header2 {
  border-bottom: solid #193D66 !important;
}
.basicCardModal__header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: solid #3FB1D3;
  width: 20%;
  white-space: nowrap;
  padding-bottom: 0.3rem;
}
.basicCardModal__body__options {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 2rem;
}
.basicCardModal__body__options__Box {
  margin: 0.5rem;
  width: 33%;
}
.basicCardModal__body__plans__header2 h3 {
  border-bottom: solid #193D66 !important;
}
.basicCardModal__body__plans__header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.basicCardModal__body__plans__header h3 {
  border-bottom: solid #3FB1D3;
  width: 20%;
  white-space: nowrap;
  padding-bottom: 0.3rem;
}
.basicCardModal__body__plans__box {
  display: flex;
  justify-content: space-around;
  margin: 1.5rem 2rem;
}
.basicCardModal__body__plans__box input {
  accent-color: #193D66;
  height: 1.1rem;
  width: 1.1rem;
}
.basicCardModal__body__plans__box label {
  margin-left: 1rem;
}
.basicCardModal__body__divider2 div span {
  background-color: #193D66 !important;
}
.basicCardModal__body__divider {
  display: flex;
  justify-content: center;
  align-items: center;
}
.basicCardModal__body__divider div {
  width: 250px;
  border-bottom: 1px solid lightgray;
}
.basicCardModal__body__divider div span {
  position: absolute;
  background-color: #3FB1D3;
  border-radius: 50%;
  padding: 4px;
  color: white;
  font-size: 0.8rem;
  margin-left: 7rem;
  margin-top: -1rem;
}
.basicCardModal__body__offerContainer2 div {
  background-color: #E6EAEE !important;
}
.basicCardModal__body__offerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.basicCardModal__body__offerContainer div {
  display: flex;
  align-items: center;
  background-color: lightblue;
  border-radius: 2rem;
  padding: 0.2rem 2rem;
  width: 250px;
  margin: 2rem 0;
}
.basicCardModal__body__offerContainer div input {
  accent-color: #193D66;
  height: 1.1rem;
  width: 1.1rem;
}
.basicCardModal__body__offerContainer div label {
  margin-left: 1rem;
  text-align: center;
}
.basicCardModal__body__offerContainer div label p {
  font-weight: 600;
  font-size: 0.8rem;
  color: #4E4E4E;
  margin-bottom: -0.3rem;
}
.basicCardModal__body__offerContainer div label span {
  font-size: 0.8rem;
  color: #4E4E4E;
}
.basicCardModal__body__offerContainer__saving {
  position: absolute;
  background-color: red;
  border-radius: 50%;
  padding: 8px;
  text-align: center;
  color: #FFFFFF;
  font-size: 0.7rem;
  margin-left: 11.5rem;
}
.basicCardModal__body__saveButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.basicCardModal__body__saveButton div {
  width: 330px;
}

.paymentPage {
  display: flex;
  justify-content: center;
}
.paymentPage__form {
  width: 550px;
  border: 1px solid #87C85B;
  border-radius: 20px;
  padding: 40px;
  margin-top: 40px;
}
.paymentPage__form__heading {
  justify-content: center;
  display: flex;
  margin-bottom: 25px;
  color: #858585;
  font-weight: 600;
  font-size: 1rem;
  text-transform: capitalize;
  flex-direction: column;
}
.paymentPage__form__heading h2 {
  display: flex;
  justify-content: center;
}
.paymentPage__form__heading p {
  display: flex;
  justify-content: center;
}
.paymentPage__form__button {
  margin-top: 15px;
}

.rate__tablecontainer {
  margin-top: 5rem;
  margin-left: 250px;
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .rate__tablecontainer {
    margin-left: 0px;
    margin-top: 5rem;
  }
}
.rate__tablecontainer__tableheader {
  margin-top: 4rem;
  padding: 1.5rem 1rem;
}
.rate__tablecontainer__tableheader__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.rate__tablecontainer__tableheader__container__title {
  font-size: 22px;
  color: #193d66;
  font-weight: 600;
}
.rate__tablecontainer__tableheader__container__buttonbox {
  align-items: center !important;
}
.rate__tablecontainer__tableheader__container__buttonbox__button {
  background-color: #193D66 !important;
  columns: #FFFFFF !important;
  padding: 0.6rem 2rem !important;
  border-radius: 0.5rem !important;
  color: white !important;
  align-items: center !important;
}
@media (max-width: 550px) {
  .rate__tablecontainer__tableheader__container__buttonbox__button {
    padding: 0.3rem 2rem !important;
  }
}
.rate__tablecontainer__table {
  width: 100%;
  height: 92vh;
}
.rate__tablecontainer__table__section {
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgray;
  padding-top: 10px;
  overflow-y: auto;
}
.rate__tablecontainer__table__section h4 {
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .rate__tablecontainer__table__section {
    overflow: auto;
  }
}
.rate__tablecontainer__table__section__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f8f8f8;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border-radius: 6px;
  text-wrap: nowrap;
}
@media (max-width: 900px) {
  .rate__tablecontainer__table__section__container {
    width: 130%;
  }
}
@media (max-width: 768px) {
  .rate__tablecontainer__table__section__container {
    width: 250%;
  }
}
.rate__tablecontainer__table__section__container__dollartextfield__greenInput {
  display: flex;
  flex-direction: column;
  border: 2px solid #87C85B;
  background-color: #ffffff;
  border-radius: 0.7rem;
  padding: 0rem 1rem;
  width: 90%;
}
.rate__tablecontainer__table__section__container__dollartextfield__greenInput label {
  color: #444444;
  font-size: 10px;
  font-weight: 600;
}
.rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox {
  display: flex;
}
.rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox input {
  position: relative;
  bottom: 0.5rem;
  margin-top: 0.8rem;
  outline: unset;
  border: unset;
  color: #444444;
  font-weight: lighter;
  font-size: 12px;
  padding-right: 40px;
  background-color: unset;
  border-radius: 0.5rem;
  width: 100%;
  padding-left: 0rem;
}
.rate__tablecontainer__table__section__container__selectbox {
  align-items: center;
  position: relative;
  top: 0.5rem;
}
.rate__tablecontainer__table__section__container__selectbox .greenSelect {
  height: 50px;
  display: flex;
  flex-direction: column;
  border: 2px solid #87C85B;
  background-color: #ffffff;
  border-radius: 0.7rem;
  padding: 0rem 0.5rem;
  width: 90%;
}
.rate__tablecontainer__table__section__container__selectbox .greenSelect label {
  color: #444444;
  font-size: 10px !important;
  font-weight: 600;
  align-items: center;
}
.rate__tablecontainer__table__section__container__selectbox .greenSelect select {
  position: relative;
  bottom: 0.3rem;
  outline: unset;
  border: unset;
  font-weight: lighter;
  color: #444444;
  margin-top: 0.5rem;
  background-color: transparent;
  align-items: center;
}
.rate__tablecontainer__table__section__container__selectbox .greenSelect select option {
  font-weight: 600;
  color: #444444;
}
.rate__tablecontainer__table__section__container__Regularbox .greenSelect {
  height: 50px;
  position: relative;
  top: 0.5rem;
  display: flex;
  flex-direction: column;
  border: 2px solid #87C85B;
  background-color: #ffffff;
  border-radius: 0.7rem;
  padding: 0rem 0.5rem;
  width: 90%;
}
.rate__tablecontainer__table__section__container__Regularbox .greenSelect label {
  color: #222222;
  font-size: 10px;
  font-weight: 600;
  align-items: center;
}
.rate__tablecontainer__table__section__container__Regularbox .greenSelect select {
  position: relative;
  bottom: 0.3rem;
  outline: unset;
  border: unset;
  font-weight: lighter;
  color: #444444;
  margin-top: 0.5rem;
  background-color: transparent;
  align-items: center;
}
.rate__tablecontainer__table__section__container__Regularbox .greenSelect select option {
  font-weight: 600;
  color: #444444;
}
.rate__tablecontainer__table__section__container__buttonbox {
  display: flex;
  align-items: center;
}
.rate__tablecontainer__table__section__buttonbox {
  display: flex;
  align-items: center !important;
  align-items: center;
  justify-content: center;
  display: none;
}
@media (max-width: 768px) {
  .rate__tablecontainer__table__section__buttonbox {
    margin-top: 1rem;
    display: block;
    display: flex;
    align-items: center !important;
    align-items: center;
    justify-content: center;
  }
}
.rate__tablecontainer__table__section__buttonbox__button {
  width: 250px;
  background-color: #193D66 !important;
  columns: #FFFFFF !important;
  padding: 0.6rem 2rem !important;
  border-radius: 0.5rem !important;
  color: white !important;
  align-items: center !important;
}

.rate__sidebar__section > :nth-child(6) {
  background-color: #476499;
  border-radius: 8px;
  opacity: 1;
  padding: 0.3rem 0.4rem;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #7FC550 !important;
  border-radius: 12px !important;
}

.MuiFormLabel-root {
  color: black;
}

.rate__tablecontainer__table__section::-webkit-scrollbar {
  width: 5px;
}

@media (max-width: 768px) {
  .rate__tablecontainer__table__section::-webkit-scrollbar {
    width: 0 !important;
  }
}
.rate__tablecontainer__table__section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFFFFF;
  border-radius: 10px;
}

.rate__tablecontainer__table__section::-webkit-scrollbar-thumb {
  background: #717171;
  border-radius: 10px;
}

.rate__tablecontainer__table__section::-webkit-scrollbar-thumb:hover {
  background: #717171;
  height: 10px;
}

.provider {
  display: flex;
  margin-top: 5rem;
  margin-left: 250px;
  height: 100%;
}
@media (max-width: 1000px) {
  .provider {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .provider {
    margin-left: unset;
    margin-top: 7rem;
  }
}
.provider__container {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 2rem;
  margin-top: 1rem;
}
@media (max-width: 1000px) {
  .provider__container {
    width: 95%;
  }
}
.provider__container__image__box {
  display: flex;
  justify-content: center;
}
.provider__container__image img {
  width: 370px;
  height: 250px;
  box-shadow: 3px 4px 7px rgba(0, 0, 0, 0.2901960784);
  object-fit: cover;
  border-radius: 1rem;
}
@media (max-width: 768px) {
  .provider__container__image img {
    width: 60%;
  }
}
@media (max-width: 500px) {
  .provider__container__image img {
    width: 75%;
  }
}
.provider__container__image__editIcon {
  display: flex;
  justify-content: center;
  background-color: #7FC550;
  color: #FFFFFF;
  cursor: pointer;
  position: relative;
  right: 1.5rem;
  height: 40px;
  width: 40px;
  align-items: center;
  text-align: center;
  bottom: 0.8rem;
  clip-path: circle(40%);
}
.provider__container__title {
  font: normal normal bold 27px/10px Yu Gothic;
  color: #4B4A4A;
  margin-right: 1rem;
  margin-top: 2rem;
}
.provider__container__rating {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  text-align: center;
  margin-right: 1rem;
}
.provider__container__rating__number {
  color: #4B4A4A;
  font: normal normal normal 23px/0px Yu Gothic;
  text-align: center;
}
.provider__container__rating__starIcon {
  color: #dbc37a;
  position: relative;
  top: 0.1rem;
  margin-left: 0.3rem;
}
.provider__container__text {
  font: normal normal normal 21px/0px Poppins;
  color: #4E4E4E;
  margin-top: 1.5rem;
  opacity: 0.8;
  margin-right: 2.2rem;
}
.provider__container__about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
}
.provider__container__about__email__container {
  display: flex;
  margin-top: 2rem;
}
.provider__container__about__email__container__icon {
  color: #4E4E4E !important;
}
.provider__container__about__email__container h4 {
  margin-top: 0.2rem;
  margin-left: 1rem;
  font: normal normal normal 19px/20px Poppins;
  color: #4E4E4E;
}
@media (max-width: 400px) {
  .provider__container__about__email__container h4 {
    font: normal normal normal 16px/20px Poppins;
  }
}
.provider__container__about__contact__container {
  display: flex;
  margin-top: 1rem;
}
@media (max-width: 600px) {
  .provider__container__about__contact__container {
    margin-right: 0rem;
  }
}
.provider__container__about__contact__container__callIcon {
  color: #4E4E4E;
  margin-top: 0.2rem;
}
.provider__container__about__contact__container__country__image {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.provider__container__about__contact__container__country__image img {
  width: 30px;
}
.provider__container__about__contact__container h4 {
  color: #4E4E4E;
  margin-left: 0.4rem;
  font: normal normal normal 18px/30px Poppins;
}
@media (max-width: 400px) {
  .provider__container__about__contact__container h4 {
    font: normal normal normal 16px/20px Poppins;
    margin-top: 0.5rem;
  }
}
.provider__container__about__locationInformation {
  display: flex;
  margin-top: 1rem;
}
@media (max-width: 600px) {
  .provider__container__about__locationInformation {
    margin-right: 0rem;
  }
}
.provider__container__about__locationInformation__cityIcon {
  color: #4E4E4E;
}
.provider__container__about__locationInformation h4 {
  font: normal normal normal 18px/27px Poppins;
  margin-left: 1rem;
  color: #4E4E4E;
}
@media (max-width: 400px) {
  .provider__container__about__locationInformation h4 {
    font: normal normal normal 16px/20px Poppins;
  }
}
.provider__container__about__container {
  width: 60%;
  margin-top: 0.5rem;
  margin-left: 3rem;
  text-align: justify;
}
@media (max-width: 768px) {
  .provider__container__about__container {
    margin-left: 0rem;
    width: 100%;
    text-align: justify;
  }
}
.provider__container__about__container h4 {
  font-size: 23px;
  color: #4E4E4E;
  position: relative;
  right: 1rem;
}
.provider__container__about__container__paragraph {
  text-align: justify;
  word-wrap: none;
  width: 80%;
  margin-top: 0.5rem;
  font: normal normal normal 17px/25px Poppins;
  color: #4E4E4E;
}
.provider__profilesetting {
  width: 30%;
  background-color: #f7f5f5;
  padding: 1rem;
}
@media (max-width: 1000px) {
  .provider__profilesetting {
    background-color: transparent;
    width: auto;
    margin-top: 1rem;
  }
}
.provider__profilesetting__section__title {
  text-align: left;
  margin-top: 0.5rem;
  font: normal normal medium 100px/28px Poppins;
  letter-spacing: 0.6px;
  color: #193D66;
  opacity: 1;
  font-size: 25px;
  font-weight: 600;
}
.provider__profilesetting__section__box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  border-radius: 12px 12px 0px 0px;
  margin-top: 1rem;
}
.provider__profilesetting__section__box__container {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font: normal normal 600 15px/24px Yu Gothic;
  color: #4B4A4A;
  border-bottom: 1px solid #e9e9e9;
  padding: 0.5rem;
  cursor: pointer;
}
.provider__profilesetting__section__box__container__logouttitle {
  color: red;
}
.provider__profilesetting__section__box__container__icon {
  color: #e9e9e9;
  cursor: pointer;
}

.termscondition__section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.termscondition__section__parahraph {
  margin-top: 1rem;
  color: #2b4091;
  font: normal normal normal 18px/23px Roboto;
  text-align: justify;
  word-wrap: none;
}
.termscondition__section__parahraph__one {
  margin-top: 1rem;
}
.termscondition__section__parahraph__two {
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .profilesection {
    overflow-y: auto !important;
    height: 100vh !important;
    border-radius: 0px !important;
    width: 100% !important;
  }
}
@media (max-width: 450px) {
  .profilesection {
    overflow-y: auto !important;
    width: 100% !important;
    height: 100vh !important;
  }
}

@media (max-width: 830px) {
  .editprofilesection {
    overflow-y: auto !important;
    height: 100vh !important;
    border-radius: 0px !important;
    width: 100% !important;
  }
}
@media (max-width: 450px) {
  .editprofilesection {
    overflow-y: auto !important;
    width: 100% !important;
    height: 100vh !important;
  }
}

.profileedit {
  width: 100%;
  background-color: transparent;
}
.profileedit__container {
  padding: 0rem 0rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: padding 0.5s;
}
@media (max-width: 768px) {
  .profileedit__container {
    box-shadow: unset;
    border-radius: unset;
    height: 100%;
  }
}
@media (max-width: 487px) {
  .profileedit__container {
    width: 100%;
  }
}
.profileedit__container__body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0rem 0;
}
@media (max-width: 768px) {
  .profileedit__container__body {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
}
@media (max-width: 487px) {
  .profileedit__container__body {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
}
.profileedit__container__divider {
  border-left: 2px solid lightgray;
  height: 360px;
  position: relative;
  top: 2rem;
}
@media (max-width: 768px) {
  .profileedit__container__divider {
    display: none;
  }
}

@media (max-width: 768px) {
  .css-1q45p5z {
    width: 471px !important;
    overflow-y: auto !important;
    height: 100vh !important;
  }
}
@media (max-width: 487px) {
  .css-1q45p5z {
    width: 400px !important;
    overflow-y: auto !important;
    height: 100vh !important;
  }
}
@media (max-width: 400px) {
  .css-1q45p5z {
    width: 350px !important;
    overflow-y: auto !important;
    height: 100vh !important;
  }
}
.modal__container {
  display: flex;
  justify-content: space-between;
}
.modal__container__icon {
  display: none;
}
@media (max-width: 768px) {
  .modal__container__icon {
    display: block;
  }
}

.editService__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.editService__modal__header {
  display: flex;
  flex-direction: column;
}
.editService__modal__header__title {
  text-align: center;
  width: 100%;
}
.editService__modal__header__description {
  text-align: center;
}

.passwordChange__container__passChange {
  font-weight: 800;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  width: 100%;
  text-align: center;
}

.plan {
  display: flex;
  border-radius: 50px;
  display: flex;
  background: #e3f2fd;
  width: 20%;
  padding: 0.3rem;
  align-items: center;
  color: #46a6f4;
  font-weight: 300 !important;
  margin-bottom: 1rem;
}
.plan h6 {
  font-weight: 400;
}
.plan__title {
  margin-bottom: 0.3rem;
}
.plan__paragraph {
  color: #7b7d80;
  font-size: 15px;
  margin-bottom: 1rem;
}
.plan__rupees {
  margin-bottom: 1rem;
}
.plan__list {
  display: flex;
  border-radius: 50px;
  gap: 1rem;
  align-items: center;
  font-weight: 300 !important;
  margin-bottom: 1em;
  color: #46474b;
  font-family: "Mona Sans" "Helvetica Neue" Helvetica Arial sans-serif;
}
.plan__list h4 {
  font-weight: 500;
}
.plan__list__container {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
}
.plan__list__container__first {
  display: flex;
  gap: 1rem;
}
.plan__list__container__second {
  display: flex;
  gap: 1rem;
}

.payment__plan {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.payment__plan__detail {
  width: 70%;
}
.payment__plan__detail__title {
  margin-bottom: 0.3rem;
  color: #294b71;
}
.payment__plan__detail__paragraph {
  color: #7b7d80;
  font-size: 15px;
}
.payment__plan__container {
  text-wrap: nowrap;
  color: #294b71;
}

.background {
  background: #436082;
  height: 400px;
  width: 55%;
  clip-path: polygon(0% 0%, 75% 0, 99% 50%, 75% 100%, 0% 141%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .background {
    width: 100%;
    clip-path: none;
    height: 120px;
    display: block;
  }
  .background h1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.popup {
  display: flex;
  gap: 2rem;
}
@media (max-width: 768px) {
  .popup {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.popup__right {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}

.updateButon {
  font-size: 18px !important;
  display: inline-block !important;
  outline: 0 !important;
  border: 0 !important;
  cursor: pointer !important;
  will-change: box-shadow, transform !important;
  background: radial-gradient(100% 100% at 100% 0%, #89e5ff 0%, #5468ff 100%) !important;
  box-shadow: 0px 0.01em 0.01em rgba(45, 35, 66, 0.4), 0px 0.3em 0.7em -0.01em rgba(45, 35, 66, 0.3), inset 0px -0.01em 0px rgba(58, 65, 111, 0.5) !important;
  padding: 0 2em !important;
  border-radius: 0.3em !important;
  color: #fff !important;
  height: 2.6em !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;
  transition: box-shadow 0.15s ease, transform 0.15s ease !important;
}
.updateButon:hover {
  box-shadow: 0px 0.1em 0.2em rgba(45, 35, 66, 0.4), 0px 0.4em 0.7em -0.1em rgba(45, 35, 66, 0.3), inset 0px -0.1em 0px #3c4fe0 !important;
  transform: translateY(-0.1em) !important;
  transform: scale(1.05) !important;
}

.cross__icon {
  display: none;
}
@media (max-width: 768px) {
  .cross__icon {
    display: flex;
    justify-content: end;
    width: 100%;
    color: white !important;
  }
}

.circularLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}

.profileModalHeader {
  display: flex;
  flex-direction: column;
}
.profileModalHeader .heading {
  margin-bottom: 5px;
}
.profileModalHeader .description {
  margin-bottom: 2rem;
  text-align: center;
}

.myAds {
  margin-top: 5rem;
  margin-left: 250px;
  padding: 1rem 2rem;
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .myAds {
    padding: 1rem 1rem;
    margin-left: 0;
  }
}
.myAds__body header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.myAds__body header h2 {
  color: #193D66;
}
@media (max-width: 367px) {
  .myAds__body header {
    font-size: 12px;
  }
}
.myAds__body__compulsoryData {
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: red;
  border: 1px solid red;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.myAds__body__container2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
@media (max-width: 768px) {
  .myAds__body__container2 {
    justify-content: center;
  }
}
.myAds__body__container2__adsContainer2 {
  margin: 1rem 0;
  width: 500px;
}
@media (max-width: 880px) {
  .myAds__body__container2__adsContainer2 {
    width: 500px;
  }
}
@media (max-width: 600px) {
  .myAds__body__container2__adsContainer2 {
    margin: 0rem;
    padding: 1rem;
  }
}
@media (max-width: 550px) {
  .myAds__body__container2__adsContainer2 {
    width: 500px;
  }
}
@media (max-width: 470px) {
  .myAds__body__container2__adsContainer2 {
    width: 400px;
  }
}
@media (max-width: 375px) {
  .myAds__body__container2__adsContainer2 {
    width: 340px;
  }
}
.myAds__body__container2__adsContainer2__adCard {
  border-radius: 1rem;
  box-shadow: 1px 1px 15px lightgray;
  background-color: #FFFFFF;
  padding: 1.5rem;
}
@media (max-width: 1125px) {
  .myAds__body__container2__adsContainer2__adCard {
    min-width: 100%;
  }
}
.myAds__body__container2__adsContainer2__adCard__profile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5rem;
  word-break: break-all;
}
.myAds__body__container2__adsContainer2__adCard__profile__image {
  display: flex;
  align-items: flex-start;
}
.myAds__body__container2__adsContainer2__adCard__profile__image img {
  width: 110px;
  height: 120px;
  object-fit: cover;
  border-radius: 1rem;
  margin-top: 0.5rem;
}
.myAds__body__container2__adsContainer2__adCard__profile__image__icon {
  color: #FFE177;
  font-size: 3rem !important;
}
.myAds__body__container2__adsContainer2__adCard__profile__nameBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.myAds__body__container2__adsContainer2__adCard__profile__nameBox h2 {
  margin-top: 0.5rem;
}
.myAds__body__container2__adsContainer2__adCard__profile__nameBox span {
  color: #858585;
  text-transform: uppercase;
  margin-top: 0.3rem;
}
.myAds__body__container2__adsContainer2__adCard__profile__nameBox h5 {
  color: #858585;
  font-size: 0.8rem;
  margin-top: 0.8rem;
}
.myAds__body__container2__adsContainer2__adCard__profile__nameBox h5 b {
  margin-left: 0.5rem;
  color: black;
}
.myAds__body__container2__adsContainer2__adCard__services {
  margin-top: 0.5rem;
  border-bottom: 1px solid lightgray;
}
.myAds__body__container2__adsContainer2__adCard__services__options {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  text-wrap: nowrap;
}
.myAds__body__container2__adsContainer2__adCard__services__options h5 {
  margin: 0.5rem;
  background-color: #193D66;
  padding: 0.5rem 1.5rem;
  border-radius: 3rem;
  color: #FFFFFF;
  font-size: 0.9rem;
  font-weight: 500;
}
.myAds__body__container2__adsContainer2__adCard__services__address {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  padding-bottom: 1rem;
  word-break: break-all;
}
.myAds__body__container2__adsContainer2__adCard__services__address__icon {
  font-size: 1.2rem !important;
}
.myAds__body__container2__adsContainer2__adCard__services__address b {
  margin-left: 0.5rem;
  text-transform: capitalize;
}
.myAds__body__container2__adsContainer2__adCard__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5rem;
  word-break: break-all;
}
.myAds__body__container2__adsContainer2__adCard__details__time {
  font-size: 0.9rem;
  color: #858585;
}
.myAds__body__container2__adsContainer2__adCard__details__price {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.myAds__body__container2__adsContainer2__adCard__details__price span {
  font-size: 0.8rem;
}
.myAds__body__container2__adsContainer2__adCard__details__price b {
  color: #193D66;
  font-size: 2rem;
}
.myAds__body__container2__adsContainer2__adCard__images {
  margin-top: 0.5rem;
}
.myAds__body__container2__adsContainer2__adCard__images__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.myAds__body__container2__adsContainer2__adCard__images__header__icons__icon {
  background-color: #193D66 !important;
  padding: 1px;
  color: #FFFFFF !important;
  border-radius: 50%;
  margin: 0 0.3rem;
}
.myAds__body__container2__adsContainer2__adCard__images__imageBox::-webkit-scrollbar {
  display: none;
}
.myAds__body__container2__adsContainer2__adCard__images__imageBox {
  display: flex !important;
  overflow: auto;
  margin-top: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.myAds__body__container2__adsContainer2__adCard__images__imageBox img {
  border-radius: 1rem;
  height: 140px;
  width: 140px;
  object-fit: cover;
  margin: 0 0.3rem;
  box-shadow: 3px 2px 2px lightgray;
}
.myAds__body__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.myAds__body__container__adsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}
.myAds__body__container__adsContainer__adIcon {
  color: #858585 !important;
  font-size: 14rem !important;
}
.myAds__body__container__adsContainer p {
  color: #858585;
  font-size: 2rem;
  margin-top: 1rem;
  text-transform: uppercase;
}
.myAds__body__container__adsContainer__addNewButton {
  margin-top: 0rem;
}

.newAdModalButton {
  background-color: #193D66 !important;
  columns: #FFFFFF !important;
  padding: 0.6rem 2rem !important;
  border-radius: 0.5rem !important;
  color: white !important;
  align-items: center !important;
}
@media (max-width: 550px) {
  .newAdModalButton {
    padding: 0rem !important;
    font-weight: 0 !important;
    letter-spacing: 0 !important;
    text-transform: normal !important;
    min-width: auto;
    padding: 9px 9px !important;
    font-size: 12px !important;
  }
}

.newAdModal__body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.newAdModal__body header {
  text-align: center;
}
.newAdModal__body header h2 {
  text-transform: capitalize;
}
.newAdModal__body__steps {
  display: flex;
  justify-content: space-between;
  width: 250px;
  margin: 2rem 0;
  color: #87C85B;
  font-size: 2.5rem;
  font-weight: 100;
}
.newAdModal__body__steps__border {
  border: 1px solid #87C85B;
  width: 250px;
  position: absolute;
  margin-top: 0.8rem;
}
.newAdModal__body__steps__step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 222;
  background-color: white;
}
.newAdModal__body__steps__step__doneIcon {
  color: #FFFFFF;
  background-color: #87C85B;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  padding: 2px;
  margin-top: -1.9rem;
}
.newAdModal__body__steps__step span {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid #87C85B;
}
.newAdModal__body__steps__step p {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  font-size: 1rem;
  margin-top: 0.5rem;
}
.newAdModal__body__textFields {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (max-width: 768px) {
  .newAdModal__body__textFields__group {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .newAdModal__body__textFields__group__textField {
    width: 100% !important;
    padding: 0 0.3rem;
  }
}
.newAdModal__body__textFields__group2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0.5rem 0;
  width: 100%;
}
.newAdModal__body__textFields__group2__textField {
  width: 49%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem;
}
@media (max-width: 500px) {
  .newAdModal__body__textFields__group2__textField {
    width: 100%;
  }
}
.newAdModal__body__textFields__group2__textField p {
  margin: 0 3rem 0 0;
  font-weight: 600;
  color: #858585;
}
@media (max-width: 768px) {
  .newAdModal__body__textFields__group2__textField p {
    display: none;
  }
}
.newAdModal__body__textFields__group2__textField div {
  width: 100%;
}
.newAdModal__body__textFields__nextButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.newAdModal__body__pictures {
  width: 100%;
}
.newAdModal__body__pictures header {
  text-align: left;
}
.newAdModal__body__pictures header h3 {
  margin: 1rem 0;
}
.newAdModal__body__pictures__box {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .newAdModal__body__pictures__box {
    display: flex;
    justify-content: space-around;
  }
}
.newAdModal__body__pictures__box img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 0.7rem;
  border-radius: 0.5rem;
}
.newAdModal__body__pictures__box__badge {
  margin: 0.7rem;
  border-radius: 1rem;
  box-shadow: 1px 1px 15px lightgray;
  background-color: #FFFFFF;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 210px;
}
.newAdModal__body__pictures__box__badge__icon {
  font-size: 5rem !important;
  background-color: #193D66 !important;
  padding: 5px;
  border-radius: 50%;
  color: #FFE177 !important;
}
.newAdModal__body__pictures__box__badge span {
  color: #858585;
  font-size: 0.8rem;
  margin-top: 0.7rem;
  text-transform: capitalize;
  white-space: nowrap;
}
.newAdModal__body__pictures__box__badge p {
  font-size: 0.9rem;
  margin-top: 0.7rem;
  text-transform: uppercase;
  white-space: nowrap;
}
.newAdModal__body__pictures__box__badge strong {
  margin-top: 0.7rem;
  white-space: nowrap;
}
.newAdModal__body__badgeModal {
  margin-top: 2rem;
}
.newAdModal__body__createAdButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}
.newAdModal__body__createAdButton div {
  width: 340px;
}
.newAdModal__body__aboutAd {
  width: 100%;
}
.newAdModal__body__aboutAd__timeLineInfo {
  display: flex;
  align-items: center;
  color: gray;
  border: 1px solid #cdcdcd;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.newAdModal__body__aboutAd__timeLineInfo svg {
  margin-right: 5px;
}
.newAdModal__body__aboutAd__timeline {
  display: flex;
  justify-content: space-between;
}
.newAdModal__body__aboutAd__timeline__input {
  width: 47%;
}

@media (max-width: 1000px) {
  .modalSmall {
    position: unset !important;
    top: unset !important;
    left: unset !important;
    transform: translate(0%, 0%) !important;
    width: 100% !important;
    border: unset !important;
    box-shadow: unset !important;
    border-radius: unset !important;
    margin-top: unset !important;
    padding: 1rem !important;
  }
}

.swiper-wrapper {
  z-index: 0 !important;
}

.swiper {
  z-index: 0 !important;
}

.myAds__body__container2__adsContainer2__adCard__services__options::-webkit-scrollbar {
  height: 5px;
}

.myAds__body__container2__adsContainer2__adCard__services__options::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px white;
  border-radius: 10px;
}

.myAds__body__container2__adsContainer2__adCard__services__options::-webkit-scrollbar-thumb {
  background: #537198;
  border-radius: 10px;
}

.myAds__body__container2__adsContainer2__adCard__services__options::-webkit-scrollbar-thumb:hover {
  background: #537198;
}

.ad__editIcon {
  display: flex;
  justify-content: center;
  background-color: #7fc550;
  color: #ffffff;
  position: relative;
  height: 40px !important;
  width: 40px !important;
  align-items: center;
  text-align: center;
  clip-path: circle(40%);
  position: relative;
  left: 1rem;
  bottom: 0.5rem;
  cursor: pointer;
}

.flex__item {
  display: flex;
  justify-content: space-between;
}

.clientreview__container {
  margin-top: 5rem;
  margin-left: 250px;
  display: flex;
  flex-wrap: wrap;
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .clientreview__container {
    margin-top: 6rem;
    margin-left: 0px;
  }
}
.clientreview__container__client {
  width: 65%;
}
@media (max-width: 1310px) {
  .clientreview__container__client {
    width: 60%;
  }
}
@media (max-width: 1315px) {
  .clientreview__container__client {
    width: 100%;
  }
}
.clientreview__container__client__title {
  margin-left: 1.5rem;
  font: normal normal medium 30px/28px Poppins;
  letter-spacing: 0.6px;
  color: #193d66;
}
.clientreview__container__client__review {
  margin-top: 0.5rem;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  padding: 1rem;
}
.clientreview__container__client__review__boxContainer {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2901960784);
  border-radius: 0.8rem;
  width: 98%;
  margin-left: 0.5rem;
}
.clientreview__container__client__review__boxContainer__rating {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.clientreview__container__client__review__boxContainer__rating__profile {
  display: flex;
}
.clientreview__container__client__review__boxContainer__rating__profile__image img {
  width: 60px;
  height: 60px;
  clip-path: circle(50%);
  object-fit: cover;
}
@media (max-width: 466px) {
  .clientreview__container__client__review__boxContainer__rating__profile__image img {
    width: 45px;
    height: 45px;
  }
}
@media (max-width: 375px) {
  .clientreview__container__client__review__boxContainer__rating__profile__image img {
    width: 40px;
    height: 40px;
  }
}
.clientreview__container__client__review__boxContainer__rating__profile__information {
  margin-left: 1rem;
  align-items: center;
}
@media (max-width: 376px) {
  .clientreview__container__client__review__boxContainer__rating__profile__information {
    margin-left: 0.5rem;
  }
}
.clientreview__container__client__review__boxContainer__rating__profile__information__name {
  font: normal normal bold 23px/23px Yu Gothic;
  margin-top: 0rem;
  align-items: center;
  color: #000000;
}
@media (max-width: 466px) {
  .clientreview__container__client__review__boxContainer__rating__profile__information__name {
    font: normal normal bold 17px/20px Yu Gothic;
  }
}
@media (max-width: 375px) {
  .clientreview__container__client__review__boxContainer__rating__profile__information__name {
    font: normal normal bold 14px/15px Yu Gothic;
  }
}
.clientreview__container__client__review__boxContainer__rating__profile__information__point {
  display: flex;
}
.clientreview__container__client__review__boxContainer__rating__profile__information__point__number {
  font: normal normal normal 19px/37px Roboto;
  color: #000000;
  font-weight: 600;
}
@media (max-width: 466px) {
  .clientreview__container__client__review__boxContainer__rating__profile__information__point__number {
    font: normal normal normal 15px/25px Roboto;
  }
}
@media (max-width: 375px) {
  .clientreview__container__client__review__boxContainer__rating__profile__information__point__number {
    font: normal normal bold 13px/25px Yu Gothic;
  }
}
.clientreview__container__client__review__boxContainer__rating__profile__information__point__iconbox {
  font: normal normal normal 18px/37px Roboto;
  margin-top: 0.3rem;
  color: #dbc37a;
}
@media (max-width: 466px) {
  .clientreview__container__client__review__boxContainer__rating__profile__information__point__iconbox {
    font: normal normal normal 0px/0px Roboto;
    position: relative;
    bottom: 0.29rem;
    font-size: 1px;
  }
}
@media (max-width: 375px) {
  .clientreview__container__client__review__boxContainer__rating__profile__information__point__iconbox {
    font-size: 0px;
  }
}
.clientreview__container__client__review__boxContainer__rating__container {
  display: flex;
}
.clientreview__container__client__review__boxContainer__rating__container__days {
  font: normal normal normal 18px/63px Roboto;
  color: #000000;
  opacity: 0.5;
  margin-right: 1rem;
}
@media (max-width: 466px) {
  .clientreview__container__client__review__boxContainer__rating__container__days {
    position: relative;
    left: 0.8rem;
    font-size: 12px;
    position: relative;
    bottom: 1.25rem;
  }
}
@media (max-width: 375px) {
  .clientreview__container__client__review__boxContainer__rating__container__days {
    font: normal normal bold 14px/15px Yu Gothic;
    position: relative;
    left: 0.8rem;
    font-size: 12px;
    position: relative;
    bottom: 0rem;
  }
}
.clientreview__container__client__review__boxContainer__rating__container__icon {
  position: relative;
  top: 1.3rem;
  color: #ff0000;
  cursor: pointer;
}
@media (max-width: 768px) {
  .clientreview__container__client__review__boxContainer__rating__container__icon {
    display: none;
  }
}
.clientreview__container__client__review__boxContainer__paragraph {
  word-wrap: none;
  word-break: none;
  padding: 0rem 1rem;
  text-align: justify;
  margin-bottom: 1rem;
  color: #000000;
  opacity: 0.5;
  position: relative;
  bottom: 0.7rem;
}
.clientreview__container__totalrating {
  width: 33.5%;
  background-color: #f7f5f5;
  margin-left: 1rem;
}
@media (max-width: 1310px) {
  .clientreview__container__totalrating {
    width: 38%;
    height: 100%;
  }
}
@media (max-width: 1310px) {
  .clientreview__container__totalrating {
    width: 100%;
    background-color: transparent;
  }
}
@media (max-width: 768px) {
  .clientreview__container__totalrating {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.clientreview__container__totalrating__section {
  padding: 2rem 1rem;
}
.clientreview__container__totalrating__section__title {
  font: normal normal medium 30px/28px Poppins;
  letter-spacing: 0.6px;
  color: #193d66;
}
.clientreview__container__totalrating__section__box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 13px;
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
}
.clientreview__container__totalrating__section__box__container {
  display: flex;
  justify-content: space-between;
}
.clientreview__container__totalrating__section__box__container__title {
  font: normal normal medium 500px/28px Poppins;
  letter-spacing: 0.6px;
  color: #363636;
  font-size: 20px;
}
.clientreview__container__totalrating__section__box__container__trending {
  width: 100px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.6rem;
  background: #e2f1d4 0% 0% no-repeat padding-box;
  border-radius: 17px;
  align-items: center;
  height: 25px;
}
.clientreview__container__totalrating__section__box__container__trending__percentage {
  font: normal normal normal 16px/0px Poppins;
  letter-spacing: 0.36px;
  color: #6eb829;
}
.clientreview__container__totalrating__section__box__container__trending__icon {
  color: #6eb829;
  position: relative;
  top: 0.2rem;
}
.clientreview__container__totalrating__section__box__paragraph {
  font: normal normal normal 15px/25px Poppins;
  letter-spacing: 0.36px;
  color: #363636;
  opacity: 0.5;
  position: relative;
  top: 0.2rem;
}
.clientreview__container__totalrating__section__averageratingtitle {
  font: normal normal medium 30px/28px Poppins;
  letter-spacing: 0.6px;
  color: #193d66;
  margin-top: 1rem;
}
.clientreview__container__totalrating__section__averageratingcontainer {
  display: flex;
}
.clientreview__container__totalrating__section__averageratingcontainer__title {
  width: 27%;
  font: normal normal medium 500px/28px Poppins;
  letter-spacing: 0.6px;
  color: #363636;
  font-size: 20px;
}
.clientreview__container__totalrating__section__averageratingcontainer__icon {
  color: #d7bd73;
  position: relative;
}
.clientreview__container__totalrating__section__ratingstat {
  display: flex;
  align-items: center;
}
.clientreview__container__totalrating__section__ratingstat__ratingpoint {
  align-items: center;
  display: flex;
  position: relative;
  top: 0.3rem;
}
.clientreview__container__totalrating__section__ratingstat__ratingpoint__icon {
  margin-top: 0.28rem;
}
.clientreview__container__totalrating__section__ratingstat__ratingpoint__number {
  margin-left: 0.8rem;
  font: normal normal medium 24px/28px Poppins;
  letter-spacing: 0.48px;
  color: #363636;
  opacity: 1;
  font-size: 17px;
}
.clientreview__container__totalrating__section__ratingstat__border {
  width: 90%;
  border: 4px solid #39b49d;
  border-radius: 100px;
  height: 1px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.clientreview__container__totalrating__section__ratingstat__border__one {
  width: 65%;
  border: 4px solid #e84de3;
  border-radius: 100px;
  height: 1px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.clientreview__container__totalrating__section__ratingstat__border__two {
  width: 55%;
  border: 4px solid #efc153;
  border-radius: 100px;
  height: 1px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.clientreview__container__totalrating__section__ratingstat__border__three {
  width: 45%;
  border: 4px solid #42bfe8;
  border-radius: 100px;
  height: 1px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.clientreview__container__totalrating__section__ratingstat__border__four {
  width: 35%;
  border: 4px solid #f72253;
  border-radius: 100px;
  height: 1px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
.clientreview__container__totalrating__section__ratingstat__number {
  width: 10%;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}
.clientreview__container__totalrating__section__ratingstat__number span {
  letter-spacing: 0.48px;
  color: #363636;
  opacity: 1;
  font-size: 17px;
}

.clientreview__container__client__review::-webkit-scrollbar {
  width: 5px;
}

.clientreview__container__client__review::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

.clientreview__container__client__review::-webkit-scrollbar-thumb {
  background: #537198;
  border-radius: 10px;
}

.clientreview__container__client__review::-webkit-scrollbar-thumb:hover {
  background: #537198;
}

.myvideo {
  margin-top: 5rem;
  margin-left: 250px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .myvideo {
    margin-left: 0px;
  }
}
.myvideo__container {
  width: 100%;
}
.myvideo__container__section {
  display: flex;
  padding: 0rem 0rem;
  width: 100%;
  margin-bottom: 1rem;
}
.myvideo__container__section__noVideo {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.myvideo__container__section__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow-y: auto;
  width: 75%;
  gap: 1rem;
  padding: 1rem;
  height: 85vh;
}
@media (max-width: 1130px) {
  .myvideo__container__section__container {
    width: 60%;
  }
}
@media (max-width: 900px) {
  .myvideo__container__section__container {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .myvideo__container__section__container {
    width: 100%;
    overflow-y: hidden;
    height: 100%;
    justify-content: center;
  }
}
.myvideo__container__section__container__greenbox {
  display: none;
}
@media (max-width: 900px) {
  .myvideo__container__section__container__greenbox {
    width: 100%;
    display: block;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1607843137);
    border: 1px solid #7FC550;
    border-radius: 10px;
    opacity: 1;
    margin-top: 1rem;
  }
}
.myvideo__container__section__container__box {
  width: 300px;
  margin-top: 0.8rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid rgba(112, 112, 112, 0.031372549);
  border-radius: 17px;
  position: relative;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .myvideo__container__section__container__box {
    width: 250px;
  }
}
@media (max-width: 768px) {
  .myvideo__container__section__container__box {
    width: 85%;
  }
}
.myvideo__container__section__container__box:hover .myvideo__container__section__container__box__hovercontainer {
  opacity: 0.76;
  height: 40px;
}
.myvideo__container__section__container__box__hovercontainer {
  opacity: 0;
  position: absolute;
  width: 100%;
  border: 1px solid rgba(112, 112, 112, 0.031372549);
  cursor: pointer;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  background: #2e3d4e;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
  height: 40px;
}
.myvideo__container__section__container__box__hovercontainer__icon {
  color: #FFFFFF;
  text-align: end;
  padding: 0.5rem;
  font-weight: bold;
}
.myvideo__container__section__container__box video {
  width: 100%;
  height: 170px !important;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
}
.myvideo__container__section__container__box__paragraph {
  padding: 0rem 0.8rem;
  letter-spacing: 0px;
  color: #000000;
  font-size: 13px;
  word-wrap: break-word;
  text-align: justify;
}
.myvideo__container__section__container__box__viewscontainer {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.8rem;
  font: normal normal medium 0px/15px Montserrat;
  letter-spacing: 0px;
  color: #6f6969;
  font-size: 12px;
}
.myvideo__uploadvideo {
  background-color: #FFFFFF;
  width: 32%;
  position: relative;
  bottom: 1.5rem;
}
@media (max-width: 1130px) {
  .myvideo__uploadvideo {
    width: 45%;
  }
}
@media (max-width: 900px) {
  .myvideo__uploadvideo {
    width: 55%;
  }
}
.myvideo__uploadvideo__section {
  padding: 2rem 1rem;
}
.myvideo__uploadvideo__section__greenbox {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #7FC550;
  border-radius: 10px;
  opacity: 1;
}
.myvideo__uploadvideo__section__greenbox__container {
  padding: 0.3rem;
  display: flex;
  justify-content: space-between;
}
.myvideo__uploadvideo__section__greenbox__container__textfileldcontainer {
  display: flex;
}
.myvideo__uploadvideo__section__greenbox__container__textfileldcontainer__icon {
  position: relative;
  top: 0.4rem;
  color: #222222;
  cursor: pointer;
}
.myvideo__uploadvideo__section__greenbox__container__textfileldcontainer__textfiled input {
  margin-top: 0.5rem;
  outline: unset;
  border: unset;
  font-weight: 600;
  padding-right: 40px;
  background-color: unset;
  border-radius: 0.5rem;
  width: 100%;
  padding-left: 0.5rem;
  font: normal normal normal 11px/19px Roboto;
  letter-spacing: 0px;
  color: #222222;
  align-items: center;
}
.myvideo__uploadvideo__section__greenbox__container__buttonbox {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 30%;
}
.myvideo__uploadvideo__section__greenbox__container__buttonbox__button {
  background-color: #193D66 !important;
  width: 250px !important;
  columns: #FFFFFF !important;
  border-radius: 0.4rem !important;
  margin-top: 0rem !important;
  color: #FFFFFF !important;
  font-size: 11px !important;
}
.myvideo__uploadvideo__section__borderbox {
  margin-top: 1.5rem;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 12px;
  padding: 1rem 1.5rem;
}
.myvideo__uploadvideo__section__borderbox__title {
  position: relative;
  bottom: 0.2rem;
  font: normal normal bold 15px/18px Roboto;
  letter-spacing: 0px;
  color: #000000;
}
.myvideo__uploadvideo__section__borderbox__dashedborderbox {
  margin-top: 0.5rem;
  border: 3px dashed #768ead !important;
  border-radius: 10px;
  border-width: 0.15rem;
  padding: 1.5rem 0rem;
  background-color: #FFFFFF;
  border-spacing: 15px 50px;
}
.myvideo__uploadvideo__section__borderbox__dashedborderbox input {
  display: none;
}
.myvideo__uploadvideo__section__borderbox__dashedborderbox__container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.myvideo__uploadvideo__section__borderbox__dashedborderbox__container__icon {
  font-size: 10px;
  color: #000000;
}
.myvideo__uploadvideo__section__borderbox__dashedborderbox__container__text {
  font: normal normal bold 13px/18px Roboto;
  letter-spacing: 0px;
  color: #000000;
}
.myvideo__uploadvideo__section__borderbox__dashedborderbox__buttonbox {
  margin-top: 0.5rem;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
  margin-left: 0.3rem;
}
.myvideo__uploadvideo__section__borderbox__dashedborderbox__buttonbox__button {
  background-color: #193D66 !important;
  width: 145px !important;
  columns: #FFFFFF !important;
  padding: 0.6rem 1rem !important;
  border-radius: 0.4rem !important;
  margin-top: 0rem !important;
  color: #FFFFFF !important;
  font-size: 11px !important;
}
.myvideo__uploadvideo__section__greeninput {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  border: 2px solid #87C85B;
  border-radius: 0.7rem;
  padding: 0.3rem 1rem;
  margin-bottom: 0rem;
  width: 100%;
  background-color: #FFFFFF;
}
.myvideo__uploadvideo__section__greeninput label {
  color: #4B4A4A;
  font-size: 13px;
  font-weight: 600;
}
.myvideo__uploadvideo__section__greeninput select {
  outline: unset;
  border: unset;
  font-weight: 600;
  color: #222222;
  opacity: 0.5;
  margin-top: 0.6rem;
  width: 100%;
}
.myvideo__uploadvideo__section__greenTextarea {
  border: 2px solid #87C85B;
  border-radius: 0.7rem;
  padding: 0.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  background-color: #FFFFFF;
}
.myvideo__uploadvideo__section__greenTextarea label {
  color: #4B4A4A;
  font-size: 13px;
  font-weight: 600;
}
.myvideo__uploadvideo__section__greenTextarea__textBox {
  margin-top: 0.3rem;
}
.myvideo__uploadvideo__section__greenTextarea__textBox textarea {
  border: unset;
  outline: unset;
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  color: #222222;
  opacity: 0.5;
}
.myvideo__uploadvideo__section__greenTextarea__textBox textarea::placeholder {
  font-weight: normal;
}
.myvideo__uploadvideo__section__uploadbuttoncontainer {
  margin-top: 1.5rem;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
}
.myvideo__uploadvideo__section__uploadbuttoncontainer__button {
  background-color: #193D66 !important;
  width: 270px !important;
  columns: #FFFFFF !important;
  padding: 0.6rem 2rem !important;
  border-radius: 0.4rem !important;
  margin-top: 0rem !important;
  color: #FFFFFF !important;
  font-size: 11px !important;
}

.myvideo__container__section__container::-webkit-scrollbar {
  width: 5px;
}

.myvideo__container__section__container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFFFFF;
  border-radius: 10px;
}

.myvideo__container__section__container::-webkit-scrollbar-thumb {
  background: #537198;
  border-radius: 10px;
}

.myvideo__container__section__container::-webkit-scrollbar-thumb:hover {
  background: #537198;
}

.tab__container {
  width: 100% !important;
}

@media (max-width: 768px) {
  .tab__container {
    position: relative;
    bottom: 3px;
  }
  .css-heg063-MuiTabs-flexContainer {
    position: static;
    width: 100%;
  }
  .css-old1by {
    padding: 3rem 0rem;
  }
  .MuiTabs-scroller {
    width: 100% !important;
  }
}
.videoStat__container {
  padding: 3rem;
}
@media (max-width: 1000px) {
  .videoStat__container {
    padding: 3rem 0rem;
  }
}
@media (max-width: 900px) {
  .videoStat__container {
    padding: 3rem 2rem;
    flex-wrap: nowrap;
  }
}
.videoStat__container__tabeleHeader {
  background: rgba(133, 152, 173, 0.1607843137);
  position: relative;
  bottom: 1.5rem;
  padding: 1rem;
}
.videoStat__container__tabeleHeader__tablecell {
  font: normal normal bold 15px/18px Roboto !important;
  color: #000000 !important;
}
@media (max-width: 555px) {
  .videoStat__container__tabeleHeader__tablecell {
    min-width: 200px !important;
    flex-wrap: nowrap !important;
    font: normal normal bold 12px/18px Roboto !important;
  }
}
.videoStat__container__tableBody {
  background-color: #FFFFFF !important;
  border: 1px solid #FFFFFF !important;
  border-radius: 100px !important;
}
.videoStat__container__tableBody__tablecell {
  font: normal normal normal 14px/17px Roboto !important;
  color: #000000 !important;
}

@media (max-width: 721px) {
  .css-1pclhgl-MuiTable-root {
    width: 50% !important;
    min-width: 0px;
  }
}

.serviceHour {
  margin-top: 5rem;
  margin-left: 250px;
  padding: 1rem;
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .serviceHour {
    padding: 1rem 1rem;
    margin-left: 0;
  }
}
.serviceHour__buttonBox {
  display: flex !important;
  justify-content: end !important;
  width: 100% !important;
  cursor: pointer !important;
}
@media (max-width: 900px) {
  .serviceHour__buttonBox {
    display: none !important;
  }
}
.serviceHour__buttonBox__button {
  background-color: #193d66 !important;
  columns: #FFFFFF !important;
  padding: 0.6rem 2rem !important;
  border-radius: 0.5rem !important;
  color: white !important;
  align-items: center !important;
  cursor: pointer !important;
}
.serviceHour__table {
  background-color: #f8f8f8;
  border-radius: 10px;
  overflow: auto;
  width: 100%;
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .serviceHour__table {
    display: none;
  }
}
.serviceHour__table__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  overflow: auto;
  gap: 1rem;
}
@media (max-width: 550px) {
  .serviceHour__table__container {
    width: 160%;
  }
}
@media (max-width: 450px) {
  .serviceHour__table__container {
    width: 250%;
  }
}
.serviceHour__table__days {
  color: #363636;
  font-family: "Poppins", sans-serif !important;
}
.serviceHour__table__title {
  color: #363636;
  font-family: "Poppins", sans-serif !important;
}
.serviceHour__table__textField {
  width: 20%;
}
@media (max-width: 768px) {
  .serviceHour__table__textField {
    width: 100%;
  }
}
.serviceHour__table__textField__greenInput {
  display: flex;
  flex-direction: column;
  border: 2px solid #87C85B;
  background-color: #ffffff;
  border-radius: 0.7rem;
  width: 100%;
}
.serviceHour__table__textField__greenInput label {
  color: #444444;
  font-size: 10px;
  font-weight: 600;
  position: relative !important;
  left: 1rem !important;
}
.serviceHour__table__textField__greenInput__inputBox {
  display: flex;
  position: relative;
  left: 1rem;
}
.serviceHour__table__textField__greenInput__inputBox input {
  position: relative;
  bottom: 0.5rem;
  margin-top: 0.8rem;
  outline: unset;
  border: unset;
  color: #444444;
  font-weight: lighter;
  font-size: 12px;
  padding-right: 40px;
  background-color: unset;
  border-radius: 0.5rem;
  width: 100%;
  padding-left: 0rem;
}
.serviceHour__table__specialHour {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  cursor: pointer !important;
  margin-top: 1rem;
}
.serviceHour__table__specialHour__button {
  background-color: #193d66 !important;
  columns: #FFFFFF !important;
  padding: 0.6rem 2rem !important;
  border-radius: 0.5rem !important;
  color: white !important;
  align-items: center !important;
  cursor: pointer !important;
  width: 20% !important;
}
.serviceHour__buttonbox {
  display: none !important;
}
@media (max-width: 900px) {
  .serviceHour__buttonbox {
    display: block !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 1rem !important;
  }
}
.serviceHour__button {
  background-color: #193d66 !important;
  columns: #FFFFFF !important;
  padding: 0.6rem 2rem !important;
  border-radius: 0.5rem !important;
  color: white !important;
  align-items: center !important;
  cursor: pointer !important;
}

.serviceHour__table__container::-webkit-scrollbar {
  width: 5px;
}

@media (max-width: 768px) {
  .serviceHour__table__container::-webkit-scrollbar {
    width: 0 !important;
  }
}
.serviceHour__table__container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFFFFF;
  border-radius: 10px;
}

.serviceHour__table__container::-webkit-scrollbar-thumb {
  background: #717171;
  border-radius: 10px;
}

.serviceHour__table__container::-webkit-scrollbar-thumb:hover {
  background: #717171;
  height: 10px;
}

.responsivServiceHour {
  display: none;
}
@media (max-width: 768px) {
  .responsivServiceHour {
    display: block;
    background-color: #f8f8f8;
    margin-top: 2rem;
    border-radius: 10px;
    overflow: auto;
  }
  .responsivServiceHour__container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
  }
  .responsivServiceHour__container__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .responsivServiceHour__container__label {
    color: #444444;
    font-size: 17px;
    font-weight: 600;
  }
  .responsivServiceHour__container__time {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .responsivServiceHour__container::-webkit-scrollbar {
    width: 0 !important;
  }
}
.responsivServiceHour__container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFFFFF;
  border-radius: 10px;
}

.responsivServiceHour__container::-webkit-scrollbar-thumb {
  background: #717171;
  border-radius: 10px;
}

.responsivServiceHour__container::-webkit-scrollbar-thumb:hover {
  background: #717171;
  height: 10px;
}

.noServiceHour_title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #193d66;
  height: 73vh;
}
@media (max-width: 768px) {
  .noServiceHour_title {
    display: none;
  }
}

.createServiceHour_title {
  display: none;
}
@media (max-width: 768px) {
  .createServiceHour_title {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #193d66;
    height: 73vh;
  }
}

@media (max-width: 900px) {
  .serviceHour__container {
    position: relative !important;
    bottom: 0rem !important;
  }
}

.myPhoto {
  margin-top: 5rem;
  margin-left: 250px;
  padding: 1rem;
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .myPhoto {
    padding: 1rem 1rem;
    margin-left: 0;
  }
}
.myPhoto__title {
  color: #193D66;
}
.myPhoto__container {
  margin-top: 2rem;
}
.myPhoto__container__body {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  bottom: 1rem;
  gap: 0.4rem;
}
@media (max-width: 650px) {
  .myPhoto__container__body {
    justify-content: center;
  }
}
.myPhoto__container__body__label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  height: 200px;
  width: 200px;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
@media (max-width: 600px) {
  .myPhoto__container__body__label {
    width: 100%;
  }
}
.myPhoto__container__body__label__icon {
  color: #565656;
  font-size: 2.5rem !important;
}
.myPhoto__container__body p {
  color: #565656;
  font-weight: 600;
  margin-bottom: 1rem;
  font: normal normal bold 12px/18px Roboto;
}
.myPhoto__container__body__image {
  position: relative;
}
.myPhoto__container__body__image__tooltip {
  position: absolute;
  top: 0;
  right: 0.5rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background: #476485;
  width: 200px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0.5rem 0.5rem 0px 0px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 650px) {
  .myPhoto__container__body__image__tooltip {
    width: 100%;
    right: 0rem;
  }
}
.myPhoto__container__body img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 1rem;
  cursor: pointer;
}
@media (max-width: 650px) {
  .myPhoto__container__body img {
    width: 100%;
    height: auto;
  }
}
.myPhoto__container input {
  visibility: hidden;
}

.myPhoto__container__body__image:hover .myPhoto__container__body__image__tooltip {
  visibility: visible;
  opacity: 1;
  margin-top: 1rem;
}

.support {
  margin-top: 5rem;
  margin-left: 250px;
  padding: 1rem 2rem;
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .support {
    padding: 1rem 1rem;
    margin-left: 0;
  }
}
.support__container {
  display: flex;
  justify-content: space-between;
}
.support__container__title {
  color: #193d66;
}
.support__container__composeButton {
  background-color: #193d66 !important;
  border: none !important;
  color: white !important;
  padding: 0.5rem 1.5rem !important;
  border-radius: 6px !important;
  cursor: pointer !important;
}
.support__form {
  margin-top: 2rem;
}
.support__form__searchBox {
  margin-top: 1.5rem;
  background-color: #FFFFFF;
  width: 100%;
  padding: 0.2rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #87C85B;
}
.support__form__searchBox__search__searchLabel {
  color: #858585;
  font-size: 0.9rem !important;
  margin-right: 0.5rem;
}
.support__form__searchBox__search__searchInput {
  width: 100%;
  outline: unset;
  border: unset;
  margin-right: 0.5rem;
}
.support__form__button {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.support__form__button__sendButton {
  background-color: #193D66 !important;
  text-transform: unset !important;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  position: relative;
  right: 1rem !important;
  font-size: 1.2rem !important;
}

.tox {
  position: relative !important;
  top: 2rem !important;
  height: 300px !important;
  border: 1px solid #87C85B !important;
}

.tox-tinymce-aux {
  display: none;
}

.mybatch {
  margin-top: 5rem;
  margin-left: 250px;
  padding: 1rem;
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .mybatch {
    padding: 1rem 1rem;
    margin-left: 0;
  }
}
.mybatch__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  gap: 1rem;
}
@media (max-width: 768px) {
  .mybatch__container {
    justify-content: center;
  }
}
.mybatch__container__box {
  background: #193d66 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid rgba(180, 182, 184, 0.2509803922);
  border-radius: 15px;
  opacity: 1;
  width: 290px;
}
.mybatch__container__box__title {
  text-align: center;
  font: normal normal normal 13px/30px Poppins;
  letter-spacing: 0px;
  color: #888b8d;
  opacity: 1;
  margin-bottom: 0.5rem;
}
.mybatch__container__box__messsauretitle {
  text-align: center;
  font: normal normal medium 13px/30px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0.5rem;
}
.mybatch__container__box__servicetitle {
  text-align: center;
  font: normal normal bold 20px/39px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.mybatch__container__box__container {
  background: #193d66 0% 0% no-repeat padding-box;
  padding: 1rem;
  color: white;
  clip-path: polygon(48% 16%, 80% 9%, 100% 0, 100% 70%, 100% 100%, 50% 100%, 0 100%, 0% 70%, 0 0, 20% 9%);
}
.mybatch__container__box__container__title {
  text-align: center;
  font: normal normal normal 13px/30px Poppins;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  margin-top: 1rem;
}
.mybatch__container__box__container__paragraph {
  text-align: center;
  font: normal normal normal 13px/30px Poppins;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  margin-top: 0.3rem;
}
.mybatch__container__box__image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 1rem;
}
.mybatch__container__box__image img {
  width: 50px;
  opacity: 1;
  border-radius: 50px;
}

.adFormfirstFormButton {
  display: flex;
  justify-content: end;
}

.adVideoForm {
  display: flex;
  justify-content: end;
}
.adVideoForm Button {
  margin: 10px;
}
.adVideoForm__Button {
  margin-top: 2rem;
}

.newAdd__tablecontainer {
  display: flex;
  background-color: #f8f8f8;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border-radius: 6px;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
@media (max-width: 500px) {
  .newAdd__tablecontainer {
    flex-wrap: wrap;
  }
}
.newAdd__tablecontainer__dollartextfield {
  width: 49%;
}
@media (max-width: 500px) {
  .newAdd__tablecontainer__dollartextfield {
    width: 100%;
  }
}
.newAdd__tablecontainer__dollartextfield__greenInput {
  display: flex;
  flex-direction: column;
  border: 2px solid #87c85b;
  background-color: #ffffff;
  border-radius: 0.7rem;
  padding: 0rem 1rem;
}
.newAdd__tablecontainer__dollartextfield__greenInput label {
  color: #444444;
  font-size: 10px;
  font-weight: 600;
}

.addForm__serviceHourTable {
  background-color: #f8f8f8;
  margin-top: 1rem;
  border-radius: 10px;
  overflow: auto;
  margin-bottom: 1rem;
  text-wrap: nowrap;
}
.addForm__serviceHourTable__container {
  display: flex;
  align-items: center;
  padding: 2rem;
  overflow: auto;
  gap: 1rem;
  justify-content: space-between;
}
@media (max-width: 900px) {
  .addForm__serviceHourTable__container {
    width: 130%;
  }
}
@media (max-width: 550px) {
  .addForm__serviceHourTable__container {
    width: 160%;
  }
}
@media (max-width: 450px) {
  .addForm__serviceHourTable__container {
    width: 250%;
  }
}
.addForm__serviceHourTable__container__day {
  color: #363636;
  font-family: "Poppins", sans-serif !important;
}

.error {
  color: red !important;
}

.drawer {
  display: none;
}
.drawer__sidebar__section {
  position: fixed;
  align-items: center;
  text-align: center;
  padding: 0.5rem 0.5rem;
  background-color: #193D66;
  cursor: pointer;
  display: none;
}
.drawer__sidebar__section :hover {
  background-color: #476485;
  border-radius: 6px;
}
@media (max-width: 768px) {
  .drawer__sidebar__section {
    display: block;
  }
}
@media (max-width: 768px) {
  .drawer__sidebar__section {
    position: unset;
  }
}
.drawer__sidebar__section__myprofile {
  display: flex;
  align-items: center;
  padding: 0.9rem 1.5rem;
  color: #FFFFFF;
  letter-spacing: 0px;
}
.drawer__sidebar__section__myprofile__icon {
  position: relative;
  top: 0.2rem;
}
.drawer__sidebar__section__myprofile__icon__dollar {
  display: flex;
  justify-content: centers;
  background-color: #193D66;
  border-radius: 0.5rem;
  align-items: center;
  position: relative;
  left: 0.6rem;
  padding: 0.5rem;
}
.drawer__sidebar__section__myprofile__name {
  margin-left: 1.7rem;
  font-size: 0.8rem;
  white-space: nowrap;
}

.provider__sidebar__section::-webkit-scrollbar {
  width: 5px;
}

.provider__sidebar__section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFFFFF;
  border-radius: 10px;
}

.provider__sidebar__section::-webkit-scrollbar-thumb {
  background: #193D66;
  border-radius: 10px;
  height: 10px;
}

.provider__sidebar__section::-webkit-scrollbar-thumb:hover {
  height: 10px;
}

@media (max-width: 768px) {
  .providerSidebar {
    display: none;
  }
}

.filter {
  padding: 1rem;
}
.filter__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
}
.filter__header__title {
  height: 40px;
  font-size: 1.4rem;
}
.filter__header__button {
  height: 40px;
}
.filter__title {
  text-align: center;
  color: black;
  margin-bottom: 1rem;
}
.filter__priceRange {
  display: flex;
  width: 100% !important;
  gap: 1rem;
  margin-bottom: 1rem;
}
.filter__priceRange__maximum, .filter__priceRange__minimum {
  display: flex;
  flex-direction: column;
}
.filter__priceRange input {
  width: 100%;
  outline: none;
  padding: 0.4rem;
  border-radius: 6px;
  border: 1px solid #f2f4f7;
}

.css-zqcytf-MuiButtonGroup-root {
  width: 100% !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.css-zqcytf-MuiButtonGroup-root .MuiButtonGroup-grouped {
  width: 50% !important;
}

.MuiSlider-rail {
  background-color: #f2f4f7 !important;
}

.MuiSlider-track {
  background-color: #1754e0 !important;
}

.MuiSlider-thumb {
  background-color: #1754e0 !important;
  width: 13px !important;
  height: 13px !important;
}

.MuiFormControl-root {
  display: flex !important;
}

.css-1hyi1uc-MuiFormGroup-root {
  display: flex !important;
  flex-direction: row !important;
  color: black !important;
  font-weight: bolder !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #193D66 !important;
}

.adsProfile {
  margin-top: 5rem;
}
.adsProfile__profilePhoto {
  height: 0;
}
@media (max-width: 1000px) {
  .adsProfile__profilePhoto {
    height: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
}
.adsProfile__profilePhoto img {
  height: 170px;
  width: 170px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  bottom: 6rem;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .adsProfile__profilePhoto img {
    height: 170px;
    bottom: 0rem;
  }
}
.adsProfile__profileData {
  display: flex;
  margin-left: 3rem;
  gap: 1rem;
}
@media (max-width: 1000px) {
  .adsProfile__profileData {
    margin-left: 0rem;
  }
}
@media (max-width: 1000px) {
  .adsProfile__profileData {
    flex-direction: column;
  }
}
.adsProfile__profileData__description {
  width: 100%;
}
@media (max-width: 1000px) {
  .adsProfile__profileData__description {
    padding: 3rem;
    margin-top: 1rem;
  }
}
.adsProfile__profileData__description__rating {
  display: flex;
  align-items: center;
}
.adsProfile__profileData__description__rating__star {
  margin-top: 0.5rem !important;
}
.adsProfile__profileData__title {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  color: #193D66;
  word-break: break-all;
}
.adsProfile__profileData__title:hover {
  font-style: italic;
  text-decoration: underline !important;
  cursor: pointer;
}
.adsProfile__profileData__img {
  width: 40px;
}
.adsProfile__profileData__location {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #193D66;
  font-weight: bolder;
}
.adsProfile__profileData__Information {
  background-color: #193d66;
  border-radius: 1rem;
  padding: 0.2rem 1rem;
  margin-bottom: 0.3rem;
  font-weight: 500;
  color: white;
  width: max-content;
  font-size: 11px;
  text-transform: capitalize;
}
.adsProfile__profileData:hover {
  cursor: pointer;
  filter: blur(0.5px);
}

.serviceHour__tab {
  width: 23%;
  transition: all 1s;
}

.rate__tab {
  width: 23%;
  transition: all 1s;
}

.photo__tab {
  width: 23%;
  transition: all 1s;
}

.video__tab {
  width: 23%;
  transition: all 1s;
}

@media only screen and (max-width: 550px) {
  .serviceHour__tab {
    width: 50%;
    transition: all 1s;
  }
  .rate__tab {
    width: 50%;
    transition: all 1s;
  }
  .photo__tab {
    width: 50%;
    transition: all 1s;
  }
  .video__tab {
    width: 50%;
    transition: all 1s;
  }
}
.profileTab {
  padding: 1.5rem !important;
}
.profileTab__container {
  border: 1px solid #e5f3dc !important;
  background-color: #e5f3dc !important;
  border-radius: 50px !important;
  margin-top: 0.5rem !important;
}

.flexdata {
  display: flex;
  align-items: center;
  justify-content: center;
}

.font__style {
  font-style: italic;
  font-weight: bolder;
}

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #193d66;
  color: white;
  text-align: center;
  position: relative;
}

.not-found-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Add a dark overlay for better text readability */
  z-index: 0;
}

.not-found-content {
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.icon-container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container svg {
  font-size: 4rem;
  color: #ffc107; /* Change icon color to a contrasting shade */
}

.not-found-content h1 {
  font-size: 6rem;
  margin: 0;
  animation: bounce 2s infinite;
}

.not-found-content p {
  font-size: 1.5rem;
  margin: 1rem 0;
  animation: slideUp 1s ease-in-out;
}

.home-link {
  text-decoration: none;
}

.home-button {
  font-size: 1rem;
  padding: 0.8rem 1.5rem;
  color: #193d66; /* Change text color to match background */
  background-color: #ffc107; /* Button background color */
  border: none;
  border-radius: 5px;
  transition: background 0.3s, transform 0.3s;
  animation: pulse 1s infinite;
}

.home-button:hover {
  background-color: #e0a806; /* Darken button background on hover */
  transform: scale(1.1);
}

/* Animations */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.noVideo__title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  color: #193d66;
}

.create_title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #193d66;
  height: 75%;
}
@media (max-width: 768px) {
  .create_title {
    display: none;
  }
}

.clientProfile {
  display: flex;
  margin: 9rem 2rem 5rem 2rem;
  justify-content: center;
}
.clientProfile__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 2rem;
}
@media (max-width: 1000px) {
  .clientProfile__container {
    width: 100%;
  }
}
.clientProfile__container__image__box {
  display: flex;
  justify-content: center;
}
.clientProfile__container__image img {
  width: 100%;
  height: 250px;
  box-shadow: 3px 4px 7px rgba(0, 0, 0, 0.2901960784);
  object-fit: cover;
  border-radius: 1rem;
}
@media (max-width: 500px) {
  .clientProfile__container__image img {
    width: 80%;
  }
}
.clientProfile__container__image__editIcon {
  display: flex;
  justify-content: center;
  background-color: #7FC550;
  color: #FFFFFF;
  cursor: pointer;
  position: relative;
  right: 1.5rem;
  height: 40px;
  width: 40px;
  align-items: center;
  text-align: center;
  bottom: 0.8rem;
  clip-path: circle(40%);
}
.clientProfile__container__title {
  font: normal normal bold 27px/10px Yu Gothic;
  color: #4B4A4A;
  margin-right: 1rem;
  margin-top: 2rem;
}
.clientProfile__container__text {
  font: normal normal normal 21px/0px Poppins;
  color: #4E4E4E;
  margin-top: 1.5rem;
  opacity: 0.8;
  margin-right: 2.2rem;
}
.clientProfile__container__about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
}
.clientProfile__container__about__email__container {
  display: flex;
  margin-top: 2rem;
}
.clientProfile__container__about__email__container__icon {
  color: #4E4E4E !important;
}
.clientProfile__container__about__email__container h4 {
  margin-top: 0.2rem;
  margin-left: 1rem;
  font: normal normal normal 19px/20px Poppins;
  color: #4E4E4E;
}
@media (max-width: 400px) {
  .clientProfile__container__about__email__container h4 {
    font: normal normal normal 16px/20px Poppins;
  }
}
.clientProfile__container__about__contact__container {
  display: flex;
  margin-top: 1rem;
}
@media (max-width: 600px) {
  .clientProfile__container__about__contact__container {
    margin-right: 0rem;
  }
}
.clientProfile__container__about__contact__container__callIcon {
  color: #4E4E4E;
  margin-top: 0.2rem;
}
.clientProfile__container__about__contact__container__country__image {
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clientProfile__container__about__contact__container__country__image img {
  width: 30px;
}
.clientProfile__container__about__contact__container h4 {
  color: #4E4E4E;
  margin-left: 0.4rem;
  font: normal normal normal 18px/30px Poppins;
}
@media (max-width: 400px) {
  .clientProfile__container__about__contact__container h4 {
    font: normal normal normal 16px/20px Poppins;
    margin-top: 0.5rem;
  }
}
.clientProfile__container__about__locationInformation {
  display: flex;
  margin-top: 1rem;
}
@media (max-width: 600px) {
  .clientProfile__container__about__locationInformation {
    margin-right: 0rem;
  }
}
.clientProfile__container__about__locationInformation__cityIcon {
  color: #4E4E4E;
}
.clientProfile__container__about__locationInformation h4 {
  font: normal normal normal 18px/27px Poppins;
  margin-left: 1rem;
  color: #4E4E4E;
}
@media (max-width: 400px) {
  .clientProfile__container__about__locationInformation h4 {
    font: normal normal normal 16px/20px Poppins;
  }
}
.clientProfile__container__about__container {
  width: 60%;
  margin-top: 0.5rem;
  margin-left: 3rem;
}
@media (max-width: 768px) {
  .clientProfile__container__about__container {
    margin-left: 0rem;
    width: 70%;
  }
}
@media (max-width: 600px) {
  .clientProfile__container__about__container {
    width: 100%;
  }
}
.clientProfile__container__about__container h4 {
  font-size: 23px;
  color: #4E4E4E;
  position: relative;
  right: 1rem;
}
.clientProfile__container__about__container__paragraph {
  text-align: justify;
  word-wrap: none;
  width: 80%;
  margin-top: 0.5rem;
  font: normal normal normal 17px/25px Poppins;
  color: #4E4E4E;
}
.clientProfile__profilesetting {
  width: 30%;
  background-color: #f7f5f5;
  padding: 1rem;
}
@media (max-width: 1000px) {
  .clientProfile__profilesetting {
    background-color: transparent;
    width: auto;
    margin-top: 1rem;
  }
}
.clientProfile__profilesetting__section__title {
  text-align: left;
  margin-top: 0.5rem;
  font: normal normal medium 100px/28px Poppins;
  letter-spacing: 0.6px;
  color: #193D66;
  opacity: 1;
  font-size: 25px;
  font-weight: 600;
}
.clientProfile__profilesetting__section__box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  border-radius: 12px 12px 0px 0px;
  margin-top: 1rem;
}
.clientProfile__profilesetting__section__box__container {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font: normal normal 600 15px/24px Yu Gothic;
  color: #4B4A4A;
  border-bottom: 1px solid #e9e9e9;
  padding: 0.5rem;
  cursor: pointer;
}
.clientProfile__profilesetting__section__box__container__logouttitle {
  color: red;
}
.clientProfile__profilesetting__section__box__container__icon {
  color: #e9e9e9;
  cursor: pointer;
}

.privacyPolicy {
  margin: 120px 70px 20px 70px;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 768px) {
  .privacyPolicy {
    margin: 0px;
    border: unset;
    border-radius: unset;
    align-items: unset;
  }
}
.privacyPolicy__content {
  padding: 15px 0px;
}
.privacyPolicy__content h2 {
  padding: 15px 0px;
}
@media (max-width: 768px) {
  .privacyPolicy__content h2 {
    padding: 0px;
  }
  .privacyPolicy__content p {
    padding-left: 0px;
  }
}
.privacyPolicy__content__heading3 {
  padding-left: 20px;
}
.privacyPolicy__content__heading3 h3 {
  margin: 10px 0px;
}

.termsConditions {
  margin: 120px 70px 20px 70px;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 768px) {
  .termsConditions {
    margin: 0px;
    border: unset;
    border-radius: unset;
    align-items: unset;
  }
}
.termsConditions__content {
  padding: 15px 0px;
}
.termsConditions__content h2 {
  padding: 15px 0px;
}
@media (max-width: 768px) {
  .termsConditions__content h2 {
    padding: 0px;
  }
  .termsConditions__content p {
    padding-left: 0px;
  }
}
.termsConditions__content__heading3 {
  padding-left: 20px;
}
.termsConditions__content__heading3 h3 {
  margin: 10px 0px;
}

.clientAdPorfile {
  display: flex;
  animation: fadeIn 1s ease-in-out;
  margin: 0 40px;
  flex-direction: column;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.clientAdPorfile__profile__image {
  width: 100%;
  padding: 0px 10px;
}
.clientAdPorfile__profile__image__box {
  width: 100%;
  display: flex;
  justify-content: center;
}
.clientAdPorfile__profile__image img {
  width: 100%;
  box-shadow: 3px 4px 7px rgba(0, 0, 0, 0.2901960784);
  object-fit: cover;
  margin-top: 2rem;
  border-radius: 1rem;
  height: 330px;
}
.clientAdPorfile__profile__image__heartIcon {
  display: none;
}
.clientAdPorfile__profile__name {
  background: #7fc550;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
  margin-top: 2.5rem;
}
.clientAdPorfile__profile__name__title {
  width: 55%;
  text-align: center;
  font: normal normal bold 35px/30px Yu Gothic;
}
.clientAdPorfile__profile__name__heartIcon {
  color: #193D66;
  display: flex;
}
.clientAdPorfile__profile__name__messageIcon {
  display: none;
}
.clientAdPorfile__profile__rating {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  margin-top: 0.5rem;
}
.clientAdPorfile__profile__rating__number {
  color: #4E4E4E;
  font: normal normal normal 22px/10px Yu Gothic;
  margin-right: 0.2rem;
  letter-spacing: 0px;
}
.clientAdPorfile__profile__rating__starIcon {
  color: #dbc37a;
  position: relative;
  top: 0.1rem;
  margin-right: 0.6rem;
  margin-left: 0.3rem;
}
.clientAdPorfile__profile__information {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
}
.clientAdPorfile__profile__information__text {
  font: normal normal normal 17px/15px Poppins;
  color: #4E4E4E;
  letter-spacing: 0px;
  opacity: 0.8;
  margin-right: 0.7rem;
  height: 50px;
}
.clientAdPorfile__clientPortfolio {
  width: 75%;
}
.clientAdPorfile__clientPortfolio__overview {
  width: 100px;
  background: green;
  padding: 10px;
  border-radius: 5px;
  font-style: italic;
}
.clientAdPorfile__clientPortfolio__overview__serviceInfo {
  display: flex;
  justify-content: space-between;
}
.clientAdPorfile__clientPortfolio__overview__serviceInfo__title {
  font: normal normal bold 20px/30px Yu Gothic;
  color: #4E4E4E;
}
.clientAdPorfile__clientPortfolio__overview__serviceInfo__icon {
  color: #4E4E4E;
}
.clientAdPorfile__clientPortfolio__overview__description {
  padding: 20px 10px;
}
.clientAdPorfile__messageIcon {
  width: 5%;
  margin-top: 3rem;
  margin-left: 0.3rem;
  cursor: pointer;
  height: 5vh;
  color: #193D66;
  display: none;
}
.clientAdPorfile__basicInfo {
  margin-top: 5px;
}
.clientAdPorfile__basicInfo__services {
  color: #102843;
  font-size: 22px;
  font-family: math !important;
}
.clientAdPorfile__basicInfo__services__fullName {
  cursor: pointer;
  font-size: 18px;
  font-family: cursive;
  font-style: italic;
  text-decoration: underline;
  color: #7fc550;
}
.clientAdPorfile__mainContainer {
  margin-top: 0px !important;
}
@media only screen and (max-width: 950px) {
  .clientAdPorfile__mainContainer {
    flex-direction: column-reverse !important;
    display: flex !important;
  }
}
.clientAdPorfile__mainContainer__adDetails {
  overflow: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.clientAdPorfile__mainContainer__adDetails__informationContainer {
  margin-top: 10px;
}
.clientAdPorfile__mainContainer__adDetails__informationContainer__heading {
  font-size: 22px !important;
  font-family: cursive !important;
  background-color: #7fc550;
  color: #102843;
  border-radius: 5px;
  padding: 0.2rem 0.4rem;
  font-weight: 600 !important;
}
.clientAdPorfile__mainContainer__adDetails__informationContainer__content {
  margin-top: 10px;
}
.clientAdPorfile__mainContainer__adDetails__informationContainer__content__description {
  font-size: 18px;
  font-family: math !important;
  color: #102843;
  font-style: italic;
  margin-top: 1rem;
}
.clientAdPorfile__mainContainer__adDetails__informationContainer__table {
  margin-top: 20px;
}
@media only screen and (min-width: 950px) {
  .clientAdPorfile__mainContainer__adDetails__informationContainer__table__location iframe {
    width: 800px;
  }
}
.clientAdPorfile__mainContainer__adDetails__title {
  font: normal normal bold 20px/30px Yu Gothic;
  color: #4E4E4E;
}
.clientAdPorfile__mainContainer__adDetails__icon {
  color: #4E4E4E;
}
.clientAdPorfile__mainContainer__adPhotos {
  position: fixed;
  right: 0;
}
@media only screen and (max-width: 950px) {
  .clientAdPorfile__mainContainer__adPhotos {
    position: relative;
  }
}
.clientAdPorfile__mainContainer__adPhotos__container {
  display: flex;
  flex-direction: column !important;
}
.clientAdPorfile__mainContainer__adPhotos__container__contactAt {
  display: flex;
  justify-content: space-between;
  background: #7fc550;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  font-family: cursive !important;
  align-items: center;
}
.clientAdPorfile__mainContainer__adPhotos__container__contactAt__copyButton button {
  color: #102843;
}
.clientAdPorfile__mainContainer__adPhotos__container img {
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
  height: 300px;
}
@media only screen and (max-width: 750px) {
  .clientAdPorfile__mainContainer__adPhotos__container img {
    height: 200px;
  }
}
.clientAdPorfile__mainContainer__adPhotos__list__container img {
  padding: 5px;
  width: 100px;
  height: 100px;
  cursor: pointer;
  border-radius: 8px;
}
@media only screen and (max-width: 750px) {
  .clientAdPorfile__mainContainer__adPhotos__list__container {
    display: flex;
    overflow: scroll;
    gap: 1;
  }
}

.profile__information {
  flex-wrap: wrap;
  animation: fadeIn 1s ease-in-out;
  width: 110%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.profile__information__container__one {
  width: auto;
}
.profile__information__container__one img {
  width: 230px;
  height: 240px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2901960784);
  object-fit: cover;
  border-radius: 1rem;
}

.about {
  padding: 1rem;
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.about__email__container {
  display: flex;
  margin-top: 1rem;
}
.about__email__container__icon {
  color: #4E4E4E;
}
.about__email__container__name {
  margin-top: 0.2rem;
  margin-left: 1rem;
  font: normal normal normal 19px/20px Poppins;
  color: #4E4E4E;
}
.about__contact__container {
  display: flex;
  margin-top: 1.3rem;
}
.about__contact__container__callIcon {
  color: #4E4E4E;
  margin-top: 0.2rem;
}
.about__contact__container__country__image {
  margin-left: 1rem;
}
.about__contact__container__phoneNumber {
  color: #4E4E4E;
  margin-left: 0.4rem;
  font: normal normal normal 18px/30px Poppins;
}
.about__locationInformation {
  display: flex;
  margin-top: 1.2rem;
}
.about__locationInformation__cityIcon {
  color: #4E4E4E;
}
.about__locationInformation__text {
  font: normal normal normal 18px/27px Poppins;
  margin-left: 1rem;
  color: #4E4E4E;
}
.about__container {
  margin-top: 1.5rem;
}
.about__container__title {
  font-size: 23px;
  color: #4E4E4E;
}
.about__container__paragraph {
  text-align: justify;
  margin-top: 0.8rem;
  font: normal normal normal 17px/25px Poppins;
  color: #4E4E4E;
}

.service {
  margin-top: 0rem;
  width: 100% !important;
  transition: all 1s;
  animation: fadeIn 1s ease-in-out;
  height: 60vh;
  overflow-y: scroll;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.service__card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
}
@media only screen and (max-width: 750px) {
  .service__card {
    gap: 0rem;
    justify-content: center;
  }
}
@media only screen and (max-width: 355px) {
  .service__card {
    padding: 0rem;
  }
}
.service__boxContainer {
  height: 40%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2901960784);
  width: 100%;
  border-radius: 2rem;
  cursor: pointer;
}
.service__boxContainer img {
  width: 100%;
  padding: 0.2rem 0.2rem;
  height: 30vh;
  object-fit: cover;
  border-radius: 35px 35px 0px 0px;
}
.service__boxContainer__title {
  display: flex;
  justify-content: center;
  color: #000000;
  font-size: 1.2rem;
}
.service__boxContainer__rating {
  display: flex;
  align-items: center;
  justify-content: center;
}
.service__boxContainer__rating__title {
  color: #000000;
  font: normal normal normal 18px/29px Yu Gothic;
}
.service__boxContainer__rating__starIcon {
  margin-top: 0.1rem;
  color: #dbc37a;
  margin-left: 0.1rem;
}
.service__boxsecondContainer {
  height: 40%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2901960784);
  width: 100%;
  border-radius: 2rem;
}
.service__boxsecondContainer img {
  width: 100%;
  padding: 0.2rem 0.2rem;
  height: 30vh;
  object-fit: cover;
  border-radius: 35px 35px 0px 0px;
}
.service__boxthirdContainer {
  height: 40%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2901960784);
  width: 100%;
  border-radius: 2rem;
}
.service__boxthirdContainer img {
  width: 100%;
  padding: 0.2rem 0.2rem;
  height: 30vh;
  object-fit: cover;
  border-radius: 35pnx 35px 0px 0px;
}
.service__buttonsBox__register {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 106%;
}
.service__buttonsBox__register__button {
  background-color: #193D66 !important;
  width: 250px !important;
  columns: #FFFFFF !important;
  padding: 0.6rem 2rem !important;
  border-radius: 0.5rem !important;
  margin-top: 3rem !important;
}

.review {
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 1s;
  margin-top: 1rem;
  animation: fadeIn 1s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.review__boxContainer {
  height: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2901960784);
  border-radius: 0.8rem;
  width: 96%;
  margin-left: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 750px) {
  .review__boxContainer {
    width: 95%;
    margin-left: 0px;
  }
}
.review__boxContainer__rating {
  display: flex;
  justify-content: space-between;
}
.review__boxContainer__rating__profile {
  display: flex;
}
.review__boxContainer__rating__profile__image img {
  width: 80px;
  height: 80px;
  clip-path: circle(50%);
  object-fit: cover;
}
.review__boxContainer__rating__profile__information {
  margin-left: 1.5rem;
  align-items: center;
}
.review__boxContainer__rating__profile__information__name {
  font: normal normal bold 23px/23px Yu Gothic;
  margin-top: 1rem;
  align-items: center;
  color: #000000;
}
.review__boxContainer__rating__profile__information__point {
  display: flex;
}
.review__boxContainer__rating__profile__information__point__number {
  font: normal normal normal 19px/37px Roboto;
  color: #000000;
  font-weight: 600;
}
.review__boxContainer__rating__profile__information__point__iconbox {
  font: normal normal normal 18px/37px Roboto;
  margin-top: 0.3rem;
  color: #dbc37a;
}
.review__boxContainer__rating__days {
  font: normal normal normal 18px/63px Roboto;
  color: #000000;
  opacity: 0.5;
}
.review__boxContainer__paragraph {
  text-align: justify;
  margin-bottom: 1rem;
  color: #000000;
  opacity: 0.5;
  margin-top: 0.5rem;
}
.review__boxContainer__buttonbox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
}
.review__boxContainer__buttonbox__button {
  position: relative;
  bottom: 10px;
  background-color: #193D66 !important;
  width: 250px !important;
  columns: #FFFFFF !important;
  padding: 0.6rem 2rem !important;
  border-radius: 0.5rem !important;
  margin-top: 3rem !important;
}

.service__tab {
  transition: all 1s;
  width: 31%;
}

.review__tab {
  transition: all 1s;
  width: 31%;
}

.about__tab {
  width: 34%;
  transition: all 1s;
}

.review::-webkit-scrollbar {
  width: 5px;
}

.review::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

.review::-webkit-scrollbar-thumb {
  background: #fafafa;
  border-radius: 10px;
}

.review::-webkit-scrollbar-thumb:hover {
  background: #fafafa;
}

.service::-webkit-scrollbar {
  width: 5px;
}

.service::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

.service::-webkit-scrollbar-thumb {
  background: #fafafa;
  border-radius: 10px;
}

.service::-webkit-scrollbar-thumb:hover {
  background: #fafafa;
}

.tab__mainconatiner {
  width: 100% !important;
  padding: 1.5rem !important;
}
.tab__mainconatiner__box {
  border: 1px solid #e5f3dc !important;
  background-color: #e5f3dc !important;
  border-radius: 50px !important;
  margin-top: 0.5rem !important;
}

