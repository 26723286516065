.passwordChange {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__container {
    background-color: $WHITE_COLOR;
    box-shadow: $BOX_SHADOW;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 1rem;
    min-width: 400px;

    @media (max-width: 600px) {
      box-shadow: unset;
      padding: unset;
    }


    &__saveButton {
      text-transform: capitalize !important;
      background-color: $BLUE_COLOR !important;
      border-radius: .5rem !important;
      margin-top: 2rem !important;
      font-size: .8rem !important;
      width: 100% !important;
      padding-top: .7rem !important;
      padding-bottom: .7rem !important;
      color: white !important;
    }

    &__passChange {
      font-weight: 800;
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
  }
}