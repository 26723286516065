.editProfile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__container {
    background-color: $WHITE_COLOR;
    box-shadow: $BOX_SHADOW;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: .5rem;
    max-width: 400px;

    @media (max-width: 600px) {
      box-shadow: unset;
      padding: unset;
    }

    &__imageBox {
      margin-top: 2rem;

      &__label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 90px;
          height: 90px;
          object-fit: cover;
          border-radius: 50%;
          background-color: $DARK_GRAY_COLOR;
        }

        &__icon {
          color: $LIGHT_GRAY_COLOR;
          margin-bottom: -3.5rem;
          z-index: 1;
        }
      }

      input {
        visibility: hidden;
      }
    }

    &__editProfile {
      font-weight: 900;
      text-transform: capitalize;
    }

    &__nameBox {
      border-top: 1px solid lightgray;
      display: flex;
      justify-content: space-between;
      margin-top: 1.5rem;
      padding-top: 1.5rem;

      div {
        width: 98%;
      }
    }

    &__userIcon {
      background-color: $LIGHT_GREEN_COLOR;
      color: $GREEN_COLOR;
      border-radius: 50% !important;
      font-weight: bolder !important;
    }

    &__saveButton {
      text-transform: capitalize !important;
      background-color: $BLUE_COLOR !important;
      border-radius: .5rem !important;
      margin-top: 2rem !important;
      font-size: .8rem !important;
      width: 100% !important;
      padding-top: .7rem !important;
      padding-bottom: .7rem !important;
      color: white !important;
    }

  }
}