.privacyPolicy {
    margin: 120px 70px 20px 70px;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
        margin: 0px;
        border: unset;
        border-radius: unset;
        align-items: unset;
    }

    &__content {
        padding: 15px 0px;

        h2 {
            padding: 15px 0px;
        }

        @media (max-width: 768px) {
            h2 {
                padding: 0px;
            }

            p {
                padding-left: 0px;
            }
        }

        &__heading3 {
            padding-left: 20px;

            h3 {
                margin: 10px 0px;
            }
        }
    }
}