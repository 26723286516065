.label{
    font-weight: 600;
    color: gray;
    font-size: 16px;
}
.toastMessage{
    left: 45% !important;
    border: 3px solid black;
    border-radius: 13px;
    @media (max-width: 768px) {
        left: 5% !important;
    }
    div{
        font-weight: 600;
    }
}

.contactUs{
    margin: 120px 200px;
    border: 2px solid #193d66;
    padding: 20px;
    border-radius: 20px;
    @media (max-width: 768px) {
        margin: 120px 20px;
    }
    h1{
        text-align: center;
    }
}
html,
body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}
.clientPage {
    height: 100%;
    /* Pushes the footer to the bottom */
}