.filter {
    &__header{
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        align-items: center;
        &__title{
            height: 40px;
            font-size: 1.4rem;
        }
        &__button{
            height: 40px;
        }
    }
    padding: 1rem;
    &__title {
        text-align: center;
        color: black;
        margin-bottom: 1rem;
    }
    
    &__priceRange {
        display: flex;
        width: 100% !important;
        gap: 1rem;
        margin-bottom: 1rem;
        &__maximum, &__minimum {
            display: flex;
            flex-direction: column;
        }
        input {
            width: 100%;
            outline: none;
            padding: 0.4rem;
            border-radius: 6px;
            border: 1px solid #f2f4f7;
        }
        
    }
}

.css-zqcytf-MuiButtonGroup-root {
    width: 100% !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.css-zqcytf-MuiButtonGroup-root .MuiButtonGroup-grouped {
    width: 50% !important;
   
}
.MuiSlider-rail {
    background-color: #f2f4f7 !important;
}
.MuiSlider-track {
    background-color: #1754e0 !important;
}
.MuiSlider-thumb {
    background-color: #1754e0 !important;
    width: 13px !important;
    height: 13px !important;
}
.MuiFormControl-root {
    display: flex !important;
}
.css-1hyi1uc-MuiFormGroup-root {
    display: flex !important;
    flex-direction: row !important;
    color: black !important;
    font-weight: bolder !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: $BLUE_COLOR !important;
}
