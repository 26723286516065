.support {
    margin-top: 5rem;
    margin-left: 250px;
    padding: 1rem 2rem;
    @include fadeIn;
    @media (max-width: 768px) {
        padding: 1rem 1rem;
        margin-left: 0;
    }
    &__container {
        display: flex;
        justify-content: space-between;
        &__title {
            color: #193d66;
        }
        &__composeButton {
            background-color: #193d66 !important;
            border: none !important;
            color: white !important;
            padding: 0.5rem 1.5rem !important;
            border-radius: 6px !important;
            cursor: pointer !important;
        }
    }
    &__form {
        margin-top: 2rem;
        &__searchBox {
            margin-top: 1.5rem;
            background-color: $WHITE_COLOR;
            width: 100%;
            padding: 0.2rem 1rem;
            border-radius: 0.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $GREEN_COLOR;
            &__search {
                &__searchLabel {
                    color: $GRAY_COLOR;
                    font-size: 0.9rem !important;
                    margin-right: 0.5rem;
                }
                &__searchInput {
                    width: 100%;
                    outline: unset;
                    border: unset;
                    margin-right: 0.5rem;
                }
            }
        }
        &__button {
            margin-top: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            &__sendButton {
                background-color: $BLUE_COLOR !important;
                text-transform: unset !important;
                border-radius: 5px !important;
                display: flex;
                align-items: center;
                padding-left: 3rem !important;
                padding-right: 3rem !important;
                position: relative;
                right: 1rem !important;
                font-size: 1.2rem !important;
            }
        }
    }
}

.tox {
    position: relative !important;
    top: 2rem !important;
    height: 300px !important;
    border: 1px solid $GREEN_COLOR !important;
}
.tox-tinymce-aux {
    display: none;
}
