.serviceHour {
  margin-top: 5rem;
  margin-left: 250px;
  padding: 1rem;
  @include fadeIn;

  @media (max-width: 768px) {
    padding: 1rem 1rem;
    margin-left: 0;
  }
  &__buttonBox {
    display: flex !important;
    justify-content: end !important;
    width: 100% !important;
    cursor: pointer !important;
    @media (max-width: 900px) {
      display: none !important;
    }
    &__button {
      background-color: #193d66 !important;
      columns: $WHITE_COLOR !important;
      padding: 0.6rem 2rem !important;
      border-radius: 0.5rem !important;
      color: white !important;
      align-items: center !important;
      cursor: pointer !important;
    }
  }
  &__table {
    background-color: #f8f8f8;
    border-radius: 10px;
    overflow: auto;
    width: 100%;
    margin-top: 1rem;
    @media (max-width: 768px) {
      display: none;
    }
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem;
      overflow: auto;
      gap: 1rem;
      // @media (max-width: 900px) {
      //     width: 130%;
      // }
      @media (max-width: 550px) {
        width: 160%;
      }
      @media (max-width: 450px) {
        width: 250%;
      }
    }
    &__days {
      color: #363636;
      font-family: "Poppins", sans-serif !important;
    }
    &__title {
      color: #363636;
      font-family: "Poppins", sans-serif !important;
    }
    &__textField {
      width: 20%;
      @media (max-width: 768px) {
        width: 100%;
      }
      &__greenInput {
        display: flex;
        flex-direction: column;
        border: 2px solid $GREEN_COLOR;
        background-color: #ffffff;
        border-radius: 0.7rem;
        width: 100%;
        label {
          color: #444444;
          font-size: 10px;
          font-weight: 600;
          position: relative !important;
          left: 1rem !important;
        }
        &__inputBox {
          display: flex;
          position: relative;
          left: 1rem;
          input {
            position: relative;
            bottom: 0.5rem;
            margin-top: 0.8rem;
            outline: unset;
            border: unset;
            color: #444444;
            font-weight: lighter;
            font-size: 12px;
            padding-right: 40px;
            background-color: unset;
            border-radius: 0.5rem;
            width: 100%;
            padding-left: 0rem;
          }
        }
      }
    }
    &__specialHour {
      display: flex !important;
      justify-content: center !important;
      width: 100% !important;
      cursor: pointer !important;
      margin-top: 1rem;
      &__button {
        background-color: #193d66 !important;
        columns: $WHITE_COLOR !important;
        padding: 0.6rem 2rem !important;
        border-radius: 0.5rem !important;
        color: white !important;
        align-items: center !important;
        cursor: pointer !important;
        width: 20% !important;
      }
    }
  }
  &__buttonbox {
    display: none !important;
    @media (max-width: 900px) {
      display: block !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      margin-top: 1rem !important;
    }
  }
  &__button {
    background-color: #193d66 !important;
    columns: $WHITE_COLOR !important;
    padding: 0.6rem 2rem !important;
    border-radius: 0.5rem !important;
    color: white !important;
    align-items: center !important;
    cursor: pointer !important;
  }
}

.serviceHour__table__container::-webkit-scrollbar {
  width: 5px;
}
@media (max-width: 768px) {
  .serviceHour__table__container::-webkit-scrollbar {
    width: 0 !important;
  }
}
.serviceHour__table__container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $WHITE_COLOR;
  border-radius: 10px;
}
.serviceHour__table__container::-webkit-scrollbar-thumb {
  background: #717171;
  border-radius: 10px;
}
.serviceHour__table__container::-webkit-scrollbar-thumb:hover {
  background: #717171;
  height: 10px;
}
.responsivServiceHour {
  display: none;

  @media (max-width: 768px) {
    display: block;
    background-color: #f8f8f8;
    margin-top: 2rem;
    border-radius: 10px;
    overflow: auto;
    &__container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem;
      &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &__label {
        color: #444444;
        font-size: 17px;
        font-weight: 600;
      }
      &__time {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 768px) {
  .responsivServiceHour__container::-webkit-scrollbar {
    width: 0 !important;
  }
}
.responsivServiceHour__container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $WHITE_COLOR;
  border-radius: 10px;
}
.responsivServiceHour__container::-webkit-scrollbar-thumb {
  background: #717171;
  border-radius: 10px;
}
.responsivServiceHour__container::-webkit-scrollbar-thumb:hover {
  background: #717171;
  height: 10px;
}
.noServiceHour_title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #193d66;
  height: 73vh;
  @media (max-width: 768px) {
    display: none;
  }
}

.createServiceHour_title {
  display: none;
  @media (max-width: 768px) {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #193d66;
    height: 73vh;
  }
}
.serviceHour__container {
  @media (max-width: 900px) {
    position: relative !important;
    bottom: 0rem !important;
  }
}
