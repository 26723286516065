.selectLevel {
  margin-left: 250px;
  margin-top: 5rem;

  @media (max-width: 768px) {
    margin-left: unset;
  }

  &__body {
    width: 100%;

    &__heading {
      text-align: center;
      padding-top: 1rem;
    }
    &__subHeading{
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }
    &__cards {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;
      &__cardBasic {
        &:hover {
          border: 2px solid lightblue !important;
          cursor: pointer;
        }
      }

      &__cardStandard {
        &:hover {
          border: 2px solid $DARK_PLUM_COLOR !important;
        }
      }

      &__cardPremium {
        &:hover {
          border: 2px solid $BLUE_COLOR !important;
        }
      }

      &__card {
        box-shadow: $BOX_SHADOW;
        padding: 1.5rem;
        border-radius: 1.5rem;
        margin: 0 0.5rem;
        width: 280px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
        z-index: 2;
        background-color: $WHITE_COLOR;
        height: 570px;

        &:hover {
          border: 2px solid $GREEN_COLOR;
          cursor: pointer;
        }

        &__headingBasic {
          border-bottom: solid $MID_BLUE_COLOR !important;
        }

        &__headingStandard {
          border-bottom: solid $DARK_PLUM_COLOR !important;
        }

        &__headingPremium {
          border-bottom: solid $BLUE_COLOR !important;
        }

        &__heading {
          border-bottom: solid $GREEN_COLOR;
          padding-bottom: 0.2rem;
          white-space: nowrap;
          text-align: center;
        }

        &__body {
          display: flex;
          flex-direction: column;
          width: 100%;

          &__textBox2 {
            height: 320px !important;
          }

          &__textBox {
            display: flex;
            flex-direction: column;
            margin-top: 2rem;
            height: 300px;

            &__text {
              display: flex;
              align-items: flex-start;
              margin-bottom: 0.8rem;

              p {
                font-size: 0.75rem;
                margin-left: 0.5rem;
                color: $GRAY_COLOR;
                text-transform: capitalize;
                font-weight: 600;
              }

              &__icon {
                background-color: black !important;
                border-radius: 50%;
                color: $WHITE_COLOR !important;
                font-size: 0.85rem !important;
                padding: 1px !important;
              }
            }
          }

          &__trialBasic {
            background-color: $DARK_OYSTER_BAY_COLOR !important;
          }

          &__trialStandard {
            background-color: $PLUM_COLOR !important;
          }

          &__trialPremium {
            background-color: $LIGHT_BLUE_COLOR !important;
          }

          &__trial {
            background-color: $LIGHT_GREEN_COLOR;
            border-radius: 1rem;
            padding: 0.3rem 0.5rem;
            text-align: center;
            color: $DARK_GRAY_COLOR;
            display: flex;
            flex-direction: column;
            width: auto;

            p {
              font-weight: 600;
              font-size: 0.8rem;
              margin-bottom: unset;
            }

            span {
              font-size: 0.8rem;
              margin-top: -0.1rem;
            }

            &__saving {
              background-color: red;
              position: absolute;
              color: $WHITE_COLOR;
              text-align: center;
              border-radius: 50%;
              padding: 8px;
              font-size: 0.7rem !important;
              margin-left: 9.5rem;
            }
          }

          &__trial2 {
            background-color: $LIGHT_GREEN_COLOR;
            border-radius: 2rem;
            padding: 0.3rem 0.5rem;
            text-align: center;
            color: $DARK_GRAY_COLOR;
            display: flex;
            flex-direction: column;
            width: auto;

            p {
              font-weight: 600;
              font-size: 0.8rem;
              margin-bottom: unset;
            }

            span {
              font-size: 0.8rem;
              margin-top: -0.2rem;
            }

            &__saving {
              background-color: red;
              position: absolute;
              color: $WHITE_COLOR;
              text-align: center;
              border-radius: 50%;
              padding: 7px;
              font-size: 0.6rem !important;
              margin-left: 11.8rem;
              margin-top: -0.7rem !important;
            }
          }

          &__dividerStandard {
            p {
              background-color: $DARK_PLUM_COLOR !important;
            }
          }

          &__dividerPremium {
            p {
              background-color: $BLUE_COLOR !important;
            }
          }

          &__divider {
            border-bottom: 1px solid lightgray;
            padding-top: 1.1rem;
            margin-bottom: 1.1rem;

            p {
              background-color: $MID_BLUE_COLOR;
              border-radius: 50%;
              position: absolute;
              color: $WHITE_COLOR;
              padding: 4px;
              font-size: 0.7rem;
              margin-left: 6.5rem;
              margin-top: -0.8rem;
            }
          }
        }
      }
    }

    &__grayBottom {
      background-color: $LIGHT_GRAY_COLOR;
      height: 180px;
      margin-top: -180px;

      @media (max-width: 1184px) {
        display: none;
      }
    }

    &__continueButton {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;

      div {
        width: 300px;
      }
    }
  }
}

.basicCardModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__header2 {
    border-bottom: solid $BLUE_COLOR !important;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: solid $MID_BLUE_COLOR;
    width: 20%;
    white-space: nowrap;
    padding-bottom: 0.3rem;
  }

  &__body {
    &__options {
      display: flex;
      justify-content: space-between;
      margin: 1.5rem 2rem;

      &__Box {
        margin: 0.5rem;
        width: 33%;
      }
    }

    &__plans {
      &__header2 {
        h3 {
          border-bottom: solid $BLUE_COLOR !important;
        }
      }

      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        h3 {
          border-bottom: solid $MID_BLUE_COLOR;
          width: 20%;
          white-space: nowrap;
          padding-bottom: 0.3rem;
        }
      }

      &__box {
        display: flex;
        justify-content: space-around;
        margin: 1.5rem 2rem;

        input {
          accent-color: $BLUE_COLOR;
          height: 1.1rem;
          width: 1.1rem;
        }

        label {
          margin-left: 1rem;
        }
      }
    }

    &__divider2 {
      div {
        span {
          background-color: $BLUE_COLOR !important;
        }
      }
    }

    &__divider {
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        width: 250px;
        border-bottom: 1px solid lightgray;

        span {
          position: absolute;
          background-color: $MID_BLUE_COLOR;
          border-radius: 50%;
          padding: 4px;
          color: white;
          font-size: 0.8rem;
          margin-left: 7rem;
          margin-top: -1rem;
        }
      }
    }

    &__offerContainer2 {
      div {
        background-color: $LIGHT_GRAY_COLOR !important;
      }
    }

    &__offerContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        display: flex;
        align-items: center;
        background-color: lightblue;
        border-radius: 2rem;
        padding: 0.2rem 2rem;
        width: 250px;
        margin: 2rem 0;

        input {
          accent-color: $BLUE_COLOR;
          height: 1.1rem;
          width: 1.1rem;
        }

        label {
          margin-left: 1rem;
          text-align: center;

          p {
            font-weight: 600;
            font-size: 0.8rem;
            color: $DARK_GRAY_COLOR;
            margin-bottom: -0.3rem;
          }

          span {
            font-size: 0.8rem;
            color: $DARK_GRAY_COLOR;
          }
        }
      }

      &__saving {
        position: absolute;
        background-color: red;
        border-radius: 50%;
        padding: 8px;
        text-align: center;
        color: $WHITE_COLOR;
        font-size: 0.7rem;
        margin-left: 11.5rem;
      }
    }

    &__saveButton {
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        width: 330px;
      }
    }
  }
}

.paymentPage{
  display: flex;
  justify-content: center;
  &__form{
    width: 550px;
    border: 1px solid #87C85B;
    border-radius: 20px;
    padding: 40px;
    margin-top: 40px;
    &__heading{
      justify-content: center;
      display: flex;
      margin-bottom: 25px;
      color: #858585;
      font-weight: 600;
      font-size: 1rem;
      text-transform: capitalize;
      flex-direction: column;
      h2{
        display: flex;
        justify-content: center;
      }
      p{
        display: flex;
          justify-content: center;
      }
      
    }
    &__button{
      margin-top: 15px;
    }
    
      
    
  }

}