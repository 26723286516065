.myPhoto {
    margin-top: 5rem;
    margin-left: 250px;
    padding: 1rem;
    @include fadeIn;

    @media (max-width: 768px) {
        padding: 1rem 1rem;
        margin-left: 0;
    }
    &__title {
        color: $BLUE_COLOR;
    }
    &__container {
        margin-top: 2rem;
        &__body {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            bottom: 1rem;
            gap: 0.4rem;
            @media (max-width: 650px) {
                justify-content: center;
            }
            &__label {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $WHITE_COLOR;
                height: 200px;
                width: 200px;
                border-radius: 0.5rem;
                margin-right: 0.5rem;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #e8e8e8;
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                margin-top: 1rem;
                @media (max-width: 600px) {
                    width: 100%;
                }
                &__icon {
                    color: #565656;
                    font-size: 2.5rem !important;
                }
            }
            p {
                color: #565656;
                font-weight: 600;
                margin-bottom: 1rem;
                font: normal normal bold 12px/18px Roboto;
            }
            &__image {
                position: relative;
                &__tooltip {
                    position: absolute;
                    top: 0;
                    right: 0.5rem;
                    visibility: hidden;
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                    background: #476485;
                    width: 200px;
                    box-shadow: 0px 3px 6px $BLACK_COLOR_2;
                    border-radius: 0.5rem 0.5rem 0px 0px;
                    display: flex;
                    justify-content: space-between;
                    @media (max-width: 650px) {
                        width: 100%;
                        right: 0rem;
                    }
                }
            }
            img {
                height: 200px;
                width: 200px;
                object-fit: cover;
                border-radius: 0.5rem;
                margin-right: 0.5rem;
                margin-top: 1rem;
                cursor: pointer;
                @media (max-width: 650px) {
                    width: 100%;
                    height: auto;
                }
            }
        }
        input {
            visibility: hidden;
        }
    }
}
.myPhoto__container__body__image:hover .myPhoto__container__body__image__tooltip {
    visibility: visible;
    opacity: 1;
    margin-top: 1rem;
}
